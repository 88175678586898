import React, { Fragment, useEffect } from 'react'
import { Controller } from 'react-hook-form';
import { GrDown } from 'react-icons/gr';
import { useHistory, useLocation } from 'react-router-dom'
import Select from 'react-select';
import { getRequest } from '../../../API';



const PendingHeader = ({ setSelectAll, setCheck, check, setAdmins, admins, control }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { reportees, name, mail, state } = history.location.state || {};
  const { companyID } =  state ? state[0] : (reportees && reportees[0]) || {};


  const get_admins = async () => {
    try {
      const { data } = await getRequest(`employee/user/groupUsers?empRole=admin&companyID=${companyID}`);
      const { result } = data || {};
      if (result && result.length > 0) {
        let Items = result.filter((item) => item.emailID !== mail);
        setAdmins(Items);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    get_admins();
  }, []);
  let admins_data = admins && admins.map((item) => {
    return { label: item.empName, value: { newApproverID: item.emailID, newApproverRole: item.empRole } };
  });


  return <Fragment>
    <svg onClick={() => history.goBack()}
      className='back--icon' width="29" height="16" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H29V7H1V9Z" fill="black" />
    </svg>
    <article className='pending--header'>
      {pathname.includes("reassign")
        ? <h1>{name} has <b>{reportees && reportees.length}</b> Reportees. </h1>
        : <h1>{name} has <b>{state && state.length}</b> Pending requests. </h1>}
      <section className='input--section'>
        <div>
          <GrDown className='icon--1' />
          {pathname.includes("reassign")
            ? <label className=''>Reassign Reportees to</label>
            : <label className=''>Reassign to</label>}
          <Controller
            control={control}
            name="reassign"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => <Select
              className='text-uppercase'
              value={value}
              styles={""}
              classNamePrefix="select"
              options={admins_data}
              onChange={(e) => onChange(e)}
            />}
          />
        </div>
      </section>
    </article>
    <div className='select--all'>
      <input
        type="checkbox"
        onChange={() => {
          pathname.includes("reassign")
            ? setSelectAll({ data: !check ? reportees : [] })
            : setSelectAll({ data: !check ? state : [] })
          setCheck(!check);
        }}
      /> &nbsp;Select All
    </div>
  </Fragment>
}

export default PendingHeader