import { toast } from "react-toastify";

const domainValidator = ({ modifiedData, companyName }) => {
  const validateEmail = [];
  return new Promise((res, rej) => {
    modifiedData && modifiedData.map((items) => {
      items.emailID = items.emailID.toLowerCase();
      var getDomain = items.emailID.split("@").pop().split(".");
      if (!(getDomain[0] && getDomain[0] === companyName)) validateEmail.push(items.emailID);
      return res(validateEmail);
    })
  })
}

const checkForAnother = ({ setCheck, fields, append, setAddAnother }) => {
  setCheck(true);
  if (fields && fields.length > 2) return toast.info("Team Members can be added max 3 at a time!", {
    style: { fontSize: "13px" }
  });
  append({ empName: "", empID: "", emailID: "" });
  document.getElementById('member').scrollIntoView({ behavior: "smooth" });
  return setAddAnother(false);
}

const fieldValidation = ({ getValues, setCustomErrorDisabled }) => {
  const error = { check: false };
  getValues && getValues.employees.map((items) => {
    const { empName, empID, emailID } = items;
    if (Object.values({ empName, emailID, empID }).some(val => val === "")) error.check = true;
    return null;
  });
  if (error.check) setCustomErrorDisabled(true);
  else setCustomErrorDisabled(false)
}

const styles_fn = (key, errors) => {
  return {
    control: styles => ({
      ...styles,
      borderColor: (errors[key]) ? "#EC5D6D!important" : null
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: (errors[key]) ? "#EC5D6D!important" : null,
        opacity: 0.4
      }
    }
  };
}


const empRoles = [
  { value: "admin", label: "Admin" },
  { value: "responder", label: "Responder" },
  { value: "requester", label: "Requester" }
];


export { domainValidator, checkForAnother, fieldValidation, styles_fn, empRoles };