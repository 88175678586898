import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import "./style.css";
import Loader from '../../../Component/Spinners/Loader';
import { useHistory } from 'react-router-dom';


const Checkout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loader, setLoader] = useState(false);
  const history = useHistory();


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required"
    });
    if (error) {
      setErrorMessage(error.message);
    } else {
      setTimeout(() => {
        setLoader(false);
        history.push("/dashboard/billing");
      }, 1000);
    }
  };

  return <section className='payment--screen'>
    <form className='card' onSubmit={handleSubmit}>
      <AddressElement options={{ mode: 'billing' }} />
      <PaymentElement />
      <button className='btn' disabled={!stripe || loader}> {loader ? <Loader /> : "Submit"} </button>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  </section>
};

export default Checkout;