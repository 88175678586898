import React, { useContext } from 'react'
import { toast } from 'react-toastify';
import { sendRequest } from '../../../API';
import Loader from '../../../Component/Spinners/Loader'
import { GlobalContext } from '../../../Context';

const HandleSub = ({ handlePayment, loader, setLoader }) => {
  const { setModalUtils, companyData, user } = useContext(GlobalContext);
  const { emailID } = (user && user.data) || {};
  const { companyID, subscriptionStatus, companyName, cancelAtperiodEnd } = companyData || {};


  const renewSub = async () => {
    try {
      await sendRequest("POST", "stripe/renewsubscription", { companyID, companyName, email: emailID });
      setTimeout(() => {
        toast.success("Subscription Renewed!");
        window.location.reload();
        setLoader({ begin: false });
      }, 1500);
    } catch (error) {
      const { status, data } = error.response || {};
      if (status === 403) return toast.error(data.message);
      toast.info("Request Failed!");
    }
  }

  return ((subscriptionStatus === 'ACTIVE') && (cancelAtperiodEnd !== true))
    ? <button className='cancel--sub' onClick={() => setModalUtils({ cancelBilling: true })}>
      <b>Cancel Subscription</b></button>
    : (subscriptionStatus === 'CANCELED'
      || cancelAtperiodEnd === true
      || subscriptionStatus === "INCOMPLETE_EXPIRED"
      || subscriptionStatus === "INCOMPLETE")

      ? <button className='cancel--sub' onClick={() => {
        setLoader({ begin: true });
        (cancelAtperiodEnd === true) ? renewSub() : handlePayment();
      }}><b>{loader.begin ? <Loader size={20} borderColor="#3263F3" borderTopColor="#FFF" /> : "Renew Subscription"}</b>
      </button>
      : (subscriptionStatus === "null")

        ? <button className='cancel--sub' onClick={() => {
          setLoader({ begin: true })
          handlePayment()
        }}> <b>{loader.begin ? <Loader size={20} borderColor="#3263F3" borderTopColor="#FFF" /> : "Begin Subscription"}</b>
        </button>
        : null
}

export default HandleSub;