import React from "react";
import { Link } from "react-router-dom";

// dummy commit comment
const Error = () => {
  return (
    <div className="text-center" style={{ marginTop: "10%" }}>
      <h2> Page Not Found!</h2>
      <Link to="/dashboard">
        <button className="btn btn-secondary">Go Home</button>
      </Link>
    </div>
  );
};

export default Error;
