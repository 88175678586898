import React, { useContext } from 'react'
import { useState } from 'react';
import { sendRequest } from '../../../API';
import { GlobalContext } from '../../../Context'
import Loader from "../../../Component/Spinners/Loader";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import { FeatureSubContext } from '../FeatureContext';


const ApproveReject = ({ items, setSerialNoModal }) => {
  const { setModalUtils, user, setCustomToast } = useContext(GlobalContext);
  const { setRequestView } = useContext(FeatureSubContext);
  const [btnTxt, setBtnTxt] = useState(false);
  const { emailID, empRole } = (user && user.data) || {};
  const historyPush = useHistory();


  const approvefn = async (data, request_status) => {
    var getDomain = emailID.split("@").pop().split(".");
    let assetId = empRole.includes("admin") ? `${getDomain[0] + "_" + Math.floor(Math.random() * 1000000)}` : "Null";
    setBtnTxt(true);
    const { companyID, ticketNo, comments } = data;
    try {
      const res = await sendRequest("POST", "items/update", {
        emailID, companyID, ticketNo,
        status: request_status, comments: comments, assetId
      });
      const { result: { history, status, track } } = res.data || {};
      setBtnTxt(false);
      if (history) setRequestView({ ...data, history, status, track, assetId });
      setCustomToast({ content: 'Request approved Successfully!', open: true, error: false });
      if (empRole.includes("admin")) setSerialNoModal(true);
      if (!empRole.includes("admin")) historyPush.push("/dashboard");
    } catch (error) {
      setBtnTxt(false);
      const { status, data } = error.response || {};
      if (status === 403) return toast.error(data.message);
      toast.error(("Something went wrong!"));
    }
  }


  return <>
    <div className='request-btn'>
      <button className='btn'
        onClick={() => {
          approvefn(items, "approve");
        }}>{btnTxt ? <Loader size={20} /> : "Approve"}</button>

      <button className='btn'
        onClick={() => {
          setModalUtils({ approveRejectModal: true })
          setRequestView(items);
        }}>Reject</button>
    </div>
  </>
}

export default ApproveReject;