import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../Context';
import Bill from './Subscription/Bill';
import Category from './Category/Category';
import Employee from './Employee/Employee';
import Profile from './Profile';
import MyRequestView from './Requests/MyRequest';
import ManageRequestView from './Requests/ManageRequests';
import Invoices from './Subscription/Invoices';
import MakePayment from './Subscription/MakePayment';
import Onboard from './Onboarding/Onboard';
import HelpSupport from './HelpSupport/HelpSupport';
import EditProfile from './EditProfile';
import ViewData from './Requests/ViewData';
import { FeatureSubContext } from './FeatureContext';
import PendingView from '../../Component/Modals/DeleteModals/PendingView';
import ReassignReportees from '../../Component/Modals/DeleteModals/ReassignReportees';


const ConditionalPath = () => {
  const { pathname } = useLocation();
  const { modalUtils, user, openMyRequestTab } = useContext(GlobalContext);
  const { requestView } = useContext(FeatureSubContext);
  const { empRole } = (user && user.data) || {};
  if (pathname.includes("/paymentscreen")) {
    document.body.style.overflowY = "auto";
  };


  if (pathname.includes("onboarding")) return <section>
    {empRole && empRole.includes("super_admin") ? <Onboard /> : window.location.href = "/dashboard"}
  </section>

  if (pathname.includes('editprofile')) return <section>
    <EditProfile image={user && user.profile_img} />
  </section>

  if (pathname.includes('profile')) return <section>
    <Profile image={user && user.profile_img} />
  </section>

  if (pathname.includes('help')) return <section>
    <HelpSupport />
  </section>

  if (pathname.includes('pending')) return <section>
    <PendingView />
  </section>

  if (pathname.includes('reassign_reportees')) return <section>
    <ReassignReportees />
  </section>


  if (pathname.includes('employee')) return <section className={(modalUtils.addEmployee || modalUtils.openSentRequestModal)
    ? "opacity--reduce" : null}>
    {empRole && empRole.includes("admin") ? <Employee /> : window.location.href = "/dashboard"}
  </section>



  if (pathname.includes('category')) return <section className={(modalUtils.addCategory || modalUtils.openSentRequestModal)
    ? "opacity--reduce" : null}>
    {empRole && empRole.includes("admin") ? <Category /> : window.location.href = "/dashboard"}
  </section>

  if (pathname.includes('help')) return <section>
    <HelpSupport />
  </section>

  if (pathname.includes('profile')) return <section>
    <Profile image={user && user.profile_img} />
  </section>

  if (requestView !== undefined && pathname.includes('viewmyrequest'))
    return <section className={(modalUtils.createRequestModal || modalUtils.openSentRequestModal)
      ? "opacity--reduce" : null}>
      <ViewData />
    </section>

  if (pathname.includes("billing")) return <section className={(modalUtils.cancelBilling
    || modalUtils.costBreakdown || modalUtils.createRequestModal) ? "opacity--reduce" : null}>
    {empRole.includes("super")
      ? modalUtils.openInvoices ? <Invoices /> : <Bill />
      : window.location.href = "/dashboard"}
  </section>

  if (pathname.includes("paymentscreen")) return <section>
    <MakePayment />
  </section>


  return <section className={(modalUtils.createRequestModal || modalUtils.openSentRequestModal) ? "opacity--reduce" : null}>
    {openMyRequestTab ? <MyRequestView /> : <ManageRequestView />}
  </section>
}

export default ConditionalPath;