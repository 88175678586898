import moment from 'moment';
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { CheckBox, Inputs } from '../../Component/Authenticate/Inputs'
import Loader from '../../Component/Spinners/Loader';

const PersonalDetails = ({ submit_data, handleSubmit, errors, register, watchValues, btnTxt }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  let { given_name, getDomain, email } = history.location.state || {};
  

  return <> <h1>{pathname.includes('userworkspace')?`Hi ${given_name}, tell us about yourself`:'Almost done.'}</h1>
    <p>Tell us more about yourself.</p>
    <form onSubmit={handleSubmit(submit_data)}>
      <section className='input--section animate__animated animate__fadeInUp'>
        <div>
          <label style={{ color: errors.givenName ? "#FF4343" : null }}>Your Name</label>
          <Inputs value={given_name}
            register={register} errors={errors} name={"givenName"} placeholder="Your Name"
            require={true} />
        </div>
      </section>

      <section className='input--section animate__animated animate__fadeInUp'>
        <div>
          <label>Name of your company</label>
          <Inputs value={(getDomain && getDomain[0])}
            register={register} errors={errors} name={"companyName"} placeholder="Name of your company*"
            require={true} readOnly={true} />
        </div>
        <div>
          <label htmlFor="">Company Email*</label>
          <Inputs value={email}
            register={register} errors={errors} name={"userEmailID"} placeholder="Company Email*"
            require={true} readOnly={true} className="email" />
        </div>
      </section>

      <section className='input--section modified--input--dob animate__animated animate__fadeInUp'>
        <div>
          {watchValues.employeeID ? <label style={{ color: errors.employeeID ? "#FF4343" : null }}>
            Employee ID</label> : null}
          <Inputs register={register} errors={errors} name={"employeeID"} placeholder="Employee ID"
            require={false} />
        </div>
        <div>
          {watchValues.doj ? <label style={{ color: errors.doj ? "#FF4343" : null }}>
            Joining Date</label> : null}
          <Inputs register={register} errors={errors} name={"doj"} placeholder="Joining Date"
            require={false} type="date" maxDate={moment().format("YYYY-MM-DD")} />
          {!watchValues.doj ? <span>Joining Date</span> : null}
        </div>
      </section>

      <section className='input--section'>
        <div>
          {watchValues.empAddress ? <label style={{ color: errors.empAddress ? "#FF4343" : null }}>
            Employee Address</label> : null}
          <Inputs register={register} errors={errors} name={"empAddress"} placeholder="Employee Address"
            require={false} />
        </div>
      </section>

      <section className='checkbox--section animate__animated animate__fadeInUp'>
        <article className="checkbox">
          <CheckBox name="check" register={register} errors={errors} />
          {errors.check ? <span className='shadow'>Required</span> : null}
        </article>
        <p>I agree to Reqos <a target="_blank" href="/terms">Terms and Conditions</a></p>
      </section>

      <section className='workspace--btn animate__animated animate__fadeInUp'>
        <button disabled={btnTxt.includes("...") ? true : false} className="btn">
          {btnTxt} {btnTxt.includes("...") ? <Loader /> : null}
        </button>
      </section>
    </form> </>
}

export default PersonalDetails;