import React, { useContext, useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { GrDown } from "react-icons/gr";
import { GlobalContext } from "../../Context";
import { useForm } from "react-hook-form";
import { Inputs, SelectBox, TextArea } from "../Authenticate/Inputs";
import { FeatureSubContext } from "../../Pages/Manage/FeatureContext";
import Loader from "../Spinners/Loader";
import { imageSender, sendRequest } from "../../API";
import FileUpload from "./FileUpload";
import { toast } from "react-toastify";
import {
  Currency,
  formatCurrency,
} from "../../Pages/Manage/Requests/RequestUtils";

const CreateRequest = () => {
  const {
    setModalUtils,
    modalUtils,
    closeModal,
    setCloseModal,
    user,
    checkForSubscription,
    setViewItem,
  } = useContext(GlobalContext);
  const { category, getCheckedCatgory, getMyRequests } =
    useContext(FeatureSubContext);
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
  } = useForm();
  const [categoryItems, setCategoryItems] = useState([]);
  const [btnTxt, setBtnTxt] = useState("Create Request");
  const [imageData, setImageData] = useState({
    signedUrl: "",
    data: "",
    imageKey: "",
  });
  const { empName, companyID, emailID } = user.data || {};
  const [click, setClick] = useState(0);
  const [admins, setAdmins] = useState([]);

  const handleClose = ({ openSent }) => {
    setTimeout(() => {
      setCloseModal({ createRequest: false });
      getMyRequests();
      setViewItem("request added");
      setModalUtils({
        ...modalUtils,
        createRequestModal: false,
        openSentRequestModal: openSent,
      });
    }, 400);
    setCloseModal({ createRequest: true });
  };

  const getCurrentCategory = watch("category");
  const isCurrencySelected = watch("currency");
  useEffect(() => {
    if (category.length <= 0) getCheckedCatgory();
    if (getCurrentCategory) {
      setCategoryItems([]);
      setValue("request", null);
      var getValue =
        category &&
        category.find((items) => items.CategoryName === getCurrentCategory);
      setAdmins(getValue.categoryAdmin);
      setCategoryItems(getValue.CategoryItems);
    }
    if (getCurrentCategory === "") {
      setCategoryItems([]);
      setValue("request", "");
    }
  }, [getCurrentCategory, category]);

  const submit_data = async (data) => {
    try {
      setBtnTxt("Processing...");
      await checkForSubscription();

      if (!(data.amount && data.currency)) {
        delete data.currency;
      }
      data.companyID = companyID;
      data.emailID = emailID;
      data.categoryAdmin = admins;
      data.imageKey = imageData.signedUrl && imageData.imageKey;
      data.currency = formatCurrency(data.amount, data.currency);
      delete data.amount;
      await sendRequest("POST", "items/create", data);
      if (imageData.signedUrl) {
        imageSender(imageData.signedUrl, imageData.data).catch((err) => {
          toast.info("Failed to upload Image!");
        });
      }
      handleClose({ openSent: true });
    } catch (error) {
      setBtnTxt("Create Request");
      if (error.response && error.response.status === 404)
        return toast.info("Please add admin to create request!");
      if (error === 403) return toast.info("Subscription ended!");
      toast.error("Request failed!");
    }
  };

  const isCustom = "CUSTOM" === watch("request");

  return (
    <section
      className={`create-request-view animate__animated animate__zoomIn
  ${closeModal.createRequest ? "animate__zoomOut" : null}`}
    >
      <form onSubmit={handleSubmit(submit_data)}>
        <article>
          <h1>
            Create Request{" "}
            <span onClick={() => handleClose({ openSent: false })}>
              <IoIosClose />
            </span>{" "}
          </h1>
          <div className="request-name-view">
            <p className="name">Creating request as</p>
            <div style={{ textTransform: "capitalize" }}>
              <span>{empName && empName.charAt(0)}</span>
              <p>{empName}</p>
            </div>
          </div>
        </article>
        <hr />
        <div className="input--section request-input">
          <Inputs
            value={""}
            register={register}
            errors={errors}
            name={"requestTitle"}
            placeholder="Request Title*"
            require={true}
            className="request-title"
          />
        </div>
        <article className="inputs">
          <div>
            <GrDown className="icon--1" />
            <SelectBox
              name="category"
              register={register}
              errors={errors}
              message="Choose Category*"
              data={category}
              dataItemDisplay={"CategoryName"}
              dataItemValue={"CategoryName"}
              className="select--error"
              valid={true}
              onClick={() => setClick(click + 1)}
              option={true}
            />
          </div>
          <div>
            <GrDown className="icon--2" />
            <SelectBox
              name="request"
              register={register}
              errors={errors}
              message="Choose Items*"
              data={categoryItems}
              dataItemDisplay={"itemName"}
              dataItemValue={"itemName"}
              className="select--error"
              valid={true}
              selectClass={click > 0 ? null : "notallowed"}
            />
            {category.length <= 0 ? <Loader size={22} /> : null}
          </div>
        </article>
        {isCustom && (
          <article className="">
            <div className="input--section request-input custom--section">
              <div className="currency--select">
                <GrDown className="icon--3" />
                <SelectBox
                  name="currency"
                  register={register}
                  errors={errors}
                  message="Select"
                  data={Currency}
                  dataItemDisplay={"CurrencyName"}
                  dataItemValue={"CurrencyName"}
                  className="select--error"
                  valid={true}
                />
              </div>
              <div className="entered--amount">
                <Inputs
                  value={""}
                  register={register}
                  errors={errors}
                  name={"amount"}
                  placeholder="Enter Amount"
                  require={true}
                  type={"number"}
                  className="request-title"
                  disabled={!isCurrencySelected}
                />
              </div>
            </div>
          </article>
        )}

        <article className="description">
          <label htmlFor="">Description</label>
          <TextArea
            placeholder="Tell the Admin more about the request you're making."
            name="description"
            register={register}
            errors={errors}
            className="request-textarea"
          />
        </article>
        <article className="request-image">
          <FileUpload empName={empName} setImageData={setImageData} />

          <p className="information">
            To upload multiple PDFs, you can combine them{" "}
            <a
              href="https://www.adobe.com/in/acrobat/online/merge-pdf.html"
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline"
            >
              here
            </a>
            .
          </p>
        </article>
        <div className="create-button">
          <button disabled={btnTxt.startsWith("P") ? true : false}>
            {btnTxt}{" "}
            {btnTxt.includes("P") ? (
              <>
                {" "}
                &nbsp; &nbsp; <Loader size={22} />{" "}
              </>
            ) : null}{" "}
          </button>
        </div>
      </form>
    </section>
  );
};

export default CreateRequest;
