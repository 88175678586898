import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../Context';
import { MdArrowBackIosNew } from "react-icons/md";
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { sendRequest } from '../../API';
import Loader from '../../Component/Spinners/Loader';
import { Inputs } from '../../Component/Authenticate/Inputs';
// import Loader from ".././../Component/Spinners/Loader";


const EditProfile = () => {
  const { handleProfile, setCustomToast, user } = useContext(GlobalContext);
  const userData = (user && user.data) || {}
  const { empName, empID, emailID, empAddress, empContact, companyID } = handleProfile.items || {};
  const history = useHistory();
  const [btnTxt, setBtnTxt] = useState("Update");
  const { handleSubmit, formState: { errors }, register } = useForm();

  useEffect(() => {
    if (!emailID) return history.goBack();
  }, [history]);


  const save_profile = async (data) => {
    let { empName, empAddress, empContact } = data;
    setBtnTxt("Processing...");
    try {
      await sendRequest("PUT", "employee/user/updateUser", {
        ...handleProfile.items, empName, empAddress, empContact,
        companyID: companyID
      });
      setBtnTxt("Update");
      history.goBack();
      setCustomToast({ content: `Profile Updated Successfully!`, open: true, error: false });
    } catch (error) {
      setBtnTxt("Update");
      const { status, data } = error.response || {};
      if (status === 403) return toast.error(data.message);
      toast.error("Something went wrong!");
    }
  };


  return <section className='edit--profile'>
    <div className='heading'>
      <h1><MdArrowBackIosNew onClick={() => history.goBack()} /> Edit Profile</h1>
      <p>Manage {(userData.emailID === emailID) ? "your" : "Employee's"} Reqo Profile</p>
    </div>
    <hr />
    <form action="" onSubmit={handleSubmit(save_profile)}>
      <div className='edit--content'>
        <h4>Profile Picture</h4>
        <article className='user--image'>
          <p>{empName && empName.substring(0, 1)}</p>
        </article>

        <p className='email'>Email <br /> <span>{emailID}</span></p>

        <article className='edit--name'>
          <label>Name</label>
          <Inputs value={empName} register={register} errors={errors} name={"empName"}
            placeholder="Employee Name*"
            require={true} />
        </article>

        <p>Employee ID <br /><span className='empID'>{empID}</span></p>

        <article className='edit--address'>
          <label>Employee Address</label>
          <Inputs value={empAddress} register={register} errors={errors} name={"empAddress"}
            placeholder="Employee Address*"
            require={true} />
        </article>

        <article className='edit--contact'>
          <label>Employee Contact</label>
          <Inputs value={empContact} register={register} errors={errors} name={"empContact"}
            placeholder="Employee Contact"
            require={true} />
        </article>
      </div>

      <button className='btn' disabled={btnTxt.includes("...")}>
        <span> {!btnTxt.includes("...") ? btnTxt
          : <>{btnTxt} <Loader size={18} borderTopColor="#0043FF" /></>}</span>
      </button>
    </form>
  </section>
}

export default EditProfile;