import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { sendRequest } from "../../../API";
import { OnBoardingSkipUtils } from "../../../Component/OnBoardingUtils";
import { SkeletonLoader } from "../../../Component/Spinners/Skeleton";
import { GlobalContext } from "../../../Context";

const Invoices = () => {
  const { companyData, getCompanyData, setGlobalError } =
    useContext(GlobalContext);
  const [invoiceData, setInvoiceData] = useState([{}]);
  const { customerID } = companyData;

  const getInvoices = async () => {
    try {
      const getData = await sendRequest("POST", "stripe/getinvoice", {
        customerID,
      });
      const { result } = getData.data;
      const { data } = result["invoice list"];
      if (data && data.length > 0) setInvoiceData(data);
      else setInvoiceData([]);
    } catch (err) {
      setGlobalError(err.response);
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    getCompanyData();
    if (customerID) {
      getInvoices();
    }
  }, [customerID]);

  return (
    <main>
      {invoiceData.length > 0 ? (
        <section className="invoices">
          <div className="invoices--content">
            <h5>Invoices</h5>
            <table className="table">
              <thead className="table--head">
                <tr>
                  <th scope="col">Date</th>
                  <th>Description</th>
                  <th scope="col">Billed Seats</th>
                  <th scope="col">Invoice Total</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              {invoiceData &&
                invoiceData.map((item, i) => {
                  let {
                    created,
                    amount_paid,
                    billing_reason,
                    lines,
                    hosted_invoice_url,
                    total,
                  } = item;
                  let line_items = (lines && lines.data.length) || {};
                  let { quantity, currency } =
                    (lines && lines.data[line_items - 1]) || {};

                  let currencyCheck = currency === "inr" ? "₹" : "$";
                  amount_paid = Math.abs((amount_paid / 100).toFixed(0));
                  total = Math.abs((total / 100).toFixed(0));

                  return (
                    <tbody className="table--body" key={i}>
                      <tr>
                        <td>
                          {created ? (
                            moment(created * 1000).format("MMM DD YYYY")
                          ) : (
                            <SkeletonLoader />
                          )}
                        </td>
                        <td className="description">
                          {billing_reason ? (
                            billing_reason + " (Montly)"
                          ) : (
                            <SkeletonLoader />
                          )}{" "}
                        </td>
                        <td>{quantity || <SkeletonLoader />}</td>
                        <td>
                          {amount_paid || total ? (
                            currencyCheck + (amount_paid || total)
                          ) : (
                            <SkeletonLoader />
                          )}
                        </td>
                        <td>
                          {quantity ? (
                            <p className="view--invoice">
                              {" "}
                              Paid
                              <a
                                href={hosted_invoice_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Invoice
                              </a>
                            </p>
                          ) : (
                            <SkeletonLoader />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
            </table>
          </div>
        </section>
      ) : (
        <section className="no--request--section">
          <article className="no--request">
            <OnBoardingSkipUtils />
            <h1>No Invoices</h1>
            <p>It's seems you don't have any previous invoices.</p>
          </article>
        </section>
      )}
    </main>
  );
};
export default Invoices;
