import React from 'react';
import "../Style/loader.css";


const Loader = ({ size, borderColor, borderTopColor }) => {
  return <div className="loader"
    style={{
      width: size || 20,
      height: size || 20,
      border: `4px solid ${borderColor ? borderColor : "whitesmoke"}`,
      borderTop: `4px solid ${borderTopColor ? borderTopColor : "#3263F3"}`,
    }}></div>
}

export default Loader;