import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { toast } from "react-toastify";
import { getRequest } from '../../../API';
import { useHistory } from 'react-router-dom';
import Loader from '../../../Component/Spinners/Loader';
import { SkeletonLoad } from '../../../Component/Spinners/Skeleton';
const stripePromise = loadStripe("pk_test_51LYPjrSAtWblKhEaGehR7pbsyYv9v5LJsvl2vazMYPRwrXOBU29vTvA0l2aJw82TNDeagcDaCXz0fY0TW3XIGbUp00KIyjUEMA");


const HanldeUpdateCard = ({ subscriptionStatus, cancelAtperiodEnd, cusId, subId }) => {
  const history = useHistory();
  const [loader, setLoader] = useState({ getCard: false, updateCard: false });
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  const [card, setCard] = useState({});



  const getCardNumber = async (cusId) => {
    try {
      setLoader({ getCard: true });
      const getCards = await getRequest(`stripe/getcard?cusId=${cusId}`);
      const { result: { data } } = getCards.data || {};
      setLoader({ getCard: false });
      if (data && data.length > 0) {
        setCard(data[0].card);
      }
    } catch (error) {
      setLoader({ getCard: false });
      const { status, data } = error.response || {};
      if (status === 403) return toast.error(data.message);
      toast.info("Unable to fetch card!");
    }
  }


  const updateCardPostCheckout = (id) => {
    getRequest(`stripe/updatecard?checkoutId=${id}`).then(() => {
      setLoader({ updateCard: false });
      toast.success("Card updated!");
      localStorage.clear();
    }).catch(() => {
      setLoader({ updateCard: false });
      toast.error("Something went worng!");
    })
  };
  const getCheckoutId = localStorage.getItem("checkoutId");
  const queryParams = new URLSearchParams(window.location.search);
  const getSessionId = queryParams.get("session_id");
  useEffect(() => {
    if (getSessionId && getCheckoutId) {
      setLoader({ updateCard: true });
      updateCardPostCheckout(getCheckoutId);
      history.push("/dashboard/billing");
    }
    if (cusId && subscriptionStatus !== "null") getCardNumber(cusId);
  }, [getSessionId, cusId]);



  const handleClick = async () => {
    try {
      setCheckoutLoader(true);
      const stripe = await stripePromise;
      const { data: { result } } = await getRequest(`stripe/checkout?cusId=${cusId}&subId=${subId}`);
      if (result.id) {
        setCheckoutLoader(false);
        localStorage.setItem('checkoutId', result.id);
        const getResult = await stripe.redirectToCheckout({ sessionId: result.id });
        if (getResult.error) return toast.info("Failed to update card!");
      }
    } catch (error) {
      setCheckoutLoader(false);
      const { status, data } = error.response || {};
      if (status === 403) return toast.error(data.message);
      toast.error("Something went worng!");
    }
  };


  return <article className='card--detials'>
    <p>Billing Card</p>

    {(loader.getCard) ? <SkeletonLoad /> : subscriptionStatus !== "null"
      ? loader.updateCard ? <div className='mt-1'>< Loader /> Updating...</div>
        : <><p>{card.last4 ? ("xxxx xxxx xxxx " + card.last4) : "--"}</p>
          {(subscriptionStatus !== "ACTIVE") || (cancelAtperiodEnd === true)
            ? <p className='no--operation'>Change Card Details</p>
            : <p onClick={() => handleClick()}>{checkoutLoader ? <Loader /> : "Change Card Details"}</p>
          }</>
      : <p>--</p>
    }
  </article >
}

export default HanldeUpdateCard;