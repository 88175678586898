import React from 'react'

const GroupTwo = ({ className }) => {
  return <svg className={className} width="205" height="205" viewBox="0 0 205 205" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M47.6058 65.8359L164.747 56.2069L21.6809 101.297L188.161 94.9502L24.4862 152.237" stroke="#EC5D6D"
      strokeWidth="16.8653" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M56.8909 48.7752L86.8473 26.551" stroke="white" strokeWidth="3.19757" strokeLinecap="round" />
    <path d="M47.1973 40.8491L52.9837 4.00056" stroke="white" strokeWidth="3.19757" strokeLinecap="round" />
    <path d="M57.0407 36.3368L68.6028 19.8064" stroke="white" strokeWidth="3.19757" strokeLinecap="round" />
  </svg>
}



export {  GroupTwo };