import React, { Fragment } from 'react'
import Welcome from '../../../Component/Authenticate/Welcome';
import { UtilsEmptyCategory, UtilsNoEmployee } from '../../../Component/DashboardUtils';
import { OnBoardingSkipUtils, OnboardingUtilsFlowComplete, OnBoardUtilsHand } from '../../../Component/OnBoardingUtils';
import { MdCheck } from "react-icons/md"
import AddCategory from '../../../Component/Modals/AddCategoryModal';


const InitialStep = ({ history }) => {
  return <section className='onboard--initial animate__animated animate__fadeIn'>
    <OnBoardUtilsHand />
    <h2>Welcome to your Dashboard !</h2>
    <small>Lets help you set up your workspace.</small>
    <article className='start--buttons'>
      <button onClick={() => history.push({ state: { path: "start flow" } })}>Lets Go</button>
      <button onClick={() => history.push({ state: { path: "skip flow" } })}>I'll skip it</button>
    </article>
  </section>
};

const HoldOnStep = () => {
  return <section className='step1--add_members skip_flow'>
    <OnBoardingSkipUtils />
    <h2>Hold On!</h2>
    <small>You're just few steps away, <br />Looking out for some configuration...</small>
  </section>
};


const StepSkip = () => {
  return <section className='step1--add_members skip_flow'>
    <OnBoardingSkipUtils />
    <h2>You're a just few steps away</h2>
    <small>To begin requesting, invite your team <br /> members  followed by adding categories.</small>
  </section>
}

const Step1 = ({ history }) => {
  return <section className='step1--add_members'>
    <article className='progress--view'>
      <p>1</p>
      <hr />
      <p>2</p>
    </article>
    <UtilsNoEmployee />
    <h2>Step 1: Add Team Members</h2>
    <small>Start with adding Admins, followed by <br /> Responders and Requesters to your workspace. </small>
    <article className='step1--buttons'>
      <button onClick={() => history.push({ state: { path: "add members" } })}>Add Members</button>
    </article>
  </section>
}

// Step2 add Member form......

const Step3 = ({ history }) => {
  return <section className='step1--add_members step3'>
    <article className='progress--view modified--progress'>
      <p><MdCheck /></p>
      <hr />
      <p>2</p>
    </article>
    <Welcome />
    <h2>Team Members Added</h2>
    <small>Your team members have been successfully invited.</small>
    <article className='step3--buttons'>
      <button onClick={() => history.push({ state: { path: "add members" } })}>Back</button>
      <button onClick={() => history.push({ state: { path: "category" } })}>Next</button>
    </article>
  </section>
}

const Step4 = ({ history }) => {
  return <section className='step1--add_members step4'>
    <article className='progress--view modified--progress'>
      <p><MdCheck /></p>
      <hr />
      <p>2</p>
    </article>
    <UtilsEmptyCategory />
    <h2>Step 2 : Add Categories and Items</h2>
    <small>Add in categories along with  items that <br /> your team members can request </small>
    <article>
      <button onClick={() => history.push({ state: { path: "add category" } })}>Add Category</button>
    </article>
  </section>
}

// Step5 adding category modal......
const Step5 = () => {
  return (
    <Fragment className='category-onboard-modal'>
      <article className='progress--view modified--progress'>
        <p><MdCheck /></p>
        <hr />
        <p>2</p>
      </article>
      <AddCategory isOnboarding={true} />
    </Fragment>
  )
}


const Step6 = ({ history }) => {
  return <section className='step1--add_members step6'>
    <article className='progress--view modified--progress'>
      <p><MdCheck /></p>
      <hr />
      <p><MdCheck /></p>
    </article>
    <Welcome />
    <h2>Category Added</h2>
    <small>Your category has been successfully added in the categories tab.</small>
    <article className='step3--buttons'>
      <button onClick={() => history.push({ state: { path: "add category" } })}>Back</button>
      <button onClick={() => history.push({ state: { path: "flow end" } })}>Finish</button>
    </article>
  </section>
}
const StepEnd = ({ history }) => {
  return <section className='step1--add_members stepend'>
    <OnboardingUtilsFlowComplete />
    <h2>You're all set!</h2>
    <small>You're all ready to start Requesting</small>
    <article className='step3--buttons'>
      <button onClick={() => {
        localStorage.setItem("onboard_complete", true);
        history.push("/dashboard");
      }}>Go to Dashboad</button>
    </article>
  </section>
}



export { InitialStep, HoldOnStep, Step1, Step3, Step4, StepSkip, Step5, Step6, StepEnd };