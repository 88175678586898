import React, { useContext, useEffect, useState } from 'react'
import { HiOutlinePencil, HiOutlineCheck } from "react-icons/hi";
import { IoCloseOutline } from "react-icons/io5"
import { toast } from 'react-toastify';
import { sendRequest } from '../../../API';
import Loader from '../../../Component/Spinners/Loader';
import { GlobalContext } from '../../../Context';
import { FeatureSubContext } from '../FeatureContext';


const HandleSerialNumber = ({ items }) => {
  const { setCustomToast, user } = useContext(GlobalContext);
  const { setRequestView } = useContext(FeatureSubContext);
  const { emailID, ticketNo, serialNumber } = items || {};
  const [openSerialNumber, setOpenSerialNumber] = useState(false);
  const [serial, setSerial] = useState();
  const [loader, setLoader] = useState(false);
  const { data } = user || {};


  useEffect(() => {
    setSerial(serialNumber);
  }, [serialNumber]);

  const submit_serialnumber = async () => {
    if (!serial) return toast.info("Serial number is required!");
    setLoader(true);
    try {
      const res = await sendRequest("POST", "items/serialnumber", {
        emailID, ticketNo,
        serialNumber: serial,
        serialNumberUpdate: true,
        updaterName: data && data.empName.split(" ")[0],
        updaterRole: data.empRole
      });
      const { result: { Attributes } } = res.data || {};
      if (Attributes) setRequestView({ ...items, serialNumber: Attributes.serialNumber, history: Attributes.history });
      setLoader(false);
      setOpenSerialNumber(false);
      setCustomToast({ content: `Serial no. updated for ticket #${ticketNo}`, open: true, error: false });
    } catch (error) {
      setLoader(false);
      setCustomToast({ content: `Something went wrong!`, open: true, error: true });
    }
  }


  return <article className='serial--number'>
    <div><b>Serial No: </b>&nbsp;</div>
    <div>
      <span className='serial'>
        {openSerialNumber
          ? <input value={serial} onChange={(e) => setSerial(e.target.value)}
            type="text" className='form-control' placeholder='Enter Serial no.' />
          : serialNumber ? serialNumber : "No serial number to display "
        }
        &nbsp;
        {!loader ? <span>{openSerialNumber
          ? <>
            <HiOutlineCheck className='icon' onClick={() => submit_serialnumber()} />
            <IoCloseOutline className='icon' onClick={() => setOpenSerialNumber(false)} />
          </>
          : (data && data.emailID !== emailID) && <HiOutlinePencil onClick={() => setOpenSerialNumber(true)} />}
        </span> : <Loader />
        }
      </span>
    </div>
  </article>
}

export default HandleSerialNumber;