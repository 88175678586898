import React, { useContext } from 'react'
import "../Style/navigation.css";
import { UtilsCategory, UtilsEmployees, UtilsFolder } from "./SidebarUtils";
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../Context';
import { useEffect } from 'react';
import { BiPlus } from "react-icons/bi";
import { IoHelpCircleOutline } from "react-icons/io5"


const SideBar = () => {
  const { user, setModalUtils, setOpenViewItemsModal, setHandleProfile } = useContext(GlobalContext);
  const { empRole } = (user && user.data) || {};
  const { companyName } = (user && user.company) || {};
  const { pathname } = useLocation();
  const history = useHistory()

  useEffect(() => {
    setModalUtils({ createRequestModal: false });
  }, [pathname]);


  const sidebar_tabs = (path, text, Utils) => {
    return <div className='navigation--tab'><NavLink onClick={() => {
      setHandleProfile({ open: false });
      setOpenViewItemsModal(false)
    }} exact to={path}
      className={(isActive) => isActive ? "selected" : "unselect"}>
      <p> <Utils path={path} /> &nbsp; <span>{text}</span></p>
    </NavLink></div>
  };


  return <main className='main--sidebar'>
    <div className='company--info'>
      <p className='company--logo'>{companyName && companyName.substring(0, 1)}</p>
      <article>
        <h6>Team {companyName && companyName.split(" ")[0]}</h6>
        <small>Free Trail
          {empRole && empRole.includes("super") ? <> &bull;&nbsp; <span onClick={() => history.push("/dashboard/billing")}>Learn More</span> </> : null}
        </small>
      </article>
    </div>

    <button onClick={() => setModalUtils({ createRequestModal: true })}>
      <BiPlus /> Create Request
    </button>

    {sidebar_tabs("/dashboard", "Requests", UtilsFolder)}
    {
      (empRole && empRole.includes("admin")) ? <>
        {sidebar_tabs("/dashboard/employee", "Teams", UtilsEmployees)}
        {sidebar_tabs("/dashboard/category", "Categories", UtilsCategory)}
      </> : null
    }
    <div className='bottom--content'>
      <article>
        <hr />
        <div className='help--support'>
          <p className='help--logo'><IoHelpCircleOutline className='logo' /></p>
          <p onClick={() => { history.push('/dashboard/help') }}>Help and Support <br /> v 1.3</p>
        </div>
      </article>

      {/* <section className={`sidebar--user_info ${pathname.includes("/paymentscreen") || pathname.includes("/category")
        ? "sidebar--user_info--modified" : null}`}
        onClick={() => history.push("/dashboard/profile")}>
        <article className='username_logo'>
          {(user && user.profile_img)
            ? <img src={user.profile_img} alt="" />
            : <span>{empName && empName.substring(0, 1)}</span>
          }
        </article>
        <article className='username'>
          <span className='name'>{empName && empName.split(" ")[0]}</span>
          <span>({companyName})</span>
        </article>
      </section> */}
    </div>
  </main>
}

export default SideBar;