import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import AddMembers from './AddMembers';
import { InitialStep, HoldOnStep, StepSkip, Step1, Step3, Step4, Step6, StepEnd, Step5 } from './SwitchModal';
import { GlobalContext } from '../../../Context';



const Onboard = () => {
  const { companyData, getCompanyData } = useContext(GlobalContext);
  const history = useHistory();
  const { path } = history.location.state || {};
  const [loader, setLoader] = useState(true);
  const check_onboard = localStorage.getItem("onboard_complete");


  useEffect(() => {
    if (!check_onboard) getCompanyData().then(() => {
      setLoader(false)
    }).catch(() => setLoader(true));
  }, [check_onboard]);


  const styles = {
    marginTop: path &&
      (path.includes("add members") || path.includes("category")) ? "-1.5%" : null
  };

  const path_handler = (path) => {
    switch (path) {
      case "skip flow":
        return <StepSkip />
      case "hold on":
        return <HoldOnStep />
      case "start flow":
        return <Step1 history={history} />
      case "add members":
        return <AddMembers history={history} />
      case "members added":
        return <Step3 history={history} />
      case "category":
        return <Step4 history={history} />
      case "add category":
        return <Step5 />
      case "categories added":
        return <Step6 history={history} />
      case "flow end":
        return <StepEnd history={history} />
      default:
        return <InitialStep history={history} />
    }
  };


  if (check_onboard) return <section className='onboarding'>
    <div className='onboard--modal' style={styles}>
      {path_handler("flow end")}
    </div>
  </section>


  return loader ? <section className='onboarding'>
    <div className='onboard--modal' style={styles}>
      {path_handler("hold on")}
    </div>
  </section> : <section className='onboarding'>
    <div className='onboard--modal' style={styles}>
      {(companyData.subscriptionStatus === "null") ? path_handler(path) : path_handler("flow end")}
    </div>
  </section>
}

export default Onboard;



// import React, { Fragment, useContext } from 'react'
// import { useHistory } from "react-router-dom";
// import AddCategory from '../../../Component/Modals/AddCategoryModal';
// import AddMembers from './AddMembers';
// import { InitialStep, Step1, Step3, Step4, StepSkip, Step6, StepEnd, Step5 } from './SwitchModal';
// const Onboard = () => {
//   const history = useHistory();
//   const { path } = history.location.state || {};
//   const styles = {
//     marginTop: path &&
//       (path.includes("add members")
//         || path.includes("category")) ? "-1.5%" : null
//   };
//   const path_handler = (path) => {
//     switch (path) {
//       case "skip flow":
//         return <StepSkip />
//       case "start flow":
//         return <Step1 history={history} />
//       case "add members":
//         return <AddMembers history={history} />
//       case "members added":
//         return <Step3 history={history} />
//       case "category":
//         return <Step4 history={history} />
//       case "add category":
//         return <Step5 />
//       case "categories added":
//         return <Step6 history={history} />
//       case "flow end":
//         return <StepEnd history={history} />
//       default:
//         return <InitialStep history={history} />
//     }
//   }
//   return <section className='onboarding'>
//     <div className='onboard--modal' style={styles}>
//       {path_handler(path)}
//     </div>
//   </section>
// }
// export default Onboard;