import React, { useState, useContext, useEffect } from 'react'
import PersonalDetails from './PersonalDetails'
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { sendRequest } from '../../API';
import Loader from '../../Component/Spinners/Loader';
import { GlobalContext } from '../../Context';

const UserWorkspace = () => {
    const [tabSwitch, setTabSwitch] = useState(true)
    const [tabs, setTabs] = useState(false);
    const [btnTxt, setBtnTxt] = useState("Select WorkSpace")
    const [margin, setMargin] = useState(false)
    const [btnActive, setBtnActive] = useState(false)
    const [userData, setUserData] = useState({})
    const [dashboardBtn, setDashboardBtn] = useState("Go to DashBoard")
    const { handleSubmit, formState: { errors }, register, watch } = useForm();
    const watchValues = watch()

    const { setCookie } = useContext(GlobalContext);
    const history = useHistory();
    const { totalCount, getDomain, companyID, picture } = history.location.state || {};

    const submitUserData = (data) => {
        setUserData(data)
        setBtnTxt("Processing...")
        setTimeout(() => {
            setTabs(true);
            setTabSwitch(false);
            setBtnTxt("Select WorkSpace");
            setMargin(true)
        }, 1000);
    }
    const handleRadioChange = () => {
        setBtnActive(!btnActive)
    }
    const handleUser = () => {
        setDashboardBtn("Processing...")
        const resultData = {
            companyID,
            emailID: userData.userEmailID,
            empName: userData.givenName,
            empID: userData.employeeID,
            empAddress: userData.empAddress,
            doj: userData.doj,
            company: userData.companyName,
            actualEmployeeCount: totalCount
        }
        setBtnActive(!btnActive)
        sendRequest('POST', 'auth?register=true', resultData)
            .then((res) => {
                setDashboardBtn("Go to DashBoard")
                const { token } = res.data && res.data;
                setCookie("u_detials", token);
                setCookie("g_token", picture);
                setTimeout(() => {
                    return window.location.href = "/dashboard";
                }, 200);
            })
            .catch((err) => {
                setDashboardBtn("Go to DashBoard")
            })
    }

    useEffect(() => {
        if (!getDomain) return history.push('/login');
    })
    return (
        <div className={`create--workspace ${margin ? 'margin-inc' : null}`}>
            <div className='tabs--details'>
                <article onClick={() => {
                    setTabSwitch(true)
                    setTabs(false);
                }}>
                    <p><span className='tab--number'>1</span></p>
                    <p>Personal Details</p>
                </article>
                <article className={!tabs ? "no--operation" : null} onClick={() => tabs ? setTabSwitch(false) : null}>
                    <p><span className='tab--number'>2</span></p>
                    <p>Select Workspace</p>
                </article>
            </div>
            <div>
                {
                    tabSwitch ? (
                        <PersonalDetails
                            submit_data={submitUserData}
                            register={register}
                            handleSubmit={handleSubmit}
                            errors={errors}
                            watchValues={watchValues}
                            btnTxt={btnTxt}
                        />
                    ) : (
                        <div className='workspace-select'>
                            <div className='select-space'>
                                <div className='company-logo'>
                                    <span>{getDomain[0].charAt(0).toUpperCase()}</span>
                                </div>
                                <div className='company-details'>
                                    <p className='heading'>Team {getDomain && `${getDomain[0].charAt(0).toUpperCase()}${getDomain[0].slice(1)}`}</p>
                                    <p className='info'>{totalCount} Members</p>
                                </div>
                                <div className='company-select'>
                                    <input type='radio' checked={btnActive === false && dashboardBtn.includes("...") ? true : btnActive} className='select-me' onClick={handleRadioChange} />
                                </div>
                            </div>
                            <div className='select-button'>
                                <button className={`${btnActive ? 'button-background' : null}`} disabled={!btnActive} onClick={handleUser}>{dashboardBtn}</button>
                                {dashboardBtn.includes("...") ? <Loader className='loader' /> : null}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default UserWorkspace