import React, { useContext } from 'react'
import { RiCloseLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../Context';

const ViewEmployeeData = () => {
  const { pathname } = useLocation();
  const { closeModal, setCloseModal, setOpenViewItemsModal, viewItem, setViewItem, setModalUtils }
    = useContext(GlobalContext);
  const { empName, empRole, empID, emailID, doj, empAddress, responderID } = viewItem || {};

  const handleClose = (time, check) => {
    setTimeout(() => {
      setCloseModal({ sentRequest: false });
      setOpenViewItemsModal(false);
      check && setViewItem({});
    }, time);
    setCloseModal({ sentRequest: true });
  }


  return <section className={`employee--viewdata animate__animated animate__bounceIn 
  ${closeModal.sentRequest ? "animate__bounceOut" : null}`}>
    <article>
      <h1><b>Employee Details</b> <span onClick={() => {
        handleClose(400, true);
      }}><RiCloseLine /></span> </h1>
    </article>

    <main className='employee--content'>
      <div className='first--content'>
        <article className='employee--img'><p>{empName && empName.substring(0, 1)}</p></article>
        <p className='text'>Profile Picture can only be modified by user.</p>
        <button className='btn edit--detials' onClick={() => {
          if (!pathname.includes("profile")) {
            setViewItem(viewItem);
            handleClose(100);
            setModalUtils({ addEmployee: true });
          }
        }}>Edit Detials</button>
      </div>
      <div className='second--content'>
        <h1>{empName && empName.split(" ")[0]} <span>&bull; {empRole}</span> </h1>
        <p className='empId'>{empID} - FTE</p>
        <article className='email'>
          <p>Email <br /> <span>{emailID}</span> </p>
        </article>
        <article className='address'>
          {empAddress ? <p>Address <br /> <span>{empAddress}</span> </p> : null}
        </article>
        <article className='doj'>
          {doj ? <p>Joined <br /> <span>{doj}</span> </p> : null}
        </article>
        <article>
          {responderID ? <p>ApproverID <br /> <span>{responderID}</span> </p> : null}
        </article>
      </div>
    </main>

  </section >
}

export default ViewEmployeeData;