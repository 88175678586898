const industry_items = [
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Technology", value: "Technology" },
  { label: "Advertising & Marketing", value: "Advertising & Marketing" },
  { label: "Design", value: "Design" },
  { label: "E- Commerce", value: "E- Commerce" },
  { label: "Health Care", value: "Health Care" },
  { label: "Real Estate", value: "Real Estate" }
];

const description_items = [
  { label: "Twitter", value: "Twitter" },
  { label: "Word Of Mouth", value: "Word Of Mouth" },
  { label: "LinkedIn", value: "LinkedIn" },
  { label: "Blog Post", value: "Blog Post" },
  { label: "Recommendation", value: "Recommendation" },
  { label: "Web Surfing", value: "Web Surfing" }
];


const country_items = [
  {
    "label": "Afghanistan",
    "dial_code": "+93",
    "value": "AF"
  },
  {
    "label": "Aland Islands",
    "dial_code": "+358",
    "value": "AX"
  },
  {
    "label": "Albania",
    "dial_code": "+355",
    "value": "AL"
  },
  {
    "label": "Algeria",
    "dial_code": "+213",
    "value": "DZ"
  },
  {
    "label": "AmericanSamoa",
    "dial_code": "+1684",
    "value": "AS"
  },
  {
    "label": "Andorra",
    "dial_code": "+376",
    "value": "AD"
  },
  {
    "label": "Angola",
    "dial_code": "+244",
    "value": "AO"
  },
  {
    "label": "Anguilla",
    "dial_code": "+1264",
    "value": "AI"
  },
  {
    "label": "Antarctica",
    "dial_code": "+672",
    "value": "AQ"
  },
  {
    "label": "Antigua and Barbuda",
    "dial_code": "+1268",
    "value": "AG"
  },
  {
    "label": "Argentina",
    "dial_code": "+54",
    "value": "AR"
  },
  {
    "label": "Armenia",
    "dial_code": "+374",
    "value": "AM"
  },
  {
    "label": "Aruba",
    "dial_code": "+297",
    "value": "AW"
  },
  {
    "label": "Australia",
    "dial_code": "+61",
    "value": "AU"
  },
  {
    "label": "Austria",
    "dial_code": "+43",
    "value": "AT"
  },
  {
    "label": "Azerbaijan",
    "dial_code": "+994",
    "value": "AZ"
  },
  {
    "label": "Bahamas",
    "dial_code": "+1242",
    "value": "BS"
  },
  {
    "label": "Bahrain",
    "dial_code": "+973",
    "value": "BH"
  },
  {
    "label": "Bangladesh",
    "dial_code": "+880",
    "value": "BD"
  },
  {
    "label": "Barbados",
    "dial_code": "+1246",
    "value": "BB"
  },
  {
    "label": "Belarus",
    "dial_code": "+375",
    "value": "BY"
  },
  {
    "label": "Belgium",
    "dial_code": "+32",
    "value": "BE"
  },
  {
    "label": "Belize",
    "dial_code": "+501",
    "value": "BZ"
  },
  {
    "label": "Benin",
    "dial_code": "+229",
    "value": "BJ"
  },
  {
    "label": "Bermuda",
    "dial_code": "+1441",
    "value": "BM"
  },
  {
    "label": "Bhutan",
    "dial_code": "+975",
    "value": "BT"
  },
  {
    "label": "Bolivia, Plurinational State of",
    "dial_code": "+591",
    "value": "BO"
  },
  {
    "label": "Bosnia and Herzegovina",
    "dial_code": "+387",
    "value": "BA"
  },
  {
    "label": "Botswana",
    "dial_code": "+267",
    "value": "BW"
  },
  {
    "label": "Brazil",
    "dial_code": "+55",
    "value": "BR"
  },
  {
    "label": "British Indian Ocean Territory",
    "dial_code": "+246",
    "value": "IO"
  },
  {
    "label": "Brunei Darussalam",
    "dial_code": "+673",
    "value": "BN"
  },
  {
    "label": "Bulgaria",
    "dial_code": "+359",
    "value": "BG"
  },
  {
    "label": "Burkina Faso",
    "dial_code": "+226",
    "value": "BF"
  },
  {
    "label": "Burundi",
    "dial_code": "+257",
    "value": "BI"
  },
  {
    "label": "Cambodia",
    "dial_code": "+855",
    "value": "KH"
  },
  {
    "label": "Cameroon",
    "dial_code": "+237",
    "value": "CM"
  },
  {
    "label": "Canada",
    "dial_code": "+1",
    "value": "CA"
  },
  {
    "label": "Cape Verde",
    "dial_code": "+238",
    "value": "CV"
  },
  {
    "label": "Cayman Islands",
    "dial_code": "+ 345",
    "value": "KY"
  },
  {
    "label": "Central African Republic",
    "dial_code": "+236",
    "value": "CF"
  },
  {
    "label": "Chad",
    "dial_code": "+235",
    "value": "TD"
  },
  {
    "label": "Chile",
    "dial_code": "+56",
    "value": "CL"
  },
  {
    "label": "China",
    "dial_code": "+86",
    "value": "CN"
  },
  {
    "label": "Christmas Island",
    "dial_code": "+61",
    "value": "CX"
  },
  {
    "label": "Cocos (Keeling) Islands",
    "dial_code": "+61",
    "value": "CC"
  },
  {
    "label": "Colombia",
    "dial_code": "+57",
    "value": "CO"
  },
  {
    "label": "Comoros",
    "dial_code": "+269",
    "value": "KM"
  },
  {
    "label": "Congo",
    "dial_code": "+242",
    "value": "CG"
  },
  {
    "label": "Congo, The Democratic Republic of the Congo",
    "dial_code": "+243",
    "value": "CD"
  },
  {
    "label": "Cook Islands",
    "dial_code": "+682",
    "value": "CK"
  },
  {
    "label": "Costa Rica",
    "dial_code": "+506",
    "value": "CR"
  },
  {
    "label": "Cote d'Ivoire",
    "dial_code": "+225",
    "value": "CI"
  },
  {
    "label": "Croatia",
    "dial_code": "+385",
    "value": "HR"
  },
  {
    "label": "Cuba",
    "dial_code": "+53",
    "value": "CU"
  },
  {
    "label": "Cyprus",
    "dial_code": "+357",
    "value": "CY"
  },
  {
    "label": "Czech Republic",
    "dial_code": "+420",
    "value": "CZ"
  },
  {
    "label": "Denmark",
    "dial_code": "+45",
    "value": "DK"
  },
  {
    "label": "Djibouti",
    "dial_code": "+253",
    "value": "DJ"
  },
  {
    "label": "Dominica",
    "dial_code": "+1767",
    "value": "DM"
  },
  {
    "label": "Dominican Republic",
    "dial_code": "+1849",
    "value": "DO"
  },
  {
    "label": "Ecuador",
    "dial_code": "+593",
    "value": "EC"
  },
  {
    "label": "Egypt",
    "dial_code": "+20",
    "value": "EG"
  },
  {
    "label": "El Salvador",
    "dial_code": "+503",
    "value": "SV"
  },
  {
    "label": "Equatorial Guinea",
    "dial_code": "+240",
    "value": "GQ"
  },
  {
    "label": "Eritrea",
    "dial_code": "+291",
    "value": "ER"
  },
  {
    "label": "Estonia",
    "dial_code": "+372",
    "value": "EE"
  },
  {
    "label": "Ethiopia",
    "dial_code": "+251",
    "value": "ET"
  },
  {
    "label": "Falkland Islands (Malvinas)",
    "dial_code": "+500",
    "value": "FK"
  },
  {
    "label": "Faroe Islands",
    "dial_code": "+298",
    "value": "FO"
  },
  {
    "label": "Fiji",
    "dial_code": "+679",
    "value": "FJ"
  },
  {
    "label": "Finland",
    "dial_code": "+358",
    "value": "FI"
  },
  {
    "label": "France",
    "dial_code": "+33",
    "value": "FR"
  },
  {
    "label": "French Guiana",
    "dial_code": "+594",
    "value": "GF"
  },
  {
    "label": "French Polynesia",
    "dial_code": "+689",
    "value": "PF"
  },
  {
    "label": "Gabon",
    "dial_code": "+241",
    "value": "GA"
  },
  {
    "label": "Gambia",
    "dial_code": "+220",
    "value": "GM"
  },
  {
    "label": "Georgia",
    "dial_code": "+995",
    "value": "GE"
  },
  {
    "label": "Germany",
    "dial_code": "+49",
    "value": "DE"
  },
  {
    "label": "Ghana",
    "dial_code": "+233",
    "value": "GH"
  },
  {
    "label": "Gibraltar",
    "dial_code": "+350",
    "value": "GI"
  },
  {
    "label": "Greece",
    "dial_code": "+30",
    "value": "GR"
  },
  {
    "label": "Greenland",
    "dial_code": "+299",
    "value": "GL"
  },
  {
    "label": "Grenada",
    "dial_code": "+1473",
    "value": "GD"
  },
  {
    "label": "Guadeloupe",
    "dial_code": "+590",
    "value": "GP"
  },
  {
    "label": "Guam",
    "dial_code": "+1671",
    "value": "GU"
  },
  {
    "label": "Guatemala",
    "dial_code": "+502",
    "value": "GT"
  },
  {
    "label": "Guernsey",
    "dial_code": "+44",
    "value": "GG"
  },
  {
    "label": "Guinea",
    "dial_code": "+224",
    "value": "GN"
  },
  {
    "label": "Guinea-Bissau",
    "dial_code": "+245",
    "value": "GW"
  },
  {
    "label": "Guyana",
    "dial_code": "+595",
    "value": "GY"
  },
  {
    "label": "Haiti",
    "dial_code": "+509",
    "value": "HT"
  },
  {
    "label": "Holy See (Vatican City State)",
    "dial_code": "+379",
    "value": "VA"
  },
  {
    "label": "Honduras",
    "dial_code": "+504",
    "value": "HN"
  },
  {
    "label": "Hong Kong",
    "dial_code": "+852",
    "value": "HK"
  },
  {
    "label": "Hungary",
    "dial_code": "+36",
    "value": "HU"
  },
  {
    "label": "Iceland",
    "dial_code": "+354",
    "value": "IS"
  },
  {
    "label": "India",
    "dial_code": "+91",
    "value": "IN"
  },
  {
    "label": "Indonesia",
    "dial_code": "+62",
    "value": "ID"
  },
  {
    "label": "Iran, Islamic Republic of Persian Gulf",
    "dial_code": "+98",
    "value": "IR"
  },
  {
    "label": "Iraq",
    "dial_code": "+964",
    "value": "IQ"
  },
  {
    "label": "Ireland",
    "dial_code": "+353",
    "value": "IE"
  },
  {
    "label": "Isle of Man",
    "dial_code": "+44",
    "value": "IM"
  },
  {
    "label": "Israel",
    "dial_code": "+972",
    "value": "IL"
  },
  {
    "label": "Italy",
    "dial_code": "+39",
    "value": "IT"
  },
  {
    "label": "Jamaica",
    "dial_code": "+1876",
    "value": "JM"
  },
  {
    "label": "Japan",
    "dial_code": "+81",
    "value": "JP"
  },
  {
    "label": "Jersey",
    "dial_code": "+44",
    "value": "JE"
  },
  {
    "label": "Jordan",
    "dial_code": "+962",
    "value": "JO"
  },
  {
    "label": "Kazakhstan",
    "dial_code": "+77",
    "value": "KZ"
  },
  {
    "label": "Kenya",
    "dial_code": "+254",
    "value": "KE"
  },
  {
    "label": "Kiribati",
    "dial_code": "+686",
    "value": "KI"
  },
  {
    "label": "Korea, Democratic People's Republic of Korea",
    "dial_code": "+850",
    "value": "KP"
  },
  {
    "label": "Korea, Republic of South Korea",
    "dial_code": "+82",
    "value": "KR"
  },
  {
    "label": "Kuwait",
    "dial_code": "+965",
    "value": "KW"
  },
  {
    "label": "Kyrgyzstan",
    "dial_code": "+996",
    "value": "KG"
  },
  {
    "label": "Laos",
    "dial_code": "+856",
    "value": "LA"
  },
  {
    "label": "Latvia",
    "dial_code": "+371",
    "value": "LV"
  },
  {
    "label": "Lebanon",
    "dial_code": "+961",
    "value": "LB"
  },
  {
    "label": "Lesotho",
    "dial_code": "+266",
    "value": "LS"
  },
  {
    "label": "Liberia",
    "dial_code": "+231",
    "value": "LR"
  },
  {
    "label": "Libyan Arab Jamahiriya",
    "dial_code": "+218",
    "value": "LY"
  },
  {
    "label": "Liechtenstein",
    "dial_code": "+423",
    "value": "LI"
  },
  {
    "label": "Lithuania",
    "dial_code": "+370",
    "value": "LT"
  },
  {
    "label": "Luxembourg",
    "dial_code": "+352",
    "value": "LU"
  },
  {
    "label": "Macao",
    "dial_code": "+853",
    "value": "MO"
  },
  {
    "label": "Macedonia",
    "dial_code": "+389",
    "value": "MK"
  },
  {
    "label": "Madagascar",
    "dial_code": "+261",
    "value": "MG"
  },
  {
    "label": "Malawi",
    "dial_code": "+265",
    "value": "MW"
  },
  {
    "label": "Malaysia",
    "dial_code": "+60",
    "value": "MY"
  },
  {
    "label": "Maldives",
    "dial_code": "+960",
    "value": "MV"
  },
  {
    "label": "Mali",
    "dial_code": "+223",
    "value": "ML"
  },
  {
    "label": "Malta",
    "dial_code": "+356",
    "value": "MT"
  },
  {
    "label": "Marshall Islands",
    "dial_code": "+692",
    "value": "MH"
  },
  {
    "label": "Martinique",
    "dial_code": "+596",
    "value": "MQ"
  },
  {
    "label": "Mauritania",
    "dial_code": "+222",
    "value": "MR"
  },
  {
    "label": "Mauritius",
    "dial_code": "+230",
    "value": "MU"
  },
  {
    "label": "Mayotte",
    "dial_code": "+262",
    "value": "YT"
  },
  {
    "label": "Mexico",
    "dial_code": "+52",
    "value": "MX"
  },
  {
    "label": "Micronesia, Federated States of Micronesia",
    "dial_code": "+691",
    "value": "FM"
  },
  {
    "label": "Moldova",
    "dial_code": "+373",
    "value": "MD"
  },
  {
    "label": "Monaco",
    "dial_code": "+377",
    "value": "MC"
  },
  {
    "label": "Mongolia",
    "dial_code": "+976",
    "value": "MN"
  },
  {
    "label": "Montenegro",
    "dial_code": "+382",
    "value": "ME"
  },
  {
    "label": "Montserrat",
    "dial_code": "+1664",
    "value": "MS"
  },
  {
    "label": "Morocco",
    "dial_code": "+212",
    "value": "MA"
  },
  {
    "label": "Mozambique",
    "dial_code": "+258",
    "value": "MZ"
  },
  {
    "label": "Myanmar",
    "dial_code": "+95",
    "value": "MM"
  },
  {
    "label": "Namibia",
    "dial_code": "+264",
    "value": "NA"
  },
  {
    "label": "Nauru",
    "dial_code": "+674",
    "value": "NR"
  },
  {
    "label": "Nepal",
    "dial_code": "+977",
    "value": "NP"
  },
  {
    "label": "Netherlands",
    "dial_code": "+31",
    "value": "NL"
  },
  {
    "label": "Netherlands Antilles",
    "dial_code": "+599",
    "value": "AN"
  },
  {
    "label": "New Caledonia",
    "dial_code": "+687",
    "value": "NC"
  },
  {
    "label": "New Zealand",
    "dial_code": "+64",
    "value": "NZ"
  },
  {
    "label": "Nicaragua",
    "dial_code": "+505",
    "value": "NI"
  },
  {
    "label": "Niger",
    "dial_code": "+227",
    "value": "NE"
  },
  {
    "label": "Nigeria",
    "dial_code": "+234",
    "value": "NG"
  },
  {
    "label": "Niue",
    "dial_code": "+683",
    "value": "NU"
  },
  {
    "label": "Norfolk Island",
    "dial_code": "+672",
    "value": "NF"
  },
  {
    "label": "Northern Mariana Islands",
    "dial_code": "+1670",
    "value": "MP"
  },
  {
    "label": "Norway",
    "dial_code": "+47",
    "value": "NO"
  },
  {
    "label": "Oman",
    "dial_code": "+968",
    "value": "OM"
  },
  {
    "label": "Pakistan",
    "dial_code": "+92",
    "value": "PK"
  },
  {
    "label": "Palau",
    "dial_code": "+680",
    "value": "PW"
  },
  {
    "label": "Palestinian Territory, Occupied",
    "dial_code": "+970",
    "value": "PS"
  },
  {
    "label": "Panama",
    "dial_code": "+507",
    "value": "PA"
  },
  {
    "label": "Papua New Guinea",
    "dial_code": "+675",
    "value": "PG"
  },
  {
    "label": "Paraguay",
    "dial_code": "+595",
    "value": "PY"
  },
  {
    "label": "Peru",
    "dial_code": "+51",
    "value": "PE"
  },
  {
    "label": "Philippines",
    "dial_code": "+63",
    "value": "PH"
  },
  {
    "label": "Pitcairn",
    "dial_code": "+872",
    "value": "PN"
  },
  {
    "label": "Poland",
    "dial_code": "+48",
    "value": "PL"
  },
  {
    "label": "Portugal",
    "dial_code": "+351",
    "value": "PT"
  },
  {
    "label": "Puerto Rico",
    "dial_code": "+1939",
    "value": "PR"
  },
  {
    "label": "Qatar",
    "dial_code": "+974",
    "value": "QA"
  },
  {
    "label": "Romania",
    "dial_code": "+40",
    "value": "RO"
  },
  {
    "label": "Russia",
    "dial_code": "+7",
    "value": "RU"
  },
  {
    "label": "Rwanda",
    "dial_code": "+250",
    "value": "RW"
  },
  {
    "label": "Reunion",
    "dial_code": "+262",
    "value": "RE"
  },
  {
    "label": "Saint Barthelemy",
    "dial_code": "+590",
    "value": "BL"
  },
  {
    "label": "Saint Helena, Ascension and Tristan Da Cunha",
    "dial_code": "+290",
    "value": "SH"
  },
  {
    "label": "Saint Kitts and Nevis",
    "dial_code": "+1869",
    "value": "KN"
  },
  {
    "label": "Saint Lucia",
    "dial_code": "+1758",
    "value": "LC"
  },
  {
    "label": "Saint Martin",
    "dial_code": "+590",
    "value": "MF"
  },
  {
    "label": "Saint Pierre and Miquelon",
    "dial_code": "+508",
    "value": "PM"
  },
  {
    "label": "Saint Vincent and the Grenadines",
    "dial_code": "+1784",
    "value": "VC"
  },
  {
    "label": "Samoa",
    "dial_code": "+685",
    "value": "WS"
  },
  {
    "label": "San Marino",
    "dial_code": "+378",
    "value": "SM"
  },
  {
    "label": "Sao Tome and Principe",
    "dial_code": "+239",
    "value": "ST"
  },
  {
    "label": "Saudi Arabia",
    "dial_code": "+966",
    "value": "SA"
  },
  {
    "label": "Senegal",
    "dial_code": "+221",
    "value": "SN"
  },
  {
    "label": "Serbia",
    "dial_code": "+381",
    "value": "RS"
  },
  {
    "label": "Seychelles",
    "dial_code": "+248",
    "value": "SC"
  },
  {
    "label": "Sierra Leone",
    "dial_code": "+232",
    "value": "SL"
  },
  {
    "label": "Singapore",
    "dial_code": "+65",
    "value": "SG"
  },
  {
    "label": "Slovakia",
    "dial_code": "+421",
    "value": "SK"
  },
  {
    "label": "Slovenia",
    "dial_code": "+386",
    "value": "SI"
  },
  {
    "label": "Solomon Islands",
    "dial_code": "+677",
    "value": "SB"
  },
  {
    "label": "Somalia",
    "dial_code": "+252",
    "value": "SO"
  },
  {
    "label": "South Africa",
    "dial_code": "+27",
    "value": "ZA"
  },
  {
    "label": "South Sudan",
    "dial_code": "+211",
    "value": "SS"
  },
  {
    "label": "South Georgia and the South Sandwich Islands",
    "dial_code": "+500",
    "value": "GS"
  },
  {
    "label": "Spain",
    "dial_code": "+34",
    "value": "ES"
  },
  {
    "label": "Sri Lanka",
    "dial_code": "+94",
    "value": "LK"
  },
  {
    "label": "Sudan",
    "dial_code": "+249",
    "value": "SD"
  },
  {
    "label": "Surilabel",
    "dial_code": "+597",
    "value": "SR"
  },
  {
    "label": "Svalbard and Jan Mayen",
    "dial_code": "+47",
    "value": "SJ"
  },
  {
    "label": "Swaziland",
    "dial_code": "+268",
    "value": "SZ"
  },
  {
    "label": "Sweden",
    "dial_code": "+46",
    "value": "SE"
  },
  {
    "label": "Switzerland",
    "dial_code": "+41",
    "value": "CH"
  },
  {
    "label": "Syrian Arab Republic",
    "dial_code": "+963",
    "value": "SY"
  },
  {
    "label": "Taiwan",
    "dial_code": "+886",
    "value": "TW"
  },
  {
    "label": "Tajikistan",
    "dial_code": "+992",
    "value": "TJ"
  },
  {
    "label": "Tanzania, United Republic of Tanzania",
    "dial_code": "+255",
    "value": "TZ"
  },
  {
    "label": "Thailand",
    "dial_code": "+66",
    "value": "TH"
  },
  {
    "label": "Timor-Leste",
    "dial_code": "+670",
    "value": "TL"
  },
  {
    "label": "Togo",
    "dial_code": "+228",
    "value": "TG"
  },
  {
    "label": "Tokelau",
    "dial_code": "+690",
    "value": "TK"
  },
  {
    "label": "Tonga",
    "dial_code": "+676",
    "value": "TO"
  },
  {
    "label": "Trinidad and Tobago",
    "dial_code": "+1868",
    "value": "TT"
  },
  {
    "label": "Tunisia",
    "dial_code": "+216",
    "value": "TN"
  },
  {
    "label": "Turkey",
    "dial_code": "+90",
    "value": "TR"
  },
  {
    "label": "Turkmenistan",
    "dial_code": "+993",
    "value": "TM"
  },
  {
    "label": "Turks and Caicos Islands",
    "dial_code": "+1649",
    "value": "TC"
  },
  {
    "label": "Tuvalu",
    "dial_code": "+688",
    "value": "TV"
  },
  {
    "label": "Uganda",
    "dial_code": "+256",
    "value": "UG"
  },
  {
    "label": "Ukraine",
    "dial_code": "+380",
    "value": "UA"
  },
  {
    "label": "United Arab Emirates",
    "dial_code": "+971",
    "value": "AE"
  },
  {
    "label": "United Kingdom",
    "dial_code": "+44",
    "value": "GB"
  },
  {
    "label": "United States",
    "dial_code": "+1",
    "value": "US"
  },
  {
    "label": "Uruguay",
    "dial_code": "+598",
    "value": "UY"
  },
  {
    "label": "Uzbekistan",
    "dial_code": "+998",
    "value": "UZ"
  },
  {
    "label": "Vanuatu",
    "dial_code": "+678",
    "value": "VU"
  },
  {
    "label": "Venezuela, Bolivarian Republic of Venezuela",
    "dial_code": "+58",
    "value": "VE"
  },
  {
    "label": "Vietnam",
    "dial_code": "+84",
    "value": "VN"
  },
  {
    "label": "Virgin Islands, British",
    "dial_code": "+1284",
    "value": "VG"
  },
  {
    "label": "Virgin Islands, U.S.",
    "dial_code": "+1340",
    "value": "VI"
  },
  {
    "label": "Wallis and Futuna",
    "dial_code": "+681",
    "value": "WF"
  },
  {
    "label": "Yemen",
    "dial_code": "+967",
    "value": "YE"
  },
  {
    "label": "Zambia",
    "dial_code": "+260",
    "value": "ZM"
  },
  {
    "label": "Zimbabwe",
    "dial_code": "+263",
    "value": "ZW"
  }
];


export { country_items, industry_items, description_items };