import React from 'react';

const BetaUtils = () => {
  return <section className='beta--utils'>
    <svg width="235" height="188" viewBox="0 0 235 188" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M63.0037 54.6597L125.001 168.001L51 107L55.5 176L12.5 115" stroke="#FFDC61" strokeWidth="23.1115" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.0901 68.4708L30.9931 170.868C29.4812 173.729 32.1677 176.958 35.2491 176.008L152.359 139.858C154.532 139.174 155.256 136.44 153.681 134.792C146.878 133.465 126.885 128.679 110.189 110.587C94.5059 93.6155 90.8459 74.7959 89.834 67.7992C89.7458 67.6946 88.7546 66.7129 85.0864 68.4476L85.0901 68.4708Z" fill="#EAF0FF" stroke="#231F20" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M89.834 67.8008C97.9243 73.5583 106.584 80.4846 115.388 88.7435C132.411 104.737 144.793 121.005 153.685 134.816" stroke="#231F20" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M132.382 118.683C138.93 112.248 154.057 99.1464 177.656 92.9774C203.307 86.2627 224.498 91.5443 233.236 94.2473" stroke="#231F20" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M77.315 63.7821C72.1428 62.8339 68.6689 59.5893 68.5728 56.3458C68.5108 54.0484 70.1051 51.6974 72.1649 50.4605C77.7134 47.1136 84.7223 52.9508 87.9929 49.639C88.6874 48.932 89.381 47.6305 89.2294 46.2515C89.0929 44.9652 88.2289 44.0344 87.1293 43.1179C83.6308 40.1627 81.7861 40.8176 78.6782 38.5129C77.9205 37.9455 73.686 34.7048 74.2397 31.2137C74.4629 29.7979 75.4056 28.8605 75.7779 28.5149C80.7402 23.4548 91.3523 29.8039 94.5494 25.7428C95.9906 23.916 95.2178 20.9009 94.3573 19.2559C92.3646 15.4626 87.6951 15.123 87.3594 15.1059" stroke="#231F20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M212.577 145.685C209.88 150.189 205.631 152.303 202.536 151.257C200.347 150.516 198.733 148.184 198.285 145.854C197.112 139.478 205.04 134.962 203.05 130.74C202.621 129.857 201.654 128.729 200.312 128.375C199.071 128.052 197.887 128.529 196.645 129.23C192.654 131.468 192.613 133.426 189.387 135.541C188.589 136.05 184.074 138.873 181.01 137.133C179.778 136.428 179.224 135.209 179.006 134.744C176.015 128.306 185.689 120.605 183 116.187C181.802 114.216 178.7 113.861 176.857 114.087C172.592 114.633 170.638 118.874 170.52 119.179" stroke="#231F20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M137.9 82.0349C139.91 81.71 141.276 79.8171 140.951 77.807C140.626 75.7969 138.733 74.4307 136.723 74.7556C134.713 75.0804 133.347 76.9733 133.672 78.9834C133.997 80.9935 135.89 82.3597 137.9 82.0349Z" fill="#0043FF" stroke="#231F20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M157.332 85.5544C159.342 85.2296 160.708 83.3367 160.383 81.3265C160.058 79.3164 158.165 77.9502 156.155 78.2751C154.145 78.5999 152.779 80.4928 153.104 82.5029C153.429 84.5131 155.322 85.8793 157.332 85.5544Z" fill="#0043FF" stroke="#231F20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M157.31 67.1716C159.32 66.8467 160.686 64.9539 160.361 62.9437C160.036 60.9336 158.144 59.5674 156.134 59.8923C154.124 60.2171 152.758 62.11 153.082 64.1201C153.407 66.1303 155.3 67.4964 157.31 67.1716Z" fill="#0043FF" stroke="#231F20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M135.937 50.6182C141.327 49.7471 144.99 44.6713 144.119 39.2811C143.248 33.8909 138.173 30.2274 132.783 31.0985C127.393 31.9696 123.73 37.0454 124.601 42.4356C125.472 47.8259 130.548 51.4893 135.937 50.6182Z" fill="#FFDC61" stroke="#231F20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M106.572 96.4486C108.976 94.2521 113.927 89.1225 116.542 80.8735C119.397 71.8961 117.863 64.3177 117.068 61.1635" stroke="#231F20" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M198.271 114.574C200.281 114.249 201.647 112.356 201.322 110.346C200.997 108.336 199.105 106.97 197.095 107.295C195.085 107.619 193.718 109.512 194.043 111.522C194.368 113.533 196.261 114.899 198.271 114.574Z" fill="#3263F3" stroke="#231F20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M220.955 83.4567C222.965 83.1319 224.331 81.239 224.006 79.2289C223.681 77.2188 221.788 75.8526 219.778 76.1774C217.768 76.5023 216.402 78.3952 216.727 80.4053C217.052 82.4154 218.945 83.7816 220.955 83.4567Z" fill="#3263F3" stroke="#231F20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M101.218 8.86691C103.228 8.54205 104.594 6.64918 104.269 4.63905C103.945 2.62892 102.052 1.26273 100.042 1.58759C98.0318 1.91244 96.6657 3.80531 96.9906 5.81544C97.3154 7.82557 99.2082 9.19176 101.218 8.86691Z" fill="#EC5D6D" stroke="#231F20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M176.555 169.181C181.944 168.31 185.608 163.234 184.736 157.844C183.865 152.453 178.79 148.79 173.4 149.661C168.01 150.532 164.347 155.608 165.218 160.998C166.089 166.388 171.165 170.052 176.555 169.181Z" fill="#EC5D6D" stroke="#231F20" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M88 150.5C45.2838 166.929 35 175 35 175L152 139C152 139 153.547 137.995 153.5 137C153.462 136.196 152.5 135.5 152.5 135.5C152.5 135.5 94.5 148 88 150.5Z" fill="#D7E2FF" />
    </svg>
  </section>
}

export default BetaUtils