import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import ViewSetting from "./ViewSetting";
import { FiSettings } from 'react-icons/fi';
import { GlobalContext } from '../../Context';



const NavOperation = ({ actionTabs }) => {
  const { pathname } = useLocation();
  const { user, open, setOpen, setHandleProfile } = useContext(GlobalContext);
  const { empRole, empName } = (user && user.data) || {};
  const history = useHistory();



  return !(empRole && empRole.includes("requester"))
    ? <>
      {pathname.includes("/billing") ? actionTabs("Billing", "Invoices", "openInvoices")
        : pathname.includes("/paymentscreen") ? actionTabs("Payment", null, null) : <p></p>}
      <div className='rt_nav--tabs'>
        {open ? <ViewSetting /> : null}
        <p onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}><FiSettings />  </p>
        {/* <p><FiBell /></p> */}
        <p onClick={() => setHandleProfile({ open: true, items: user.data })}
          className='user--logo'>
          {(user && user.profile_img)
            ? <img src={user.profile_img} alt="" />
            : <span>{empName && empName.substring(0, 1)}</span>
          }
        </p>
      </div>
    </>
    : <> <p></p> <div className='requester--navtabs'>
      {open ? <ViewSetting /> : null}
      <p className="icon" style={{ color: open ? "#3263F3" : "#000" }}
        onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}><FiSettings />  </p>
      <div className='profile--tabs' onClick={() => history.push("/dashboard/profile")}>
        <p>{empName && empName.split(" ")[0]}</p>
        <p> {(user && user.profile_img)
          ? <img src={user.profile_img} alt="" />
          : <span>{empName && empName.substring(0, 1)}</span>
        }</p>
      </div>
    </div>
    </>
}

export default NavOperation;