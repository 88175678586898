import * as msal from "@azure/msal-browser";
import axios from "axios";
import { toast } from "react-toastify";

const msalConfig = {
  auth: {
    clientId: "beaff415-0244-4a3a-9465-5ed1472df184",
  },
};
const msalInstance = new msal.PublicClientApplication(msalConfig);

const errorHandleMicrosoft = ({ errorCode }) => {
  switch (errorCode) {
    case "user_cancelled":
      return "User Cancelled the flow";
    case "access_denied":
      return "User Denied the flow";
    default:
      return "Failed to Authenticate!";
  }
};

const errorHandleGoogle = (errorType) => {
  console.log(errorType);
  switch (errorType) {
    case "popup_closed":
      return "User Cancelled the flow";
    case "access_denied":
      return "User Denied the flow";
    default:
      return "Failed to Authenticate!";
  }
};

const handle_google_token = async (tokenResponse) => {
  try {
    const res = await axios.get(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      }
    );
    return res;
  } catch (error) {
    toast.error(errorHandleGoogle("error"));
  }
};

export {
  msalInstance,
  errorHandleMicrosoft,
  handle_google_token,
  errorHandleGoogle,
};
