import React, { useContext } from "react";
import LoginSvg from "./../../Component/Authenticate/LoginSvg";
import { useGoogleLogin } from "@react-oauth/google";
import {
  GoogleLogo,
  MicrosoftLogo,
} from "./../../Component/Authenticate/GoogleLogo";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { GlobalContext } from "../../Context";
import { sendRequest } from "../../API";
import { toast } from "react-toastify";
import Loader from "../../Component/Spinners/Loader";

import {
  errorHandleGoogle,
  errorHandleMicrosoft,
  handle_google_token,
  msalInstance,
} from "./authCred";
import { CustomToasterAuth } from "../../Component/CustomToaster";
import "../../Component/Style/auth.css";

const SignIn = () => {
  const { setCookie, setCustomToast, customToast } = useContext(GlobalContext);
  const [text, setTxt] = useState("Sign In with Google");
  const [textMicrosoft, setTxtMicrosoft] = useState("Sign in with Microsoft");
  const history = useHistory();

  const signIn = (data) => {
    var { email, given_name, picture } = data;
    var getDomain = email.split("@").pop().split(".");
    const personalDomain = [
      "gmail",
      "yahoo",
      "hotmail",
      "outlook",
      "mailinator",
    ];
    const getPlDomain = personalDomain.find((items) => items === getDomain[0]);
    if (getPlDomain)
      return setCustomToast({
        content: `We noticed you have used a personal email ID. 
      Please enter a valid work email ID to get started.`,
        open: true,
        error: true,
      });

    setTxt("Processing...");
    sendRequest("POST", "auth", {
      company: getDomain[0],
      userEmailID: email,
      given_name,
    })
      .then((res) => {
        const { message, token, result } = res.data || {};

        if (message === "new user found!") {
          const totalCount = result.companyData.actualEmployeeCount;
          const companyID = result.companyData.companyID;
          return history.push({
            pathname: "/userworkspace",
            state: {
              getDomain,
              given_name,
              picture,
              email,
              totalCount,
              companyID,
            },
          });
        }
        if (result && result.isActive !== "true") {
          setTxt("Sign In With Google");
          return toast.info("Account Inactive!");
        }
        if (message && message.includes("user")) {
          setCookie("u_detials", token, { path: "/" });
          setCookie("g_token", picture, { path: "/" });
          return (window.location.href = "/dashboard");
        }
        return history.push({
          pathname: "/createworkspace",
          state: { getDomain, given_name, email, picture },
        });
      })
      .catch((e) => {
        setTxt("Sign In With Google");
        if (e.response && e.response.status === 401)
          return toast.error("account deleted!");
        if (e.response && e.response.status === 403)
          return toast.error("Subscription expired!, contact admin");
        toast.error("Something went wrong!");
      });
  };

  const authMicrosoftHandler = async () => {
    try {
      setTxtMicrosoft("Processing...");
      var loginRequest = {
        scopes: ["user.read", "mail.send"],
      };
      let data = await msalInstance.loginPopup(loginRequest);
      let { username, name } = (data && data.account) || {};
      var getDomain = username && username.split("@").pop().split(".");
      const personalDomain = [
        "gmail",
        "yahoo",
        "hotmail",
        "outlook",
        "mailinator",
      ];
      const getPlDomain = personalDomain.find(
        (items) => items === getDomain[0]
      );
      if (getPlDomain) {
        setTxtMicrosoft("Sign In With Microsoft");
        return setCustomToast({
          content: `We noticed you have used a personal email ID. 
        Please enter a valid work email ID to get started.`,
          open: true,
          error: true,
        });
      }

      sendRequest("POST", "auth", {
        company: getDomain[0],
        userEmailID: username,
        givenName: name,
      })
        .then((res) => {
          const { message, token, result } = res.data || {};

          if (message === "new user found!") {
            const totalCount = result.companyData.actualEmployeeCount;
            const companyID = result.companyData.companyID;
            return history.push({
              pathname: "/userworkspace",
              state: {
                getDomain,
                givenName: name,
                email: username,
                totalCount,
                companyID,
                tokenId: data.idToken,
              },
            });
          }

          if (result && result.isActive !== "true") {
            setTxtMicrosoft("Sign In With Microsoft");
            return toast.info("Account Inactive!");
          }
          if (message && message.includes("user")) {
            setCookie("g_token", data.idToken, { path: "/" });
            setCookie("u_detials", token, { path: "/" });
            return (window.location.href = "/dashboard");
          }
          return history.push({
            pathname: "/createworkspace",
            state: {
              getDomain,
              givenName: name,
              email: username,
              tokenId: data.idToken,
            },
          });
        })
        .catch((e) => {
          setTxtMicrosoft("Sign In With Microsoft");
          if (e.response && e.response.status === 401)
            return toast.error("account deleted!");
          if (e.response && e.response.status === 403)
            return toast.error("Subscription expired!, contact admin");
          toast.error("Something went wrong!");
        });
    } catch (err) {
      setTxtMicrosoft("Sign In with Microsoft");
      toast.error(errorHandleMicrosoft(err));
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const result = await handle_google_token(tokenResponse);
      signIn(result.data);
    },
    onNonOAuthError: (error) => toast.error(errorHandleGoogle(error.type)),
    onError: (error) => toast.error(errorHandleGoogle(error)),
  });

  return (
    <main className="container" style={{ marginBottom: "5%" }}>
      <section className="login">
        {customToast.open ? <CustomToasterAuth /> : null}
        <article className="login--svg">
          <LoginSvg />
        </article>
        <article className="login--text">
          <h1 className="animate__animated animate__fadeInUp">Welcome Back</h1>
          <p className="animate__animated animate__fadeInUp">
            Nice to see you again, please log in to continue.
          </p>
        </article>

        <div className="social--providers">
          <article className="google--login animate__animated animate__zoomIn">
            <button
              disabled={
                text.startsWith("P") || textMicrosoft.startsWith("P")
                  ? true
                  : false
              }
              onClick={() => login()}
              className="btn login--btn"
            >
              {" "}
              <GoogleLogo /> &nbsp;
              <span>
                {text}{" "}
                {text.startsWith("P") ? (
                  <Loader borderColor="#3263F3" borderTopColor="whitesmoke" />
                ) : null}
              </span>
            </button>
          </article>

          <article className="google--login microsoft--login animate__animated animate__zoomIn">
            <button
              disabled={
                text.startsWith("P") || textMicrosoft.startsWith("P")
                  ? true
                  : false
              }
              className="btn login--btn"
              onClick={() => {
                sessionStorage.clear();
                authMicrosoftHandler();
              }}
            >
              {" "}
              <MicrosoftLogo /> &nbsp;
              <span>
                {textMicrosoft}{" "}
                {textMicrosoft.startsWith("P") ? (
                  <Loader borderColor="#3263F3" borderTopColor="whitesmoke" />
                ) : null}
              </span>
            </button>
          </article>
        </div>

        <div className="animate__animated animate__fadeInUp">
          <Link to="/register" className="signup--link">
            Don't have an account? <span>Sign up</span>
          </Link>
        </div>
      </section>
    </main>
  );
};

export default SignIn;
