import React, { Fragment } from "react";
import { IoIosAddCircleOutline, IoIosCloseCircleOutline } from "react-icons/io";
import { MultiSelect } from "react-multi-select-component";

const ShowItem = (props) => {
    const {
        category,
        idx,
        error,
        handleInputChange,
        className,
        handleCancel,
        handleAdd,
        dropDownOptions,
        handleAddCategoryAdmin,
    } = props;

    return (
        <>
        <form className={`category-form ${className}`}>
            <input
            type="text"
            name="categoryName"
            placeholder="Category Name*"
            className={
                error.includes(`categoryName${idx}`) ? "error" : "inputCategory"
            }
            value={category.CategoryName}
            onChange={(event) => {
                handleInputChange(event, idx);
            }}
            />

            <section id="defaultItems--dropdown" className="input--multi2">
            <MultiSelect
                onChange={(dropDown) => {
                handleAddCategoryAdmin(dropDown, idx);
                }}
                value={category.selectedAdmins}
                options={dropDownOptions}
            />
            </section>

            {category.CategoryItems.map((item, idxs) => {
            return (
                <div key={idxs} className="inputCategoryGroup">
                <input
                    value={item.itemName}
                    name="itemName"
                    placeholder="Add item*"
                    className={
                    error.includes(`itemName${idx}${idxs}`)
                        ? "error"
                        : "inputCategory"
                    }
                    onChange={(event) => handleInputChange(event, idx, idxs)}
                />
                {category.CategoryItems.length === 1 ? (
                    <IoIosAddCircleOutline
                    onClick={() => {
                        handleAdd(idx);
                    }}
                    name="add"
                    className="iconCat"
                    />
                ) : category.CategoryItems.length - 1 === idxs ? (
                    <Fragment>
                    <IoIosAddCircleOutline
                        onClick={() => {
                        handleAdd(idx);
                        }}
                        name="add"
                        className="iconCat"
                    />
                    <IoIosCloseCircleOutline
                        onClick={() => {
                        handleCancel(idxs, idx);
                        }}
                        name="close"
                        className="iconCat"
                    />
                    </Fragment>
                ) : (
                    <IoIosCloseCircleOutline
                    onClick={() => {
                        handleCancel(idxs, idx);
                    }}
                    name="close"
                    className="iconCat"
                    />
                )}
                </div>
            );
            })}
        </form>
        </>
    );
};

export default ShowItem;
