import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../Context';

const SuccessIcon = () => {
  return <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_365_28058)">
      <circle cx="15.5" cy="15.5" r="14.8542" stroke="#0043FF" strokeWidth="1.29167" />
    </g>
    <path d="M22.7319 11.2958C22.639 11.2021 22.5284 11.1277 22.4065 11.0769C22.2846 11.0261 22.1539 11 22.0219 11C21.8899 11 21.7592 11.0261 21.6373 11.0769C21.5155 11.1277 21.4049 11.2021 21.3119 11.2958L13.8619 18.7558L10.7319 15.6158C10.6354 15.5226 10.5215 15.4492 10.3966 15.4C10.2717 15.3508 10.1384 15.3267 10.0042 15.329C9.87006 15.3314 9.73765 15.3601 9.61458 15.4136C9.4915 15.4671 9.38016 15.5443 9.28692 15.6408C9.19368 15.7373 9.12037 15.8513 9.07117 15.9761C9.02196 16.101 8.99783 16.2343 9.00015 16.3685C9.00247 16.5027 9.0312 16.6351 9.0847 16.7581C9.13819 16.8812 9.2154 16.9926 9.31192 17.0858L13.1519 20.9258C13.2449 21.0195 13.3555 21.0939 13.4773 21.1447C13.5992 21.1955 13.7299 21.2216 13.8619 21.2216C13.9939 21.2216 14.1246 21.1955 14.2465 21.1447C14.3684 21.0939 14.479 21.0195 14.5719 20.9258L22.7319 12.7658C22.8334 12.6722 22.9144 12.5585 22.9698 12.432C23.0252 12.3055 23.0539 12.1689 23.0539 12.0308C23.0539 11.8927 23.0252 11.7561 22.9698 11.6296C22.9144 11.5031 22.8334 11.3894 22.7319 11.2958Z" fill="#3263F3" />
    <defs>
      <filter id="filter0_i_365_28058" x="0" y="0" width="31" height="36.1667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="5.16667" />
        <feGaussianBlur stdDeviation="6.45833" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.238281 0 0 0 0 0.421998 0 0 0 0 0.9375 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_365_28058" />
      </filter>
    </defs>
  </svg>
};

const ErrorIcon = () => {
  return <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_105_13077)">
      <circle cx="15.5" cy="15.5" r="14.8542" stroke="#DA2C2C" strokeWidth="1.29167" />
    </g>
    <path d="M16.204 10.12V15.268L15.988 18.256H14.908L14.692 15.268V10.12H16.204ZM15.448 19.84C15.796 19.84 16.078 19.948 16.294 20.164C16.51 20.38 16.618 20.662 16.618 21.01C16.618 21.358 16.51 21.64 16.294 21.856C16.078 22.072 15.796 22.18 15.448 22.18C15.1 22.18 14.818 22.072 14.602 21.856C14.386 21.64 14.278 21.358 14.278 21.01C14.278 20.662 14.386 20.38 14.602 20.164C14.818 19.948 15.1 19.84 15.448 19.84Z" fill="#DA2C2C" />
    <defs>
      <filter id="filter0_i_105_13077" x="0" y="0" width="31" height="36.1667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="5.16667" />
        <feGaussianBlur stdDeviation="6.45833" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.238281 0 0 0 0 0.421998 0 0 0 0 0.9375 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_105_13077" />
      </filter>
    </defs>
  </svg>
}

const CustomToasterAuth = () => {
  const { customToast, setCustomToast } = useContext(GlobalContext);
  return <div className='custom--toaster--login animate__animated 
  animate__fadeInRight animate__faster'>
    <div>
      <p>{customToast.error ? <ErrorIcon /> : <SuccessIcon />}</p>
      <p>{customToast.content}</p>
    </div>
    <p className='dismiss' onClick={() => setCustomToast({ ...customToast, open: false })}>Dismiss</p>
  </div>
}

const CustomToaster = () => {
  const { customToast, setCustomToast } = useContext(GlobalContext);
  const [wait, setWait] = useState(false);


  const handleClose = () => {
    setTimeout(() => {
      setWait(true);
    }, 2000);
  };
  useEffect(() => {
    if (customToast.open) {
      handleClose();
    }
  }, [customToast]);



  return <div className={`custom--toaster animate__animated animate__faster animate__fadeInRight
    ${wait && "animate__fadeOutRight"}`}>
    <p>{customToast.error ? <ErrorIcon /> : <SuccessIcon />} &nbsp; {customToast.content}</p>
    <p onClick={() => setCustomToast({ ...customToast, open: false })}>Dismiss</p>
  </div>
}

export { CustomToaster, SuccessIcon, ErrorIcon, CustomToasterAuth };
