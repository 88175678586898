import React from 'react'
import { LegalUtils } from './LegalUtils';

const PrivacyPolicy = () => {
  document.title = "REQO | Privacy Policy";
  document.body.style.overflowY = "auto";
  

  return <main>
    <article className='privacy' id='terms'>
      <section>
        <div className='privacy--logo'>
          <LegalUtils />
        </div>
        <div>
          <h1>Privacy Policy</h1>
          <p>Welcome to our privacy policy. We respect your privacy and are committed to protecting your
            personal data. This privacy policy lets you know how we look after your personal data when you
            visit our website (regardless of where you visit it from)
            or access our services, and tells you about your privacy rights and how the law protects you.</p>
        </div>
      </section>
    </article>

    <article className='privacy--content'>
      <p className='numbers'> 1. Important information and who we are</p>

      <p>Purpose of this privacy policy</p>

      <p>This privacy policy aims to give you information on how Reqo collects and  processes your
        personal data through your use of this website and/or application integration, including any
        data you may provide when accessing or using our  services. Our website and services are not intended
        for children and we do not knowingly  collect data relating to children. It is important that you
        read this privacy policy together with any other privacy  policy or fair processing policy we may provide
        on specific occasions when we  are collecting or processing personal data about you so that you are fully
        aware  of how and why we are using your data. This privacy policy supplements other  notices and privacy
        policies and is not intended to override them.</p>
      <p>Controller</p>

      <p>AntStack Technologies Private Limited, trading as Reqo, is the data controller  and is responsible
        for your personal data(collectively referred to as &ldquo;Reqo&rdquo;,  &quot;we&quot;, &quot;us&quot; or
        &quot;our&quot; in this privacy policy). We have appointed a data privacy manager who is responsible
        for overseeing  questions in relation to this privacy policy. If you have any questions about this  privacy
        policy, including any requests to exercise your legal rights, please  contact data privacy manager using the
        details set out below. Full name of legal entity: AntStack Technologies Private Limited, trading as  Reqo. <br />
        Email address: connect@antstack.io <br /> Postal address: Data Privacy Manager, Reqo, #620, 03rd Floor, 02nd Block,
        01st  Main, Dr.Rajkumar Road, Rajajinagar, Bengaluru - 560010, Karnataka, INDIA. You have the right to make
        a complaint at any time to <br />  The Ministry of Electronics and Information Technology (MeitY) , the Indian  regulator
        for data protection issues <a rel="noopener noreferrer" target="_blank" href="https://www.meity.gov.in">
          (https://www.meity.gov.in) </a> We would,  however, appreciate the chance to deal with your
        concerns before you approach  the MeitY so please contact us in the first instance.</p>

      <p>Changes to the privacy policy and your duty to inform us of changes</p>

      <p>We keep our privacy policy under regular review. It is important that the personal data we hold about
        you is accurate and current.  Please keep us informed if your personal data changes during your
        relationship  with us.</p>

      <p>Third-party links</p>

      <p>This website may include links to third-party websites, plug-ins and applications.  Clicking on those
        links or enabling those connections may allow third parties to  collect or share data about you. We do not
        control these third-party websites and are not responsible for their privacy statements. When you
        leave our website, we encourage you to read the privacy policy of every website you visit.</p>

      <p className='numbers'>2. The data we collect about you</p>

      <p>Personal data, or personal information, means any information about an  individual from which
        that person can be identified. It does not include data  where the identity has been removed (anonymous data).</p>

      <p>We may collect, use, store and transfer different kinds of personal data about  you which we
        have grouped together as follows:</p>

      <p><b>- Identity and Contact Data</b> includes name, username (including Email  username) or similar identifier
        and email address. <br /> <b>- Technical Data</b> includes internet protocol (IP) address, your login data,  browser
        type and version, time zone setting and location, browser plug-in types  and versions, operating system
        and platform, and other technology on the  devices you use to access this website or our services. <br />
        <b>- Usage Data</b> includes information about how you use our website and services. <br />
        <b>- Marketing and Communications Data</b> includes your preferences in  receiving marketing
        from us and your communication preferences.</p>

      <p>We also collect, use and share <b>Aggregated Data</b> such as statistical or  demographic data for any
        purpose. Aggregated Data could be derived from your  personal data but is not considered personal
        data in law as this data will not  directly or indirectly reveal your identity. For example,
        we may aggregate your  Usage Data to calculate the percentage of users accessing a specific website
        feature. However, if we combine or connect Aggregated Data with your personal  data so that
        it can directly or indirectly identify you, we treat the combined data  as personal data which
        will be used in accordance with this privacy policy.</p>

      <p>We do not collect any <b>Special Categories of Personal Data</b> about you (this  includes details
        about your race or ethnicity, religious or philosophical beliefs,  sex life, sexual orientation,
        political opinions, trade union membership,  information about your health, and genetic and biometric
        data). Nor do we  collect any information about criminal convictions and offences.</p>

      <p><b>If you fail to provide personal data</b></p>

      <p>Where we need to collect personal data by law, or under the terms of a contract  we have with you,
        and you fail to provide that data when requested, we may not  be able to perform the contract we
        have or are trying to enter into with you (for  example, to provide you with goods or services).
        In this case, we may have to  cancel a product or service you have with us but we will notify you
        if this is the  case at the time.</p>

      <p className='numbers'>3. How is your personal data collected?</p>

      <p>We use different methods to collect data from and about you including through:</p>

      <p><b>Direct interactions.</b> You may give us, or we may automatically receive, your  Identity and Contact
        Data when you use our services.</p>

      <p><b>Automated technologies or interactions.</b> As you interact with our website  and services, we will
        automatically collect Technical Data about your equipment,  browsing actions and patterns.
        We collect this personal data by using cookies  and other similar technologies.  Third parties
        or publicly available sources. We may automatically receive  your Identity and Contact Data when
        the company you work for integrates their  application with our services.</p>

      <p className='numbers'>4. How we use your personal data</p>

      <p>We will only use your personal data when the law allows us to. Most commonly,  we will use
        your personal data in the following circumstances: - Where we need to perform a contract we are
        about to enter into or have  entered into with you. - Where it is necessary for our legitimate
        interests (or those of a third party) and  your interests and fundamental rights do not override
        those interests. - Where we need to comply with a legal obligation. Generally, we do not rely on
        consent as a legal basis for processing your  personal data although, when required by law,
        we will get your consent before  sending direct marketing communications to you via email or
        text message. You  have the right to withdraw consent to marketing at any time by contacting
        us. Purposes for which we will use your personal data We have set out below, in a table format,
        a description of all the ways we plan to use your personal data, and which of the legal bases we
        rely on to do so. We  have also identified what our legitimate interests are where appropriate.
        Note that we may process your personal data for more than one lawful ground  depending on the
        specific purpose for which we are using your data. Please  contact us if you need details about
        the specific legal ground we are relying on to process your personal data where more than one ground
        has been set out in the  table below.</p>
    </article>
  </main>
}

export default PrivacyPolicy;