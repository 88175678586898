import React, { useContext, useEffect, useState } from 'react'
import moment from "moment";
import Paginate from '../../../Component/Paginate';
import { SkeletonLoader } from '../../../Component/Spinners/Skeleton';
import { GlobalContext } from '../../../Context';
import { Accepted, PendingAction, Rejected, UtilsEmpty } from '../../../Component/DashboardUtils';
import { FeatureSubContext } from '../FeatureContext';
import DeleteRequestModal from '../../../Component/Modals/DeleteModals/DeleteRequestModal';
import { FilterAction, MyRequestOperation } from './RequestUtils';
import { useHistory } from 'react-router-dom';



const MyRequest = () => {
  document.title = "REQO | My Requests";
  const { openViewItemsModal, user, setModalUtils, modalUtils, handleProfile } = useContext(GlobalContext);
  const { getMyRequests, myRequest, setRequestView } = useContext(FeatureSubContext);
  let [currentItems, setCurrentItems] = useState([{}]);
  const [handleView, setHandleView] = useState({ viewModal: false, deleteModal: false });
  const [filterData, setFilterData] = useState([]);
  const history = useHistory();
  localStorage.setItem("myTotalRequest", myRequest.length);


  useEffect(() => {
    getMyRequests();
  }, []);
  useEffect(() => {
    if (myRequest.length <= 1) setCurrentItems(myRequest);
  }, [myRequest]);


  return <main>
    {handleView.deleteModal ? <DeleteRequestModal setHandleView={setHandleView} /> : null}
    <section className={`view--items ${(openViewItemsModal || handleView.deleteModal) ? "opacity--reduce" : null}`}>

      <FilterAction setFilterData={setFilterData}
        manageRequest={myRequest}
        setModalUtils={setModalUtils}
        modalUtils={modalUtils}
        user={user}
      />
      {myRequest && myRequest.length >= 1 ? <>
        <article className='request--viewtable'>
          <table className='table'>
            <tbody className='viewtable--body'>
              {currentItems.map((items, i) => {
                return <React.Fragment key={i}>
                  <tr className='request--data' onClick={() => {
                    setRequestView(items);
                    if (!handleView.viewModal) history.push('/dashboard/viewmyrequest')
                  }}>
                    <td> {items.ticketNo ? <> &nbsp;&nbsp;
                      {items.isDefaultRequest ? <span className='default--request'>Default</span> : null}
                      #{items.ticketNo} </> : <SkeletonLoader />}
                    </td>
                    <td style={{ width: "280px" }}>{items.requestTitle
                      ? items.requestTitle.length >= 29 ? items.requestTitle.substring(0, 30) + "..." : items.requestTitle
                      : <SkeletonLoader />}
                    </td>
                    <td>
                      {items.status ? items.status && items.status.includes("approved")
                        ? <span className='take--action accepted--action'><Accepted /> Accepted</span>
                        : items.status && items.status.includes("rejected")
                          ? <span className='take--action rejected--action'><Rejected /> Rejected</span>
                          : <span className='pending--action'><PendingAction /> Pending</span>
                        : <SkeletonLoader />}
                    </td>
                    <td className='request--user'>{items.requester ? <> {items.requester.split(" ")[0]}
                      <p className='user--icon'>  {(user && user.profile_img)
                        ? <img src={user.profile_img} alt="" />
                        : <span>{items.requester.substring(0, 1)}</span>
                      }</p></>
                      : <SkeletonLoader />}
                    </td>
                    {!handleProfile.open ? <>
                      <td>{items.requestCreatedTime ? moment(items.requestCreatedTime).format("MMM DD, YYYY") : <SkeletonLoader />}</td>
                      <td className='request--options'>{items.ticketNo
                        ? <MyRequestOperation items={items} setHandleView={setHandleView} handleView={handleView} /> : <SkeletonLoader />}</td>
                    </> : null}
                  </tr>
                  <tr className='request--line'><td></td></tr>
                </React.Fragment>
              })}
            </tbody>
          </table>
        </article>
        <article className='view--pagination'>
          {
            myRequest.length > 1 ? <Paginate data={filterData.length > 0 ? filterData : myRequest}
              setCurrentItems={setCurrentItems} displayItem={10} /> : null
          }
        </article>
      </> : <section className='no--request'>
        <UtilsEmpty />
        <h1>You have not created any requests</h1>
        <p>All requests raised by you will be available in this tab.</p>
        <div className='rt_nav--tabs'>
          <button onClick={() => setModalUtils({ ...modalUtils, createRequestModal: true })}
            className='create--requests btn'>
            Create Request
          </button>
        </div>
      </section>
      }
    </section>
  </main>
}

export default MyRequest;