import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoIosClose } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendRequest } from '../../API';
import { Inputs } from '../../Component/Authenticate/Inputs';
import { SkeletonLoader, SkeletonRound } from '../../Component/Spinners/Skeleton';
import { GlobalContext } from '../../Context';
import { FeatureSubContext } from './FeatureContext';


const Profile = ({ image }) => {
  document.title = "REQO | Profile";
  const { closeModal, setCloseModal } = useContext(GlobalContext);
  const { getCurrentEmployee } = useContext(FeatureSubContext);
  const [editClick, setEditClick] = useState(0);
  const [user, setUser] = useState({});
  const { empName, empRole, empID, emailID, doj, empAddress, responderID, empContact, companyID } = user || {};
  const { handleSubmit, formState: { errors }, register } = useForm();
  const [btnTxt, setBtnTxt] = useState("Save Detials");
  const history = useHistory();



  const handleClose = () => {
    setTimeout(() => {
      setCloseModal({ createRequest: false });
      history.push("/dashboard");
    }, 400);
    setCloseModal({ createRequest: true });
  };


  const getEmployee = () => {
    getCurrentEmployee().then((res) => {
      const { result: { Item } } = res.data;
      if (Item) setUser(Item);
    }).catch((err) => {
      const { status, data } = err.response || {};
      if (status === 403) return toast.error(data.message);
    });
  }
  useEffect(() => {
    getEmployee();
  }, []);


  const save_profile = async (data) => {
    let { empName, empAddress, empContact } = data;
    setBtnTxt("Processing...");
    try {
      await sendRequest("PUT", "employee/user/updateUser", {
        ...user, empName, empAddress, empContact,
        companyID: companyID, isDeleted: "false",
      });
      setBtnTxt("Save Detials");
      getEmployee();
      setEditClick(0);
    } catch (error) {
      setBtnTxt("Save Detials");
      const { status, data } = error.response || {};
      if (status === 403) return toast.error(data.message);
      toast.error("Something went wrong!");
    }
  };

  // temp function...
  const temp = () => { };


  return <main className='user--profile'>
    <section className={`employee--viewdata animate__animated animate__bounceIn  
    ${closeModal.createRequest ? "animate__bounceOut" : null}`}>
      <article>
        <h1><b>My Profile</b> <span onClick={() => handleClose()}><IoIosClose /></span> </h1>
      </article>

      <form className='employee--content' onSubmit={handleSubmit(editClick > 1 ? save_profile : temp)}>
        <div className='first--content'>
          {empName ? <article className='profile--img'>
            {(image)
              ? <img src={image} alt="" />
              : <p>{empName && empName.substring(0, 1)}</p>
            }
          </article> : <SkeletonRound size={"200px"} />}
          {empName ? <button className='btn edit--detials' onClick={() => setEditClick(editClick + 1)}>
            {editClick >= 1 ? btnTxt : "Edit Detials"}
          </button> : null}
        </div>
        <div className='second--content'>
          {
            editClick > 0 ? <section className='input--section' >
              <Inputs value={empName} register={register} errors={errors} name={"empName"}
                placeholder="Employee Name*"
                require={true} />
              <Inputs value={empAddress} register={register} errors={errors} name={"empAddress"}
                placeholder="Employee Address*"
                require={true} />
              <Inputs value={empContact} register={register} errors={errors} name={"empContact"}
                placeholder="Employee Contact"
                require={true} />
            </section>
              : <>
                <h1>{empName ? empName.split(" ")[0] : <p style={{ width: 150 }}><SkeletonLoader /></p>}
                  {empRole ? <span>&bull; {empRole} </span> : null}
                </h1>
                {empName ? <p className='empId'>{empID} - FTE</p> : <p><SkeletonLoader /></p>}
                <article className='email'>
                  {emailID ? <p>Email <br /> <span>{emailID}</span> </p> : <p> <SkeletonLoader /></p>}
                </article>
                <article className='address'>
                  {(empAddress || empName)
                    ? <p>Address <br /> <span>{empAddress || "--"}</span> </p>
                    : <p> <SkeletonLoader /></p>}
                </article>
                <article className='doj'>
                  {(empName || doj)
                    ? <p>Joined <br /> <span>{doj || "--"}</span> </p>
                    : <p> <SkeletonLoader /></p>}
                </article>
                <article>
                  {(empName || empContact) ? <p>Contact <br /> <span>{empContact || "--"}</span> </p> : null}
                </article>
                <article>
                  {responderID ? <p>ApproverID <br /> <span>{responderID}</span> </p> : null}
                </article>
              </>
          }
        </div>
      </form>
    </section>
  </main>
}

export default Profile;