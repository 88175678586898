import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { GrDown } from 'react-icons/gr';
import { BsArrowUp } from 'react-icons/bs';
import { Inputs, SelectBox } from "../../Component/Authenticate/Inputs";
import { GlobalContext } from '../../Context';
import { FeatureSubContext } from '../../Pages/Manage/FeatureContext';
import { getRequest, sendRequest } from '../../API';
import Loader from '../Spinners/Loader';
import { toast } from 'react-toastify';
import EmployeeDefaultRequest from './EmployeeDefaultRequest';
import moment from 'moment';


const AddEmployeeModal = () => {
  const { setModalUtils, closeModal, setCloseModal, user, viewItem,
    checkForSubscription, setViewItem, getManagerPendingRequest } = useContext(GlobalContext);
  const { empName, empID, emailID, empAddress, doj, empRole, responderID, empContact, roleChange } = viewItem || {};
  const { getEmployees } = useContext(FeatureSubContext);
  const [btnTxt, setBtnTxt] = useState(empName ? "Update Employee" : "Add Employee");
  const [responder, setResponder] = useState([]);
  const [resChange, setResChange] = useState(empName ? responderID ? responderID : "no record" : null);
  const [click, setClick] = useState(0);
  const [selected, setSelected] = useState([]);


  const { handleSubmit, formState: { errors }, register, watch, setValue } = useForm();
  const getEmpRole = watch("empRole");
  const getDojValue = watch("doj");
  const { companyID } = (user && user.data) || {};
  const { companyName } = (user && user.company) || {};


  const handleClose = ({ openSent }) => {
    setTimeout(() => {
      setViewItem(empName ? "updated" : "added");
      setModalUtils({ addEmployee: false });
      setCloseModal({ createRequest: false });
      getEmployees();
      setModalUtils({ addEmployee: false, openSentRequestModal: openSent });
    }, 400);
    setCloseModal({ createRequest: true });
  }

  // getting employee based on empRole......
  const get_employee_based_role = (role, companyID) => {
    setResponder([]);
    setResChange(null);
    setValue("responderID", "");

    var role_update = (role === 'requester') ? "responder" : (role === "admin") ? "super_admin" : "admin";
    getRequest(`employee/user/groupUsers?empRole=${role_update}&companyID=${companyID}`)
      .then((res) => {
        var { result } = res.data || {};
        result = result.filter((items) => items.isDeleted !== "true" && items.isActive !== "false");
        result = result.filter((items) => items.emailID !== emailID);
        if (result && result.length > 0) setResponder(result);
        else {
          setResponder([]);
          setValue("responderID", "");
          setResChange("no record");
        };
      }).catch((error) => {
        setValue("responderID", "");
        setResChange("no record");
        const { status, data } = error.response || {};
        if (status === 403) toast.error(data.message);
        setResponder([]);
      })
  };
  useEffect(() => {
    if ((getEmpRole && click > 0)) get_employee_based_role(getEmpRole, companyID);
    if (click <= 0 && !emailID) {
      setValue("empRole", "requester");
      setClick(click + 1);
    }
    if (getEmpRole === "") {
      setResponder([]);
      setValue("responderID", "");
    }
  }, [getEmpRole, click]);



  // create or update employee data......
  const submit_data = async (data) => {
    try {
      data.emailID = data.emailID.toLowerCase();
      var getDomain = data.emailID.split("@").pop().split(".");
      if (!(getDomain[0] && getDomain[0].includes(companyName))) return toast.info("Email must be valid domain!");

      // fetch company Details...
      setBtnTxt("Processing...");
      const url = `company/getCompany?companyName=${getDomain[0]}&companyID=${companyID}`;
      const { data: { result } } = await getRequest(url);
      if (!result) throw Error("Company Not Found!");
      const { actualEmployeeCount, subscriptionStatus, subscriptionID, companyOrigin } = result;
      const companyData = {
        actualEmployeeCount,
        subscriptionStatus,
        subscriptionID,
        companyName: getDomain[0],
        companyID,
        companyOrigin
      };
      await checkForSubscription();
      data.roleChange = roleChange;
      data.empRole = empRole && empRole.includes("super") ? "super_admin" : data.empRole;


      // Pending request validation only if user role changes while editing......
      if (empRole && (empRole !== data.empRole)) {
        const { data: { result } } = await getManagerPendingRequest(data.emailID);
        let { Items } = result;
        data.roleChange = true;
        let checkPreviousRole = (empRole === "responder") ? "YES" : null;
        let checkCurrentRole = (data.empRole === "admin") ? "ALLOW" : null;
        if ((Items && Items.length > 0) && !((checkPreviousRole === "YES") && (checkCurrentRole === "ALLOW")))
          throw Error("pending requests");
      }

      // creating user...
      data.responderID = data.responderID === 'no record' ? "" : data.responderID;
      data.categoryData = selected;
      var path = empName ? "employee/user/updateUser" : "employee/user/createUser";
      var method = empName ? "PUT" : "POST";
      await sendRequest(method, path, {
        ...data, empContact, companyID: companyID, isDeleted: "false",
        companyData, creatorRole: user.data.empRole
      });
      handleClose({ openSent: true });

    } catch (error) {
      setBtnTxt(empName ? "Update Employee" : "Add Employee");
      const { status, data } = error.response || {};
      if (status === 403) return toast.error(data.message);
      if (error && error.message === "pending requests") return toast.info("Failed! Employee have pending request");
      if (error.response && error.response.status === 302) return toast.info("An account already exists!");
      if (error === 403) return toast.info("Subscription ended!");
      toast.error("Something went wrong!");
      setBtnTxt(empName ? "Update Employee" : "Add Employee");
    }
  };


  const empRoles = [
    { value: "admin" },
    { value: "responder" },
    { value: "requester" }
  ];


  return <section className={`create--request add--employee animate__animated animate__zoomIn
  ${closeModal.createRequest ? "animate__zoomOut" : null}`} style={{ height: empName ? "600px" : "650px" }}>
    <form onSubmit={handleSubmit(submit_data)}>
      <article>
        <h1>Employee Details  <span onClick={() => handleClose({ openSent: false })}><BsArrowUp /></span> </h1>
      </article>

      <section className='input--section'>
        <Inputs value={empName} register={register} errors={errors} name={"empName"} placeholder="Employee Name*"
          require={true} />
        <Inputs value={empID} register={register} errors={errors} name={"empID"} placeholder="Employee ID*"
          require={true} />
      </section>

      <section className='input--section modified--input--dob'>
        <Inputs readOnly={emailID ? true : false} className={"text-lowercase"} value={emailID} register={register} errors={errors} name={"emailID"} placeholder="Employee Email*"
          require={true} regex={/\S+@\S+\.\S+/} />
        <div className='doj--section'>
          <Inputs value={doj} register={register} errors={errors} name={"doj"} placeholder="Joining date*"
            require={true} date={true} type="date" maxDate={moment().format("YYYY-MM-DD")} />
          {(!getDojValue && !doj) ? <span>Joining Date*</span> : null}
        </div>
      </section>
      {
        !(empRole && empRole.includes("super_admin")) ? <article className='inputs'>
          <div>
            <GrDown className='icon--1' />
            <SelectBox name="empRole" register={register} errors={errors}
              message="Employee Role*"
              data={empRoles}
              dataItemDisplay={"value"}
              dataItemValue={"value"}
              className="select--error"
              value={empRole}
              onClick={() => setClick(click + 1)}
              valid={true}
            />
          </div>
          <div>
            <GrDown className='icon--2' />
            <SelectBox name="responderID" register={register} errors={errors}
              message="Employee Approver"
              data={responder}
              dataItemDisplay={"empName"}
              dataItemValue={"emailID"}
              selectClass={click > 0 ? null : "notallowed"}
              value={resChange}
              valid={false}
            />
            {(responder.length <= 0 && !resChange && getEmpRole) ? <Loader size={25} /> : null}
          </div>
        </article> : null
      }
      <section className='input--section'>
        <Inputs value={empAddress} register={register} errors={errors} name={"empAddress"} placeholder="Employee Address*"
          require={true} />
      </section>
      <p className='message'>All fields marked (*) are mandatory</p>


      {!emailID && btnTxt.startsWith("A")
        ? <EmployeeDefaultRequest
          empName={empName}
          setSelected={setSelected}
          selected={selected}
          user={user} /> : null}

      <div className='create--btn'>
        <button style={{ marginBottom: 30 }} disabled={btnTxt.includes("...") ? true : false}>
          {btnTxt} {btnTxt.startsWith("P") ? <> &nbsp; <Loader /> </> : null}
        </button>
      </div>

    </form>
  </section>
}

export default AddEmployeeModal;