import React, { useEffect, useState } from 'react';
import { GrDown } from 'react-icons/gr';
import Select from 'react-select';
import { IoIosArrowDown, IoIosArrowForward, IoMdClose } from 'react-icons/io';
import { useContext } from 'react';
import { GlobalContext } from '../../../Context';
import { getRequest } from '../../../API';
import { empRoles, styles_fn } from './OnBoardUtils';
import moment from 'moment';



const AddMemberComp = ({ i, setValue, watch, remove, errors, checkError, register, companyID, control, check, Controller }) => {
  const [autoHide, setAutoHide] = useState({ trigger: true })
  const { closeModal, setCloseModal, setCustomToast } = useContext(GlobalContext);
  const [responder, setResponder] = useState([]);
  const watchValues = watch();


  // getting Responder's/Approver based on empRole......
  const getEmpRole = watch(`employees.${i}.empRole`) || "admin";
  const get_employee_based_role = (role, companyID) => {
    role = role.value || getEmpRole;
    setResponder([]);
    setValue(`employees.${i}.responderID`, "");

    var role_update = (role === 'requester') ? "responder" : (role === "admin") ? "super_admin" : "admin";
    getRequest(`employee/user/groupUsers?empRole=${role_update}&companyID=${companyID}`)
      .then((res) => {
        var { result } = res.data || {};
        result = result.filter((items) => items.isDeleted !== "true" && items.isActive !== "false");
        if (result && result.length > 0) setResponder(result);
        else {
          setResponder([]);
          setValue(`employees.${i}.responderID`, "");
        };
      }).catch((error) => {
        setValue(`employees.${i}.responderID`, "");
        const { status, data } = error.response || {};
        if (status === 403) return setCustomToast({ content: `Logout, ${data.message}`, open: true, error: true })
        setResponder([]);
      })
  };
  useEffect(() => {
    if ((getEmpRole)) get_employee_based_role(getEmpRole, companyID);
    if (getEmpRole === "") {
      setResponder([]);
      setValue(`employees.${i}.responderID`, "");
    }
  }, [getEmpRole]);


  const handleClose = () => {
    setTimeout(() => {
      setCloseModal({ sentRequest: false });
      setAutoHide({ ...autoHide, trigger: false })
    }, (180));
    setCloseModal({ sentRequest: true });
  };


  let modify_responders = responder && responder.map((items) => {
    return { label: items.empName, value: items.emailID }
  });
  return <>
    {
      check ? <article className='members--tab'>
        <p>Member {i + 1}
          <span> {i !== 0 ? <IoMdClose onClick={() => {
            remove(i)
          }} /> : null}
            {(autoHide.trigger)
              ? <IoIosArrowDown onClick={() => {
                handleClose();
              }} />
              : <IoIosArrowForward onClick={() => setAutoHide({ ...autoHide, trigger: true })} />}
          </span>
        </p>
      </article> : null
    }

    {autoHide.trigger ? <div className={`animate__animated animate__fadeIn ${closeModal.sentRequest
      ? "animate__faster animate__fadeOut" : null}`}>
      <section className='input--section'>
        <div>
          {watchValues.employees[i].empName ? <label>Employee Name*</label> : null}
          <input className={`form-control ${checkError && errors.employees[i].empName ? 'input--error' : null}`}
            {...register(`employees.${i}.empName`, { required: true })} placeholder="Employee Name*" />
        </div>
        <div>
          {watchValues.employees[i].empID ? <label>Employee ID*</label> : null}
          <input className={`form-control ${checkError && errors.employees[i].empID ? 'input--error' : null}`}
            {...register(`employees.${i}.empID`, { required: true })} placeholder="Employee ID*" />
        </div>
      </section>

      <section className='input--section'>
        <div>
          {watchValues.employees[i].emailID ? <label>Employee Email*</label> : null}
          <input className={`form-control email ${checkError && errors.employees[i].emailID ? 'input--error' : null}`}
            {...register(`employees.${i}.emailID`, { required: true, pattern: /\S+@\S+\.\S+/ })}
            placeholder="Employee Email*" />
        </div>
        <div>
          {watchValues.employees[i].doj ? <label>Joining date</label> : null}
          <input className="form-control"
            {...register(`employees.${i}.doj`)} placeholder="Joining date" type={"date"} max={moment().format("YYYY-MM-DD")} />
        </div>
      </section>

      <section className='input--section'>
        <div>
          {watchValues.employees[i].empRole ? <label>Employee Role*</label> : null}
          <GrDown className='icon--1' />
          <label htmlFor="">Employee Role*</label>
          <Controller
            control={control}
            name={`employees.${i}.empRole`}
            defaultValue={{ value: "admin", label: "Admin" }}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => <Select
              value={value}
              styles={styles_fn("empRole", errors)}
              className="custom"
              classNamePrefix="select"
              placeholder="Employee Role*"
              options={empRoles}
              onChange={(e) => {
                onChange(e)
              }}
            />}
          />
        </div>
        <div className='input--modified'>
          {watchValues.employees[i].responderID ? <label>Employee Approver</label> : null}
          <GrDown className='icon--1' />
          <Controller
            control={control}
            name={`employees.${i}.responderID`}
            render={({ field: { onChange, value } }) => <Select
              value={value}
              styles={styles_fn("responderID", errors)}
              className="custom"
              classNamePrefix="select"
              placeholder="Employee Approver"
              options={modify_responders}
              onChange={(e) => onChange(e)}
            />}
          />
        </div>
      </section>

      <section className='input--section input--modified'>
        <div>
          {watchValues.employees[i].empAddress ? <label>Employee Address</label> : null}
          <input className="form-control"
            {...register(`employees.${i}.empAddress`)} placeholder="Employee Address" />
        </div>
      </section>
    </div>
      : null
    }
  </>
}

export default AddMemberComp;