import React from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, Route } from 'react-router-dom';



const ProtectedRoute = ({ component: Comp, ...rest }) => {
  const [cookie] = useCookies();
  return <Route {...rest}
    render={() => (cookie.u_detials && cookie.u_detials !== undefined) ? <Comp /> : <Redirect to='/login' />
    }
  />
}


const SignInRoute = ({ component: Comp, ...rest }) => {
  const [cookie] = useCookies();
  return <Route {...rest}
    render={() => (cookie.u_detials && cookie.u_detials !== undefined) ? <Redirect to='/dashboard' /> : <Comp />
    }
  />
}

// const AdminProtectedRoute = ({ component: Comp, ...rest }) => {
//   const [cookie] = useCookies();
//   const { g_token, u_details: { role } } = cookie;
//   return <Route {...rest}
//     render={() => (g_token !== undefined && !(role && role.includes("admin"))) ? <Redirect to='/dashboard' /> : <Comp />
//     }
//   />
// }

export { ProtectedRoute, SignInRoute };