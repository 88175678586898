import React, { Fragment, useContext, useState } from 'react'
import { useForm } from 'react-hook-form';
import { CheckBox, delete_emp_main } from './CheckBox';
import PendingHeader from './PendingHeader';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Loader from '../../Spinners/Loader';
import { OnBoardingSkipUtils } from '../../OnBoardingUtils';
import { GlobalContext } from '../../../Context';
import { sendRequest } from '../../../API';

const ReassignReportees = () => {
  const history = useHistory();
  const { reportees, name, role, mail } = history.location.state || {};
  const { user } = useContext(GlobalContext);
  const { watch, control } = useForm();
  const [admins, setAdmins] = useState([]);
  const [selectAll, setSelectAll] = useState({ data: [] });
  const [check, setCheck] = useState(false);
  const [loader, setLoader] = useState(false);
  const { companyID } = (user && user.data) || {}


  const reassign_to = watch("reassign");
  const handle_reportees = async () => {
    try {
      setLoader(true);
      let data1 = reportees;
      let data_selected = selectAll.data;

      const { value } = reassign_to;
      let reportees_email = data_selected.map((item) => item.emailID);
      let data_to_send = {
        companyID: companyID,
        reporteeList: reportees_email,
        newResponderID: value.newApproverID
      };
      await sendRequest("POST", "employee/user/reassign", { ...data_to_send });
      const update_items = data1.filter(item => !data_selected.includes(item));
      history.replace("/dashboard/reassign_reportees", { reportees: update_items, name, role, mail });
      setLoader(false);
    } catch (error) {
      setLoader(false)
      console.log(error.response);
    }
  }


  const delete_again = async () => {
    try {
      setLoader(true);
      await delete_emp_main({ companyID, emailID: mail, empRole: role, currentUser: user.data, empName: name, history });
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };


  return <main className='pending--view'>
    {reportees && reportees.length > 0 ? <Fragment>
      <PendingHeader
        setCheck={setCheck}
        setSelectAll={setSelectAll}
        check={check}
        admins={admins}
        setAdmins={setAdmins}
        control={control}
      />
      <article>
        <div className='text-center request--viewtable mt-4'>
          <table className='table'>
            <tbody className='viewtable--body'>
              {reportees && reportees.map((item, i) => {
                const { empName, emailID, empID, empRole, doj } = item || {};
                return <Fragment key={i}>
                  <tr className='request--data shadow-sm'>
                    <td>
                      {!check ? <input type="checkbox" onChange={(e) => {
                        if (e.target.checked) setSelectAll({ data: [...selectAll.data, item] });
                        else {
                          let select_all = selectAll.data.filter((items) => items.ticketNo !== item.ticketNo);
                          setSelectAll({ data: select_all });
                        }
                      }} /> : <CheckBox />}
                      {empName}
                    </td>
                    <td className='text-lowercase'> {emailID}</td>
                    <td> {empRole}</td>
                    <td>{empID}</td>
                    <td className='request--user' style={{ width: 130 }}>
                      {empName.split(" ")[0]}
                      <p className={`${(i % 2) ? "user--icon1" : (i % 3) ? "user--icon2" : "user--icon3"}`}>
                        {empName.substring(0, 1)}
                      </p>
                    </td>
                    <td style={{ width: 200 }}>{moment(doj).format("MMM DD, YYYY")}</td>
                  </tr>
                  <tr className='request--line'><td style={{ padding: 4 }}></td></tr>
                </Fragment>
              })}
            </tbody>
          </table>
        </div>
      </article>
      <div className='submit--button'>
        <button className='btn' style={{ width: 160 }}
          onClick={() => handle_reportees()}
          disabled={loader || !reassign_to || !(selectAll.data.length > 0)}>
          {loader ? <> Please Wait... <Loader size={18} /> </> : "Reassign Reportees"}
        </button>
      </div>
    </Fragment>
      : <section className='onboarding no_pending_requests'>
        <div className='onboard--modal'>
          <section className='step1--add_members skip_flow'>
            <OnBoardingSkipUtils />
            <small className='mt-4'>There are no <b>Reportees</b> under <b>{name}'s</b></small>
            <article className='step1--buttons'>
              <button onClick={() => history.goBack()}>Go Back</button>
              &nbsp;
              <button className='btn'
                onClick={() => delete_again()}
                disabled={loader}>
                {loader ? <> Please Wait... </> : "Continue Delete"}
              </button>
            </article>
          </section>
        </div>
      </section>
    }
  </main>
}

export default ReassignReportees