import moment from 'moment';
import React, { useContext } from 'react'
import { IoCloseOutline } from 'react-icons/io5';
import { GlobalContext } from '../Context';

const Banner = ({ bannerOpen, setBannerOpen }) => {
  const { companyData, globalError } = useContext(GlobalContext);
  const { subscriptionEnded, subscriptionType, subscriptionStatus, cancelAtperiodEnd } = companyData;
  const { status, data } = globalError || {};


  if (bannerOpen && status === 403) {
    return <article className='trail--banner' style={{ background: "#EC5D6D", letterSpacing: "0px" }}>
      <b>{data.message}</b> Click to logout to continue.
      <IoCloseOutline onClick={() => { setBannerOpen(false) }} />
    </article>
  }

  // if (!pathname.includes("billing")) return null;


  let check = moment(subscriptionEnded).diff(moment(), 'day')
  if (bannerOpen && (subscriptionType === "PAID") && (check > 0 && check <= 3)) {
    return <article className='trail--banner' style={{ background: "#EC5D6D" }}>
      Reminder: Your Subscription ends on: {moment(subscriptionEnded).format("DD MMMM YYYY")}.
      <IoCloseOutline onClick={() => { setBannerOpen(false) }} />
    </article>
  }

  if (bannerOpen && (subscriptionStatus === "CANCELED" || cancelAtperiodEnd === true)) {
    return <article className='trail--banner' style={{ background: "#EC5D6D" }}>
      You have cancelled your subcsription. Click to renew your subscription.
      <IoCloseOutline onClick={() => { setBannerOpen(false) }} />
    </article>
  }


  return bannerOpen && (subscriptionType === "FREE_TRAIL") ? <article className='trail--banner'>
    Your Free trial ends on: {moment(subscriptionEnded).format("DD MMMM YYYY")}.&nbsp;
    <span>
      Click here to begin subscription
    </span> <IoCloseOutline onClick={() => { setBannerOpen(false) }} />
  </article> : null
}

export default Banner;