import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './Checkout';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const stripePromise = loadStripe("pk_test_51LYPjrSAtWblKhEaGehR7pbsyYv9v5LJsvl2vazMYPRwrXOBU29vTvA0l2aJw82TNDeagcDaCXz0fY0TW3XIGbUp00KIyjUEMA");

const MakePayment = () => {
  const history = useHistory();
  const { location: { state } } = history;
  const [clientSecret] = useState(state && state.clientSecret);

  useEffect(() => {
    if (!clientSecret) return history.push("/dashboard/billing");
  }, [clientSecret]);


  const options = {
    clientSecret: clientSecret,
    appearance: {/*...*/ },
  };

  return <section>
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  </section>
};
export default MakePayment;