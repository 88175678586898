import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify';
import { sendRequest } from '../../../API';
import { RejectRequestsSign } from '../../../Component/DashboardUtils';
import Loader from '../../../Component/Spinners/Loader';
import { GlobalContext } from '../../../Context';



const CancelModal = () => {
  const { closeModal, setCloseModal, setModalUtils, user } = useContext(GlobalContext);
  const { companyName, companyID } = (user && user.company) || {};
  const [loader, setLoader] = useState(false);

  const handleClose = (value) => {
    setTimeout(() => {
      setCloseModal({ sentRequest: false });
      setModalUtils({ cancelBilling: false });
      if (value) window.location.reload();
    }, (450));
    setCloseModal({ sentRequest: true });
  };


  const cancelSub = async () => {
    setLoader(true);
    try {
      await sendRequest("POST", "stripe/cancelsubscription", { companyName, companyID });
      setTimeout(() => {
        setLoader(false);
        handleClose(true);
      }, 1500);
    } catch (error) {
      setLoader(false);
      const { status, data } = error.response || {};
      if (status === 403) return toast.error(data.message);
    }
  }

  return <main className={`sent--request animate__animated animate__bounceIn 
  ${closeModal.sentRequest ? "animate__bounceOut" : null}`}>
    <section onClick={() => handleClose()} className='goback'>Go Back</section>
    <section>
      <article style={{ marginTop: "10%" }}> <RejectRequestsSign /></article>
      <p>
        Are you sure you want to cancel your subscription?
      </p>
      <div className='reject--request--btn'>
        <button disabled={loader} className='btn cancel--plans' onClick={() => cancelSub()}>
          {loader ? <Loader /> : "Yes, Cancel"}
        </button>
      </div>
    </section>
  </main>
}

export default CancelModal;