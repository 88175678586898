import React, { useState } from 'react'
import { CategoryUtils, BlueAutoCorrect, DefaultItemSelect, DefaultItemUnSelect } from "./CategoryUtils";
import { FiTrash2 } from "react-icons/fi";
import { HiOutlinePencil } from "react-icons/hi";
import Loader from "../../../Component/Spinners/Loader";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { SkeletonLoader } from "../../../Component/Spinners/Skeleton";
import { toast } from "react-toastify";

const CategoryTable = (props) => {
    document.body.style.overflowY = "auto";

    const {
      Category,
      handleDelete,
      handleToggle,
      handleView,
      handleSetCount,
      updateCategoryItem,
      visibleDefault,
      visibleAuto,
      visibleDelete,
      setVisibleAuto,
      setVisibleDelete,
      setVisibleToggle,
      visibleToggle
    } = props;

    const { CategoryItems, selectedAdmins, createdDate } = Category;
    const [visible, setVisible] = useState(false);

    const handleItemDelete = (category, currentItem) => {
      const {
        id,
        CompanyID,
        checked,
        CategoryName,
        CategoryItems,
        defaultItems,
        categoryAdmin,
        selectedAdmins
      } = category;
      const findIndex = CategoryItems.findIndex(
        (item) => item.itemName === currentItem.itemName
      );
      const itemIndex = defaultItems.findIndex((item) => item.itemName === currentItem.itemName)
      CategoryItems.splice(findIndex, 1);
      if (itemIndex !== -1) defaultItems.splice(itemIndex, 1)

      const resultData = {
        id,
        CompanyID,
        checked,
        CategoryName,
        CategoryItems,
        defaultItems,
        categoryAdmin,
        selectedAdmins
      };
      updateCategoryItem(resultData, true);
    };

    const handleAutoClick = (Category, item, idx) => {
      const ids = `${Category.id}_${idx}`
      setVisibleAuto((prev) => [...prev, ids])
      const { itemName } = item;
      const {
        defaultItems,
        id,
        CompanyID,
        checked,
        CategoryName,
        CategoryItems,
        isDefaultItemSelect,
        categoryAdmin,
        selectedAdmins
      } = Category;

      if (isDefaultItemSelect === 'true') {
        const findItem = defaultItems.find((item) => item.itemName === itemName);
        const findIndex = defaultItems.findIndex((item) => item.itemName === itemName);
        let items = [...defaultItems];
        if (findItem !== undefined && findItem.length !== 0) {
          items.splice(findIndex, 1);
        } else {
          items.push(item);
        }

        const resultData = {
          id,
          CompanyID,
          checked,
          CategoryName,
          CategoryItems,
          defaultItems: items,
          categoryAdmin,
          selectedAdmins
        };
        updateCategoryItem(resultData,'',ids);

      } else {
        toast.error('Only Category with default item turned on can set default item')
        const filterId = visibleAuto.filter(id => id !== ids)
        setVisibleAuto(filterId)
      }
    };

    const handleAutoCorrect = (item, Category, idx) => {
      const { itemName } = item;
      const { defaultItems } = Category;
      const findItem = defaultItems && defaultItems.find((item) => item.itemName === itemName);

      const showAuto = () =>{
        if(visibleAuto.includes(`${Category.id}_${idx}`)){
          return <Loader/>
        } else if(findItem !== undefined && findItem.length !== 0){
          return <BlueAutoCorrect />
        } else {
          return <CategoryUtils />
        }
      }

      return(
        <div
            className="hover-edit"
            onClick={() => { handleAutoClick(Category, item, idx) }}
          >
            { showAuto()}
            <span className="hidden-tooltip-auto">{findItem !== undefined && findItem.length !== 0?'Auto Request on':'Auto Request'}</span>
          </div>
      )
    };

    const handleDefaultSelect = (Category) => {
      const { isDefaultItemSelect } = Category

      const showDefault = () =>{
        if(visibleDefault.includes(Category.id)){
          return <Loader/>
        } else if( isDefaultItemSelect === "true"){
          return <DefaultItemSelect />
        } else {
          return <DefaultItemUnSelect />
        }
      }
      return(
        <div
            className="hover-edit"
            onClick={() => { handleSetCount(Category) }}
          >
            { showDefault()}
            <span className="hidden-tooltip-auto-default">{ isDefaultItemSelect === 'true'? 'Default Item': 'Set Default Item'}</span>
          </div>
      )
    }
    return (
      <>
        <tr
          key={Category.id}
          id={`${visible ? "visible" : null}`}
          className="rows-design"
        >
          <td
            onClick={() => setVisible(!visible)}
          >
            {visible ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </td>
          <td>{Category.CategoryName || <SkeletonLoader />}</td>
          <td>
            {Category.isDeleted ? (
              `${Category.CategoryItems.length} items`
            ) : (
              <SkeletonLoader />
            )}
          </td>
          {
            visibleToggle.includes(Category.id)? (
                <div className="loader-toggle">
                   <Loader/>
                </div>): (
                <td className="td-align">
                <div className="form-check form-switch" id="toggle">
                  {Category.checked ? (
                    <div className="hover-edit">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked={Category.checked === "true" ? true : false}
                            readOnly={true}
                            onClick={() => {
                                setVisibleToggle(prev => [...prev, Category.id])
                                handleToggle(
                                    Category.id,
                                    Category.CompanyID,
                                    Category.checked,
                                    Category.CategoryName,
                                    Category.CategoryItems,
                                    Category.defaultItems,
                                    Category.categoryAdmin,
                                    Category.selectedAdmins
                                );
                            }}
                        />
                        <span className="tooltip-toggle">
                            Display Category On Dashboard
                        </span>
                    </div>
                  ) : (
                    <SkeletonLoader />
                  )}
                </div>
              </td>
            )
          }
          <td>
            {
              Category.isDeleted? (
                <div className="category-admin">
                    {
                        selectedAdmins && selectedAdmins.length !== 0?(
                            <>
                              <p className="icon">{selectedAdmins&&selectedAdmins[0]['label'][0].toUpperCase()}</p>
                              <p className="name">{selectedAdmins &&selectedAdmins[0]['label'].split(' ')[0]}</p>
                            </>

                        ):(
                            <p className='nil-category'>--NIL--</p>
                        )
                    }

                </div>
              ):(
                <SkeletonLoader />
              )
            }

          </td>
          <td>
            {
              Category.isDeleted ==="false"?(
                <div>{createdDate && createdDate.slice(4,15).split(' ').join(',').replace(',', ' ')}</div>
              ) : (
                <SkeletonLoader />
              )
            }
          </td>
          <td>
            {Category.isDeleted ? (
              <div className="action">
                <div className="hover-edit">
                  <HiOutlinePencil
                    onClick={() => {
                      handleView(Category);
                    }}
                  />
                  <span className="tooltip-edit">Edit</span>
                </div>
                <div className="hover-edit">
                    {
                        visibleDelete.includes(Category.id)?(
                            <Loader/>
                        ):(
                            <FiTrash2
                                onClick={() => {
                                    setVisibleDelete(prev => [...prev,Category.id])
                                    handleDelete(Category.id, Category.CompanyID);
                                }}
                            />
                        )
                    }

                  <span className="tooltip-delete">Delete</span>
                </div>
                {handleDefaultSelect(Category)}
              </div>
            ) : (
              <SkeletonLoader />
            )}
          </td>
        </tr>
        <tr className='request--line'><td></td></tr>
        {
          visible && Category.CategoryItems.length !== 0 ? (
            <>
              <tr id="bold-header">
                <td></td>
                <td>{Category.CategoryName} View</td>
                <td>Item List</td>
                <td></td>
                <td></td>
                <td></td>
                <td id="action-align-item">Action Items</td>
              </tr>
              {
                CategoryItems.map((item, idx) =>{
                  return(
                    <tr key={`${Category.id}_${idx}`} className= 'nested-row'>
                      <td></td>
                      <td></td>
                      <td>{item.itemName}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                      {Category.isDeleted ? (
                            <div className="action-items">
                              <div className="hover-edit">
                                <HiOutlinePencil
                                  onClick={() => {
                                    handleView(Category);
                                  }}
                                />
                                <span className="hidden-tooltip">Edit</span>
                              </div>
                              <div className="hover-edit">
                                <FiTrash2
                                  onClick={() => {
                                    handleItemDelete(Category, item);
                                  }}
                                />
                                <span className="hidden-tooltip-delete">
                                  Delete
                                </span>
                              </div>
                              {handleAutoCorrect(item, Category, idx)}
                            </div>
                          ) : (
                            <SkeletonLoader />
                          )}
                      </td>
                    </tr>
                  )
                })
              }
            </>
          ) : null
        }
      </>
    );
  };

export default CategoryTable