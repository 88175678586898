import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const SkeletonLoader = () => {
  return <Skeleton count={1} />
}

const SkeletonLoad = () => {
  return <Skeleton
    style={{
      width: "150px",
      borderRadius: "5px"
    }}
  />
}

const SkeletonRound = ({ size }) => {
  return <Skeleton
    style={{
      width: size ? size : "40px",
      height: size ? size : "40px",
      borderRadius: "50%",
    }}
  />
}

export { SkeletonLoader, SkeletonRound, SkeletonLoad };