import React from 'react';
import Group3 from './Group3.png';
import Group4 from './Group4.png';
import Group5 from './Group5.png';


const filterComp = (Group, content, heading, imgStyle, animation, dur1, dur2, className) => {
  return <div>
    <img src={Group} alt="" className={imgStyle} data-aos={animation} data-aos-duration="650" data-aos-easing="ease-in-out" />
    <div className='text'>
      <h6 data-aos="fade-up" data-aos-duration={dur1} data-aos-easing="ease-in-out">{heading}</h6>
      <p className={className} data-aos="zoom-in-up" data-aos-duration={dur2} data-aos-easing="ease-in-out">
        {content}
      </p>
    </div>
  </div>
};


const Feature = () => {
  return <section className='reqo_features'>
    <p>What Reqo does different</p>
    <h1>No more meeting & email tags, just a smarter way to request. </h1>
    <article className='features'>
      {filterComp(Group3,
        `Minimise and Optimise with crisp & standard dashboards and calendars that save your day, time and energy.`,
        "Seamless Management", "", 'fade-up', "800", "1000", "content")
      }
      {filterComp(Group4,
        `Helps you keep track of your organisation's assets present, sent, received and returned. Number & Navigate.`,
        "Hassle-Free Asset Management", "img_modified", 'fade-up', "1000", "1200")
      }
      {filterComp(Group5,
        `A well-defined road map to address all the needs, remove the blockers, take quicker actions and revert at the earliest without any chaos.`,
        "Easy Monitoring", "img_modified", 'fade-up', "1200", "1400")
      }
    </article>
  </section>
}

export default Feature;