import React from 'react'
import FrontImage from '../Component/Landing/frontImage.png';
import Group1 from '../Component/Landing/Group1.png';
import { GroupTwo } from '../Component/Landing/Group2';
import Feature from '../Component/Landing/Feature';
import Banner from '../Component/Landing/Banner';
import { Link } from 'react-router-dom';
import Beta from '../Component/Landing/Beta';


const LandingPage = () => {
  document.body.style.backgroundColor = 'white';
  

  return <main>
    <article className='container'>
      <section className='display_section'>
        <div className='lt_display_section animate__animated animate__fadeIn'>
          <img src={FrontImage} alt="image4" />
        </div>
        <div className='rt_display_section'>
          <article className="animate__animated animate__fadeInUp">
            <h2><b>End-to-end <br /> <span>Request Management</span> </b></h2>
            <p>A collaborative workspace built to manage requests within your organisation,
              carving a hassle-free experience with spot-on dashboards to track.</p>
          </article>
          <Link to="/register">
            <button className='btn get_started_btn animate__animated animate__zoomIn'>
              Get started for free
            </button>
          </Link>
        </div>
      </section>
    </article>
    <Banner />
    <section className='display_about_reqo'>
      <article>
        <img src={Group1} alt="" height="200" width="250" />
        Request and Relax
        <GroupTwo className="side_image" />
        <div>
          <p>Introducing Reqo to your team is similar to having a Pizza Party on a Monday Morning;
            they will love it and thank you for it.</p>
        </div>
      </article>
    </section>
    <Feature />
    <section>
      <Beta />
    </section>
  </main>
}

export default LandingPage;