import React, { Fragment, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import "../../Style/profile_delete.css";
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { sendRequest } from '../../../API';
import { CheckBox, delete_emp_main } from './CheckBox';
import PendingHeader from './PendingHeader';
import { GlobalContext } from '../../../Context';
import { OnBoardingSkipUtils } from '../../OnBoardingUtils';
import Loader from '../../Spinners/Loader';



const PendingView = () => {
  const history = useHistory();
  const { state, name, code, category, role, mail } = history.location.state || {};
  const { user } = useContext(GlobalContext);
  const { watch, control } = useForm();
  const [admins, setAdmins] = useState([]);
  const [selectAll, setSelectAll] = useState({ data: [] });
  const [check, setCheck] = useState(false);
  const [loader, setLoader] = useState(false);
  const { emailID, empName, empRole, companyID } = (user && user.data) || {}


  const reassign_to = watch("reassign");
  const handle_pending_request = async () => {
    try {
      setLoader(true);
      let data1 = state;
      let data_selected = selectAll.data;

      const { value } = reassign_to;
      let data_to_send = {
        updaterEmailID: emailID,
        updaterName: empName,
        updaterRole: empRole,
        newApproverID: value.newApproverID,
        newApproverRole: value.newApproverRole,
        ticketData: data_selected
      }
      await sendRequest("POST", "items/reassign", { ...data_to_send });
      const update_items = data1.filter(item => !data_selected.includes(item));
      history.replace("/dashboard/pending", { state: update_items, name, role, mail });
      setLoader(false);
    } catch (error) {
      setLoader(false)
      console.log(error.response);
    }
  };


  const delete_again = async () => {
    try {
      setLoader(true);
      await delete_emp_main({ companyID, emailID: mail, empRole: role, currentUser: user.data, empName: name, history });
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };


  return <main className='pending--view'>
    {state && state.length > 0 ? <Fragment>
      <PendingHeader
        setCheck={setCheck}
        setSelectAll={setSelectAll}
        check={check}
        admins={admins}
        setAdmins={setAdmins}
        control={control}
      />
      <article>
        <div className='text-center request--viewtable mt-4'>
          <table className='table'>
            <tbody className='viewtable--body'>
              {state && state.map((item, i) => {
                const { ticketNo, requestTitle, requester, requestCreatedTime, request, category } = item || {};
                return <Fragment key={i}>
                  <tr className='request--data shadow-sm'>
                    <td>
                      {!check ? <input type="checkbox" onChange={(e) => {
                        if (e.target.checked) setSelectAll({ data: [...selectAll.data, item] });
                        else {
                          let select_all = selectAll.data.filter((items) => items.ticketNo !== item.ticketNo);
                          setSelectAll({ data: select_all });
                        }
                      }} /> : <CheckBox />}
                      #{ticketNo}
                    </td>
                    <td> {category}</td>
                    <td> {request}</td>
                    <td>
                      {(requestTitle.length >= 29) ? requestTitle.substring(0, 30) + "..." : requestTitle}
                    </td>
                    <td className='request--user'>
                      {requester.split(" ")[0]}
                      <p className={`${(i % 2) ? "user--icon1" : (i % 3) ? "user--icon2" : "user--icon3"}`}>
                        {requester.substring(0, 1)}
                      </p>
                    </td>
                    <td style={{ width: 200 }}>{moment(requestCreatedTime).format("MMM DD, YYYY")}</td>
                  </tr>
                  <tr className='request--line'><td style={{ padding: 4 }}></td></tr>
                </Fragment>
              })}
            </tbody>
          </table>
        </div>
      </article>
      <div className='submit--button'>
        <button className='btn'
          onClick={() => handle_pending_request()}
          disabled={loader || !reassign_to || !(selectAll.data.length > 0)}>
          {loader ? <> Please Wait... <Loader size={18} /> </> : "Reassign Tickets"}
        </button>
      </div>
    </Fragment>
      : <section className='onboarding no_pending_requests'>
        <div className='onboard--modal'>
          {code === 202 ?
            <section className='step1--add_members skip_flow'>
              <OnBoardingSkipUtils />
              <small className='mt-4'>Please assign a new category admin for the category&nbsp;
                <b className='text-secondary'>"{category && category[0].CategoryName}"</b> for <b className='text-capitalize'>{name}'s</b>
              </small>
              <article className='step1--buttons'>
                <button onClick={() => history.goBack()}>Go Back</button>
                &nbsp;
                <button style={{ width: 150 }} onClick={() => history.push("/dashboard/category")}>Continue</button>
              </article>
            </section>
            : <section className='step1--add_members skip_flow'>
              <OnBoardingSkipUtils />
              <small className='mt-4'>There are no pending request for <b>{name}'s</b></small>
              <article className='step1--buttons'>
                <button onClick={() => history.goBack()}>Go Back</button>
                &nbsp;
                <button className='btn' style={{ width: 150 }}
                  onClick={() => delete_again()}
                  disabled={loader}>
                  {loader ? <> Please Wait... </> : "Continue Delete"}
                </button>
              </article>
            </section>}
        </div>
      </section>}
  </main >
}

export default PendingView;