import React, { useContext, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { sendRequest } from "../../../API";
import {
  AcceptedFilter,
  Dots,
  FilterIcon,
  InReviewFilter,
  RejectedFilter,
  TakeAction,
} from "../../../Component/DashboardUtils";
import Loader from "../../../Component/Spinners/Loader";
import { GlobalContext } from "../../../Context";
import { FeatureSubContext } from "../FeatureContext";
import { FiRotateCcw } from "react-icons/fi";

const RequestUtils = ({ items, setHandleView, getManageRequests }) => {
  const { setCustomToast, user, setModalUtils } = useContext(GlobalContext);
  const { setRequestView } = useContext(FeatureSubContext);
  const [openAction, setOpenAction] = useState(false);
  const history = useHistory();
  const { empRole, emailID } = (user && user.data) || {};
  const [loader, setLoader] = useState(false);

  const handleViewAction = (action, view) => {
    setOpenAction(action);
    setHandleView(view);
  };

  const moveToAdmin = async (data) => {
    const { companyID, ticketNo, comments } = data;
    try {
      setLoader(true);
      await sendRequest("POST", "items/update", {
        emailID,
        companyID,
        ticketNo,
        status: "moveToAdmin",
        comments: comments,
        assetId: "Null",
      });
      setLoader(false);
      getManageRequests();
      handleViewAction(false, false);
      setCustomToast({
        content: "Request Move to admin Successfully!",
        open: true,
        error: false,
      });
    } catch (error) {
      setLoader(false);
      const { status, data } = error.response || {};
      if (status === 403) return toast.error(data.message);
      toast.error("Something went wrong!");
    }
  };
  const approvefn = async (data) => {
    const { companyID, ticketNo, comments } = data;
    try {
      setLoader(true);
      await sendRequest("POST", "items/update", {
        emailID,
        companyID,
        ticketNo,
        status: "approve",
        comments: comments,
        assetId: "Null",
      });
      setLoader(false);
      getManageRequests();
      handleViewAction(false, false);
      setCustomToast({
        content: "Request approved Successfully!",
        open: true,
        error: false,
      });
    } catch (error) {
      setLoader(false);
      const { status, data } = error.response || {};
      if (status === 403) return toast.error(data.message);
      toast.error("Something went wrong!");
    }
  };

  return loader ? (
    <Loader />
  ) : (
    <section
      className="open--operation"
      onMouseEnter={() => handleViewAction(true, true)}
      onMouseLeave={() => handleViewAction(false, false)}
    >
      <Dots />
      {openAction ? (
        <article className="request--actions">
          {items.status && items.status.includes("pending") ? (
            <>
              {!empRole.includes("admin") ? (
                <p onClick={() => moveToAdmin(items)}>Move to Admin</p>
              ) : null}
              <p onClick={() => approvefn(items)}>
                {!empRole.includes("admin")
                  ? "Move to next Approver"
                  : "Approve Request"}
              </p>
              <p
                onClick={() => {
                  setModalUtils({ approveRejectModal: true });
                  setRequestView(items);
                }}
              >
                Reject Request
              </p>
            </>
          ) : null}
          <p onClick={() => history.push("/dashboard/viewmyrequest")}>
            View full Request
          </p>
        </article>
      ) : null}
    </section>
  );
};

const MyRequestOperation = ({ setHandleView, handleView, items }) => {
  const { setRequestView } = useContext(FeatureSubContext);
  const [openAction, setOpenAction] = useState(false);
  const history = useHistory();

  return (
    <section
      className="open--operation"
      onMouseEnter={() => {
        setOpenAction(true);
        setHandleView({ ...handleView, viewModal: true });
      }}
      onMouseLeave={() => {
        setOpenAction(false);
        setHandleView({ ...handleView, viewModal: false });
      }}
    >
      <Dots />
      {openAction ? (
        <article className={`request--actions modify--actions`}>
          {!items.status.includes("pending") ? (
            <p
              onClick={() => {
                setRequestView({ ticketNo: items.ticketNo });
                setHandleView({ ...handleView, deleteModal: true });
              }}
            >
              Delete Request
            </p>
          ) : null}
          <p onClick={() => history.push("/dashboard/viewmyrequest")}>
            View full Request
          </p>
        </article>
      ) : null}
    </section>
  );
};

const FilterAction = ({
  setFilterData,
  manageRequest,
  setModalUtils,
  modalUtils,
  user,
}) => {
  const { setOpenMyRequestTab, openMyRequestTab } = useContext(GlobalContext);
  const [openFilter, setOpenFilter] = useState({ open: false, value: "" });
  const getMyTotalRequest = localStorage.getItem("myTotalRequest");
  const getManageTotalRequest = localStorage.getItem("manageTotalRequest");
  const { empRole } = (user && user.data) || {};

  const filterAction = (value) => {
    value === "reset" && window.location.reload();
    setOpenFilter({ ...openFilter, value });
    manageRequest =
      manageRequest &&
      manageRequest.filter((items) => items.status.includes(value));
    setFilterData(manageRequest);
  };
  const filters = (val, text, Icon) => {
    return (
      <article
        className={openFilter.value === val ? "modify--filter" : null}
        onClick={() => filterAction(val)}
      >
        <span>
          <Icon />
        </span>
        <span>{text}</span>
      </article>
    );
  };

  return (
    <article className="request--tabs">
      {!(empRole === "requester") ? (
        <p
          className={`tabs ${!openMyRequestTab ? "modify--tabs" : null}`}
          onClick={() => setOpenMyRequestTab(false)}
        >
          Inbox (
          {(getManageTotalRequest * 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
          })}
          )
        </p>
      ) : null}
      <p
        className={`tabs ${openMyRequestTab ? "modify--tabs" : null}`}
        onClick={() => setOpenMyRequestTab(true)}
      >
        My Requests (
        {(getMyTotalRequest * 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
        })}
        )
      </p>
      <main
        className="tabs"
        onMouseEnter={() => setOpenFilter({ ...openFilter, open: true })}
        onMouseLeave={() => setOpenFilter({ ...openFilter, open: false })}
      >
        {" "}
        <FilterIcon /> Filters
        {openFilter.open ? (
          <section>
            <div className="filters">
              {manageRequest.find((item) => item.status.includes("pending")) &&
                filters(
                  "pending",
                  openMyRequestTab ? "Pending" : "Take Action",
                  TakeAction
                )}
              {manageRequest.find((item) => item.status.includes("approved")) &&
                filters("approved", "Accepted", AcceptedFilter)}
              {manageRequest.find((item) => item.status.includes("rejected")) &&
                filters("rejected", "Closed", RejectedFilter)}
              {manageRequest.find((item) => item.status.includes("Deleted")) &&
                filters("employee Deleted", "Auto Closed", RejectedFilter)}
              {filters("reset", "Reset", InReviewFilter)}
            </div>
          </section>
        ) : null}
      </main>
      <p className={`tabs`} onClick={() => window.location.reload()}>
        <FiRotateCcw /> Reset
      </p>
      {empRole === "requester" ? (
        <article className="open--create_request">
          <p
            onClick={() =>
              setModalUtils({ ...modalUtils, createRequestModal: true })
            }
            className="tabs button"
          >
            <BiPlus /> Create Request
          </p>
        </article>
      ) : null}
    </article>
  );
};
function isPdfFile(imageKey) {
  const fileExtension = imageKey && imageKey.match(/\.[0-9a-z]+$/i)[0];
  return fileExtension === ".pdf";
}
function downloadPDF(url, fileName) {
  fetch(url, { mode: "cors", cache: "no-cache" })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch((error) => console.error("Failed to download:", error));
}

const Currency = [
  {
    CurrencyName: "₹ (INR)",
  },
  {
    CurrencyName: "$ (USD)",
  },
  {
    CurrencyName: "£ (GBP)",
  },
  {
    CurrencyName: "€ (EUR)",
  },
];
function formatCurrency(amount, currency) {
  const CurrencySymbols = {
    "₹ (INR)": "₹",
    "$ (USD)": "$",
    "£ (GBP)": "£",
    "€ (EUR)": "€",
  };
  const symbol = CurrencySymbols[currency];
  return `${symbol} ${amount}`;
}

export {
  RequestUtils,
  FilterAction,
  MyRequestOperation,
  isPdfFile,
  downloadPDF,
  Currency,
  formatCurrency,
};
