import React, { useContext, Fragment, useEffect } from "react";
import { GlobalContext } from "../../Context";
import { BsArrowUp } from "react-icons/bs";
import { useState } from "react";
import Loader from "../Spinners/Loader";
import { sendRequest } from "../../API";
import { FeatureSubContext } from "../../Pages/Manage/FeatureContext";
import { toast } from "react-toastify";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import ShowItem from "./ShowCategoryModal";
import { IoMdClose } from 'react-icons/io';
import { CategoryWarning } from "../../Pages/Manage/Category/CategoryUtils";
import { useHistory } from "react-router-dom";

const ShowCategory = (props) => {
  const {
    category,
    categoryTotal,
    className,
    idx,
    handleInputChange,
    error,
    handleCancel,
    handleAdd,
    dropDownOptions,
    handleAddCategoryAdmin,
    handleDeleteCategory,
  } = props;
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    const tag = document.getElementsByClassName("gray");
    if (tag.length !== 0) {
      tag[0].innerText = "Add Category Admin";
    }
  }, [showAdd, category]);

  return (
    <div>
      {category.length !== 0 ? (
        <>
          <div className="category-collapse">
            <h2>Category {idx + 1}</h2>
            <div className="header-margin">
              <div
                onClick={() => {
                  handleDeleteCategory(idx);
                }}
                className="category-cancel"
              >
                <IoMdClose />
              </div>

              {idx === categoryTotal - 1 ? (
                showAdd === true ? (
                  <div
                    onClick={() => {
                      setShowAdd(!showAdd);
                    }}
                    className="collpase-icon"
                  >
                    <IoIosArrowDown />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setShowAdd(!showAdd);
                    }}
                    className="collpase-icon"
                  >
                    <IoIosArrowUp />
                  </div>
                )
              ) : showAdd ? (
                <div
                  onClick={() => {
                    setShowAdd(!showAdd);
                  }}
                  className="collpase-icon"
                >
                  <IoIosArrowUp />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setShowAdd(!showAdd);
                  }}
                  className="collpase-icon"
                >
                  <IoIosArrowDown />
                </div>
              )}
            </div>
          </div>

          {idx === categoryTotal - 1
            ? !showAdd && (
              <>
                <ShowItem
                  category={category}
                  handleCancel={handleCancel}
                  handleAdd={handleAdd}
                  idx={idx}
                  handleInputChange={handleInputChange}
                  dropDownOptions={dropDownOptions}
                  handleAddCategoryAdmin={handleAddCategoryAdmin}
                  error={error}
                  className={className}
                />
              </>
            )
            : showAdd && (
              <>
                <ShowItem
                  category={category}
                  handleCancel={handleCancel}
                  handleAdd={handleAdd}
                  idx={idx}
                  handleInputChange={handleInputChange}
                  dropDownOptions={dropDownOptions}
                  handleAddCategoryAdmin={handleAddCategoryAdmin}
                  error={error}
                  className={className}
                />
              </>
            )}
        </>
      ) : null}
    </div>
  );
};


const AddCategory = ({ isOnboarding }) => {
  const history = useHistory()
  const {
    setModalUtils,
    user,
    closeModal,
    setCloseModal,
    checkForSubscription,
  } = useContext(GlobalContext);
  const {
    viewCategory,
    getAllAdminAndSuper,
    super_Admins,
    setViewCategory,
    getAllCategoryItem,
    categoryUpdate,
    setCategoryUpdate,
    data,
    setFirstCategory,
  } = useContext(FeatureSubContext);
  const [btnTxt, setBtnTxt] = useState(
    categoryUpdate ? "Update Category" : isOnboarding ? 'Save Category' : "Add Category"
  );

  const [dropDownOptions, setOptions] = useState([]);
  const [error, setError] = useState([]);
  const [activeBtn, setActiveBtn] = useState(false);
  const [noAdmin, setNoAdmin] = useState(false)
  const [category, setCategory] = useState(
    viewCategory !== undefined
      ? viewCategory.CategoryItems.length === 0 ? [viewCategory.CategoryItems.push({ itemName: "" }) && viewCategory] : [viewCategory]
      : [
        {
          CategoryName: "",
          CategoryItems: [{ itemName: "" }],
          selectedAdmins: [],
        },
      ]
  );

  const handleInputChange = (event, index, idx) => {
    const { name, value } = event.target;
    const result = [...category];
    const errorResult = [...error];

    if (categoryUpdate && name === "itemName") {
      const defaultItem = [...result[0].defaultItems];
      let defaulItemId;

      defaultItem.filter((item, id) => {
        if (item.itemName === result[0].CategoryItems[idx].itemName) {
          defaulItemId = id;
          return item;
        } else {
          return false;
        }
      });

      if (defaulItemId !== undefined) {
        defaultItem[defaulItemId].itemName = value;
        result.defaultItems = defaultItem;
        setCategory(result);
      }
    }

    if (name.includes("categoryName")) {
      result[index].CategoryName = value;
      errorResult.splice(`categoryName${index}`);
      setCategory(result);
      setError(errorResult);
    } else if (name === "itemName") {
      result[index].CategoryItems[idx].itemName = value;
      errorResult.splice(`itemName${index}${idx}`);
      setCategory(result);
      setError(errorResult);
    }
  };

  const handleAddCategory = () => {
    const result = [
      ...category,
      {
        CategoryName: "",
        CategoryItems: [{ itemName: "" }],
        selectedAdmins: [],
      },
    ];
    setCategory(result);
  };

  const handleDeleteCategory = (index) => {
    const result = [...category];
    result.splice(index, 1);
    setCategory(result);
  };

  const handleAddCategoryAdmin = (dropDown, index) => {
    const result = [...category];
    result[index].selectedAdmins = dropDown;
    setCategory(result);
  };

  const handleAdd = (idx) => {
    let result = [...category];
    result[idx].CategoryItems = [
      ...result[idx].CategoryItems,
      { itemName: "" },
    ];
    setCategory(result);
  };

  const handleCancel = (index, id) => {
    let result = [...category];
    let categoryItem = [...result[id].CategoryItems];
    categoryItem.splice(index, 1);
    result[id].CategoryItems = categoryItem;
    if (error.length !== 0) setError([])
    setCategory(result);
  };

  const handleClose = ({ openSent }) => {
    setTimeout(() => {
      setModalUtils({ addCategory: false });
      setCloseModal({ createRequest: false });
      setModalUtils({ addCategory: false, openSentRequestModal: openSent });
    }, 400);
    getAllCategoryItem();
    setViewCategory();
    setCloseModal({ createRequest: true });
    setCategoryUpdate(false);
  };

  const handleSubmitData = async () => {
    const categoryResult = [...category];
    const resultError = [...error];
    const resultData = [];
    const admins = [];
    let endpoint, method;

    categoryResult.forEach((category, id) => {
      const { CategoryName, CategoryItems, selectedAdmins } = category;

      if (CategoryName === "" && !error.includes(`categoryName${id}`)) {
        resultError.push(`categoryName${id}`);
      }
      CategoryItems.forEach((item, idx) => {
        if (item.itemName === "" && !error.includes(`itemName${id}${idx}`)) {
          resultError.push(`itemName${id}${idx}`);
        }
      });

      if (selectedAdmins.length !== 0) {
        selectedAdmins.forEach((admin) => {
          if (!admins.includes(admin.value)) {
            admins.push(admin.value);
          }
        });
      }

      if (categoryUpdate) {
        const obj = {
          checked: categoryResult[0].checked,
          CompanyID: user.data.companyID,
          id: categoryResult[0].id,
          CategoryName: categoryResult[0].CategoryName,
          CategoryItems: categoryResult[0].CategoryItems,
          defaultItems: categoryResult[0].defaultItems,
          categoryAdmin: admins,
          selectedAdmins,
        };
        method = "PUT";
        endpoint = "categoryItems/updateCategoryItem";
        resultData.push(obj);
      } else {
        const obj = {
          checked: "true",
          isDeleted: "false",
          defaultItems: [],
          isDefaultItemSelect: "false",
          CategoryItems,
          CompanyID: user.data.companyID,
          categoryAdmin: admins,
          CategoryName,
          selectedAdmins,
        };
        method = "POST";
        endpoint = `categoryItems/createCategoryItem${data.length === 0 ? "?firstTime=first time" : ""
          }`;
        resultData.push(obj);
      }
    });

    try {
      await checkForSubscription();
    } catch (error) {
      setBtnTxt(btnTxt);
      if (error === 403) return toast.info("Subscription ended!");
    }

    if (resultError.length !== 0) {
      setError(resultError);
      return;
    }
    setBtnTxt("Processing...");
    setActiveBtn(!activeBtn);

    sendRequest(method, endpoint, resultData)
      .then((res) => {
        setBtnTxt(btnTxt);
        setFirstCategory(res.data.message);
        setCategory([
          {
            CategoryName: "",
            CategoryItems: [{ itemName: "" }],
            selectedAdmins: [],
          },
        ])
        if (isOnboarding) {
          setActiveBtn(false)
          history.push({ state: { path: "categories added" } })
          return
        }
        setActiveBtn(!activeBtn);
        handleClose({ openSent: true });
      })
      .catch((error) => {
        toast.error("Please check the Inputs!");
        setBtnTxt(btnTxt);
        setActiveBtn(false);
      });
  };

  useEffect(() => {
    getAllAdminAndSuper();
  }, []);

  useEffect(() => {
    const tag = document.getElementsByClassName("gray");
    if (tag.length !== 0) {
      tag[0].innerText = "Add Category Admin";
    }
  }, [category]);

  useEffect(() => {
    if (super_Admins.length > 1) {
      let all_admins = super_Admins;
      all_admins = all_admins.map((adm) => {
        return { label: adm.empName, value: adm.emailID };
      });
      if (all_admins.length === 0) setNoAdmin(true)
      setOptions(all_admins);
    }
  }, [super_Admins]);

  return (
    <>
      {
        noAdmin ? (
          <div className="no-admin animate__animated animate__zoomIn">
            <CategoryWarning />
            <p>You cannot create a request without an Admin assigned to a workspace</p>
            <p className="add-admin" onClick={() => {
              setNoAdmin(false)
              history.push('/dashboard/employee')
            }} >Add an Admin</p>
          </div>
        ) : (
          <section
            className={`${!isOnboarding ? 'create--request' : 'onboarding-category'} add--category ${!isOnboarding ? 'animate__animated animate__zoomIn' : null}
            ${closeModal.createRequest ? "animate__zoomOut" : null}`}
          >

            <div className="category-heading">
              <h1>Add Category</h1>
              {
                !isOnboarding && <span
                  onClick={() => {
                    handleClose({ openSent: false });
                  }}
                >
                  <BsArrowUp />
                </span>
              }
              <p>
                Add in categories along with items. You can add in more from the
                categories tab.
              </p>
            </div>

            {category.length === 1 ? (
              <>
                <ShowItem
                  category={category[0]}
                  handleInputChange={handleInputChange}
                  idx={0}
                  handleAdd={handleAdd}
                  handleCancel={handleCancel}
                  dropDownOptions={dropDownOptions}
                  handleAddCategoryAdmin={handleAddCategoryAdmin}
                  error={error}
                />
              </>
            ) : (
              <>
                {category.map((categ, id) => {
                  return (
                    <Fragment key={id}>
                      <ShowCategory
                        category={categ}
                        handleInputChange={handleInputChange}
                        idx={id}
                        handleAdd={handleAdd}
                        handleCancel={handleCancel}
                        dropDownOptions={dropDownOptions}
                        handleAddCategoryAdmin={handleAddCategoryAdmin}
                        categoryTotal={category.length}
                        error={error}
                        className="add-margin"
                        handleDeleteCategory={handleDeleteCategory}
                      />
                    </Fragment>
                  );
                })}
              </>
            )}
            {categoryUpdate ? (
              <div className="category-update">
                <button onClick={handleSubmitData} disabled={activeBtn}>
                  {btnTxt.includes("...") ? (
                    <div className="btn-loading">
                      {btnTxt}
                      <Loader />
                    </div>
                  ) : (
                    btnTxt
                  )}
                </button>
              </div>
            ) : (
              <div className="category-save">
                <button onClick={handleAddCategory} disabled={activeBtn}>
                  Add Another Category
                </button>
                {!isOnboarding && <button onClick={handleSubmitData} disabled={activeBtn}>
                  {btnTxt.includes("...") ? (
                    <div className="btn-loading">
                      {btnTxt}
                      <Loader />
                    </div>
                  ) : (
                    btnTxt
                  )}
                </button>}

              </div>
            )}
            {
              isOnboarding && (
                <>
                  <button className='category-onboard-btn' onClick={() => history.push({ state: { path: "category" } })}>Back</button> &nbsp;
                  <button onClick={handleSubmitData}
                    disabled={btnTxt.includes("...") ? true : false}
                    style={{
                      color: '#3263f3', width: "175px",
                      height: "37px", border: "1.5px solid #3263f3",
                      backgroundColor: "white", borderRadius: "24px",
                      position: 'absolute', right: '7px', bottom: '-50px'
                    }}>{btnTxt.includes("...") ? (
                      <div style={{
                        display: "flex",
                        fontSize: "16px",
                        paddingLeft: "70px"
                      }}>
                        <Loader />
                      </div>
                    ) : (
                      "Next"
                    )}</button>
                </>
              )
            }
          </section>
        )
      }
    </>

  );
};


export default AddCategory;
