import React, { useContext } from "react";
import { GlobalContext } from "../../../Context";
import { UtilsEmptyCategory } from "./CategoryUtils";

function CategoryNoData() {
    const { setModalUtils} = useContext(GlobalContext);
  return (
    <div>
       <div className="no-category">
            <UtilsEmptyCategory className = 'no-icon' />
            <h1>Add categories to start</h1>
            <div>
              <p>Add in categories along with  items that your team members can request</p>
            </div>
            <div>
              <button disabled={true} className="upload-csv">Upload CSV</button>
              <button onClick={() => {setModalUtils({ addCategory: true })}}>Add Category</button>
            </div>
          </div>
    </div>
  )
}

export default CategoryNoData