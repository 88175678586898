import React, { useContext, useState } from 'react'
import { MainSearchSvg, SearchArrow, SearchSvg } from '../svgUtils'
import "./search.css";
import { sendRequest } from '../../../../API';
import { GlobalContext } from '../../../../Context';
import { FeatureSubContext } from '../../FeatureContext';




const Search = ({ setSearchBy, openSearchBy }) => {
  const { user } = useContext(GlobalContext);
  const { setManageRequest } = useContext(FeatureSubContext)
  const [openSearch, setOpenSearch] = useState(false);
  const [data, setData] = useState();
  const { open, option, value } = openSearchBy;
  const { companyID } = (user && user.data) || {};



  const handle_search = async () => {
    try {
      setManageRequest([{}]);
      let value = {};
      if (option && option.includes("Ticket")) value.ticketNo = (data * 1);
      if (option && option.includes("Asset")) value.assetID = data;
      if (option && option.includes("Email")) value.emailID = data;
      if (option && option.includes("Responder")) value.responderID = data;

      const res = await sendRequest("POST", "items/assetID", { companyID, ...value });
      let { Items } = (res.data && res.data.result) || {};
      if (Items && Items.length > 0) {
        Items = Items.sort((a, b) => new Date(b.requestCreatedTime) - new Date(a.requestCreatedTime))
        return setManageRequest(Items);
      }
      setSearchBy({ ...openSearchBy, value: option });
      setManageRequest([]);
    } catch (error) {
      console.log(error);
    }
  };


  return <div className='search--section'>
    {!openSearch
      ? <span onClick={() => setOpenSearch(true)}><MainSearchSvg /></span>
      : <article className='search--field'>
        <div className='search--by'>
          <span onClick={() => setSearchBy({ ...openSearchBy, open: true })}>&nbsp;
            {option ? option : " Search By"} <SearchArrow />
          </span>
          {open && <div className='search--options'>
            <p onClick={() => setSearchBy({ value: value, option: "Asset ID" })}>Assest ID</p>
            <p onClick={() => setSearchBy({ value, option: "Ticket ID" })}>Ticket ID</p>
            <p onClick={() => setSearchBy({ value, option: "Email ID" })}>Email ID</p>
            <p onClick={() => setSearchBy({ value, option: "Responder" })}>Responder ID</p>
          </div>}
        </div>
        <form onSubmit={(e) => {
          e.preventDefault();
          handle_search();
        }}>
          <div className={`search ${!option && "opacity--reduce_search"}`}>
            <span onClick={() => handle_search()}><SearchSvg /></span>
            <input onChange={(e) => setData(e.target.value)}
              placeholder='What are you looking for?' className='form-control' type="text" />
          </div>
        </form>
      </article>}
  </div>
}

export default Search