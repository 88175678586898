import React, { useContext, useState } from 'react'
import { GlobalContext } from '../../../Context';
import "../../Style/profile_delete.css";
import { useHistory } from 'react-router-dom';
import Loader from '../../Spinners/Loader';
import { delete_emp_main } from './CheckBox';


const DeleteProfileModal = () => {
  const { deleteProfileModal, setDeleteProfileModal, setCloseModal, closeModal, setHandleProfile, user } = useContext(GlobalContext);
  const [loader, setLoader] = useState({ loading: false, txt: "Yes, I'm sure" });
  const { empName, empRole, emailID, companyID } = deleteProfileModal.data || {};
  const history = useHistory();
  const { loading, txt } = loader;
  const currentUser = (user && user.data) || {}



  const handleClose = () => {
    setTimeout(() => {
      setCloseModal({ sentRequest: false });
      setDeleteProfileModal({ open: false });
    }, (450));
    setCloseModal({ sentRequest: true });
  };

  const handle_delete_emp = async () => {
    try {
      setLoader({ loading: true, txt: "Please wait..." });
      await delete_emp_main({ companyID, emailID, empRole, currentUser, empName, history });
      setHandleProfile({ items: null, open: false });
      // window.location.reload();
    } catch (error) {
      setLoader({ loading: false, txt: "Yes, I'm sure" });
    }
  };





  return <section className={`profile--delete animate__animated animate__bounceIn
  ${closeModal.sentRequest && "animate__bounceOut"}`}>
    <div className='card'>
      <span className='delete_profile--close' onClick={() => handleClose()}>
        <svg width="15" height="15" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L19.5 19.5" stroke="black" strokeWidth="2" strokeLinecap="round" />
          <path d="M1 19.5L19.5 0.999999" stroke="black" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </span>
      <p>Confirm deletion of <span className='text-capitalize'>{empName}'s</span>  account? <br />
        This action cannot be undone.</p>
      <button disabled={loading}
        className='btn' onClick={() => handle_delete_emp()}>
        {txt} {loading && <Loader size={18} />}
      </button>
    </div>
  </section>
}

export default DeleteProfileModal;