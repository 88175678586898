import React, { useContext } from 'react'
import { GlobalContext } from '../../Context';
import { UtilsBilling, UtilsFaq, UtilsPolicy } from './NavbarUtils';
import { FiLogOut } from "react-icons/fi";
import { useHistory } from 'react-router-dom';



const ViewSetting = () => {
  const { setOpen, logout, user } = useContext(GlobalContext);
  const { empRole } = (user && user.data) || {};
  const history = useHistory();


  return <section className='view--settings' onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
    <div className='card'>
      <li onClick={() => {
        history.push("/privacy");
        setOpen(false);
      }}> <UtilsPolicy /> &nbsp; Privacy Policy</li>
      <li> <UtilsFaq /> &nbsp; FAQs</li>
      {
        empRole.includes("super") ? <li onClick={() => {
          history.push("/dashboard/billing");
          setOpen(false);
        }}> <UtilsBilling /> &nbsp; Billing</li> : null
      }

      <hr />

      <li onClick={() => {
        window.location.reload()
        logout();
      }} > <FiLogOut /> &nbsp; SignOut</li>
    </div>
  </section>
}

export default ViewSetting;