import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../../Context';
import { IoIosClose } from 'react-icons/io';
import { sendRequest } from '../../../API';
import { SkeletonLoader } from '../../../Component/Spinners/Skeleton';


const CostBreakModal = () => {
  const { closeModal, setCloseModal, setModalUtils, viewItem, companyData } = useContext(GlobalContext);
  const { companyOrigin } = companyData;
  const { upcomingInvoiceData } = viewItem || {};

  const handleClose = () => {
    setTimeout(() => {
      setCloseModal({ sentRequest: false });
      setModalUtils({ costBreakdown: false });
    }, (450));
    setCloseModal({ sentRequest: true });
  };


  return <section className={`cost--breakdown animate__animated animate__bounceIn
  ${closeModal.sentRequest ? "animate__bounceOut" : null}`}>
    <article>
      <p>Cost Breakdown <span onClick={() => handleClose()}>
        <IoIosClose /></span>
      </p>
    </article>
    <hr />
    {/* If Upcoming invoice...  */}
    {upcomingInvoiceData ? <><article className='cost--content'>
      <div className='cost--calc'>
        <p>{upcomingInvoiceData.quantity} Requestors monthly</p> {companyOrigin === "IN"
          ? <p>INR ₹75 x {upcomingInvoiceData.quantity} users</p>
          : <p>US $1x{upcomingInvoiceData.quantity} users</p>}
      </div>
      <div className='cost--amount'>{companyOrigin === 'IN'
        ? <p>₹{upcomingInvoiceData.subscriptionCharge}</p>
        : <p>US ${upcomingInvoiceData.subscriptionCharge}</p>}
      </div>
    </article>
      <p className='proration--charge'>Prorated Charge: <small>{companyOrigin === 'IN'
        ? "₹" + upcomingInvoiceData.prorationCharge
        : "US $" + upcomingInvoiceData.prorationCharge}
      </small>
      </p>
      <hr />
      <article className='cost--total'>
        <p>Total</p>{companyOrigin === 'IN'
          ? <p>INR ₹{upcomingInvoiceData.amountDue}</p>
          : <p>US ${upcomingInvoiceData.amountDue}</p>}
      </article></>
      /* If No Upcoming invoice starts here...  */
      : viewItem ? <>
        <article className='cost--content'>
          <div className='cost--calc'>
            <p>{viewItem} Requestors monthly</p>
            {companyOrigin === "IN" ? <p>INR ₹75 x {viewItem} users</p> : <p>US $1x{viewItem} users</p>}
          </div>
          <div className='cost--amount'>
            {viewItem ? companyOrigin === 'IN' ? <p>₹{viewItem * 75}</p> : <p>US ${viewItem || "--"}</p> : "--"}
          </div>
        </article>
        <hr />
        <article className='cost--total'>
          <p>Total</p>
          {viewItem ? companyOrigin === 'IN' ? <p>INR ₹{viewItem * 75}</p> : <p>US ${viewItem}</p> : "--"}
        </article>
      </> : null
    }
  </section>
}




const CostBreak = ({ customerID, activeUser, companyOrigin }) => {
  const { setModalUtils, setGlobalError, setViewItem } = useContext(GlobalContext);
  const [upcomingInvoice, setUpcomingInvoiceData] = useState({ items: {}, loader: true });
  const { items, loader } = upcomingInvoice || {};


  // getting Upcoming Invoices...
  const getUpcomingInvoices = async () => {
    try {
      const res = await sendRequest("POST", "stripe/upcominginvoice", { customerID });
      let { result } = res.data;
      setUpcomingInvoiceData({ items: result, loader: false });
      if (result.amountDue) setViewItem({ upcomingInvoiceData: result })
    } catch (err) {
      setUpcomingInvoiceData({ items: {}, loader: false });
      setGlobalError(err.response);
    }
  }
  useEffect(() => {
    if (customerID) getUpcomingInvoices();
  }, [customerID])


  return <>
    <p>Invoice Total</p>
    <h6>{(activeUser && activeUser.length > 0 && !loader)
      ? (items.amountDue)
        ? (companyOrigin === "IN") ? `INR ₹${items.amountDue}` : `US $${items.amountDue}`
        : (companyOrigin === "IN") ? `INR ₹${activeUser.length * 75}` : `US $${activeUser.length}`
      : <SkeletonLoader />}
    </h6>
    <button className='no--btn' onClick={() => setModalUtils({ costBreakdown: true })}>
      See cost breakdown
    </button>
  </>
}

export { CostBreakModal, CostBreak };