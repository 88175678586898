import React, { useContext } from 'react'
import { Logo } from '../Landing/Logo';
import { Link, useHistory, useLocation } from "react-router-dom";
import "../Style/navigation.css";
import { GlobalContext } from '../../Context';
import NavOperation from './NavOperation';


const Navbar = () => {
  const { pathname } = useLocation();
  const { setModalUtils, modalUtils, user, setOpenViewItemsModal } = useContext(GlobalContext);
  const { empRole } = (user && user.data) || {};
  const history = useHistory();



  const actionButtons = (text, action) => {
    return <button onClick={() => {
      (pathname.includes("/profile") ||
        pathname.includes("/paymentscreen") ||
        pathname.includes("/billing")) && history.push("/dashboard")
      setModalUtils({ ...modalUtils, [action]: true, cancelBilling: false });
    }} className='btn'>
      {text}
    </button>
  };
  const actionTabs = (tab1, tab2, action) => {
    return <div className='lt_nav--tabs'>
      {empRole && !empRole.includes("requester")
        ? <p className={!modalUtils[action] ? "active--navbar" : null}
          onClick={() =>{
            setOpenViewItemsModal(false)
            setModalUtils({ [action]: false })
          }}>{tab1}
        </p> : null}
      {tab2
        ? <p className={modalUtils[action] ? "active--navbar" : null}
          onClick={() => {
            setOpenViewItemsModal(false)
            setModalUtils({ [action]: true })}
          }>{tab2}</p>
        : null}
    </div>
  };


  if (pathname.includes('/dashboard')) {
    return <>
      <nav className='dashboard--nav shadow-sm'>
        <section className='reqo--logo' onClick={() => history.push("/dashboard")}>
          <div className='logo'> <Logo /> </div>
        </section>
        <section className='nav--tabs'>
          <NavOperation actionButtons={actionButtons} actionTabs={actionTabs} />
        </section>
      </nav>
    </>
  }


  return <section className='header--section' style={{
    backgroundColor: pathname.includes("terms") ? "#C4D2FC"
      : pathname.includes("privacy") || pathname.includes("cancellation") ? "#FFF7D9" : null,
    position: pathname.includes("register") ? "absolute" : null
  }}>
    <Link to="/"><div className='lt_header_section'> <Logo /> </div></Link>
    {(pathname.length <= 1 || pathname.includes('privacy')
      || pathname.includes("terms")
      || pathname.includes("cancellation"))
      ? <div className='rt_header_section'>
        <p>FAQs</p>
        <Link to="/login">
          <button className='login_btn'> Sign In</button>
        </Link>
      </div> : null}
  </section>
}

export default Navbar;