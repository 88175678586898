import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify';
import { sendRequest } from '../../../API';
import { GlobalContext } from '../../../Context';
import { FeatureSubContext } from '../../../Pages/Manage/FeatureContext';
import { RejectRequestsSign } from '../../DashboardUtils';
import Loader from '../../Spinners/Loader';



const DeleteRequestModal = ({ setHandleView }) => {
  const { closeModal, setCloseModal, requestView, user } = useContext(GlobalContext);
  const { getMyRequests } = useContext(FeatureSubContext);
  const { ticketNo } = requestView || {};
  const [btnTxt, setBtnTxt] = useState(false);
  const { emailID } = (user && user.data) || {};

  
  const handleClose = () => {
    setTimeout(() => {
      setCloseModal({ sentRequest: false });
      setHandleView({ deleteModal: false });
    }, (450));
    setCloseModal({ sentRequest: true });
  };


  const delete_fn = async () => {
    try {
      setBtnTxt(true);
      await sendRequest("DELETE", `query/deleteRequest?emailId=${emailID}&ticketNo=${ticketNo}`, {});
      setBtnTxt(false);
      handleClose();
      getMyRequests();
    } catch (error) {
      console.log(error.response);
      setBtnTxt(false);
      const { status, data } = error.response || {};
      if (status === 403) return toast.error(data.message);
      toast.error("Error while deleting request!");
    }
  };

  return <main className={`sent--request animate__animated animate__bounceIn ${closeModal.sentRequest
    ? "animate__bounceOut" : null}`}>
    <section onClick={() => handleClose()} className='goback'>Go Back</section>
    <section>
      <article style={{ margin: "5% 0% 5% 0%" }}> <RejectRequestsSign /></article>
      <p>
        Are you sure you want to delete request <b>#{ticketNo}? </b>
        You will not be able to undo this action.
      </p>
      <div className='reject--request--btn'>
        <button disabled={btnTxt} className='btn' onClick={() => delete_fn()}>
          {btnTxt ? <Loader size={20} /> : "Yes"}
        </button>
      </div>
    </section>
  </main>
};

export default DeleteRequestModal;