import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Welcome from "../Authenticate/Welcome";
import { GlobalContext } from "../../Context";

const SentRequestModal = () => {
  const { setModalUtils, closeModal, setCloseModal, modalUtils, viewItem, setViewItem, setOpenMyRequestTab } = useContext(GlobalContext);
  const { pathname } = useLocation();
  const history = useHistory();

  const handleClose = (time, request) => {
    setTimeout(() => {
      setViewItem({});
      setCloseModal({ sentRequest: false });
      setModalUtils({ ...modalUtils, openSentRequestModal: false });
      if (request) {
        history.push("/dashboard")
        setOpenMyRequestTab(true);
      }
    }, time);
    setCloseModal({ sentRequest: true });
  };

  const openModal = () => {
    setViewItem({});
    setModalUtils({ openSentRequestModal: false });
    if (pathname.includes("employee")) setModalUtils({ addEmployee: true });
    if (pathname.includes("category")) setModalUtils({ addCategory: true });
  }


  return (
    <main className={`sent--request animate__animated animate__bounceIn 
    ${closeModal.sentRequest ? "animate__bounceOut" : null}`}>
      <section onClick={() => handleClose(500)} className='goback'>Go Back</section>
      <section className="mt-4">
        <article>
          <Welcome />
        </article>
        <h1>
          {(viewItem === "request added") ? "Request Sent"
            : pathname.includes("employee") ? (viewItem === "added") ? "Employee Added" : "Employee Updated"
              : pathname.includes("category")
                ? "Category Added"
                : null
          }
        </h1>
        <p>
          {(viewItem === "request added") ? `Your Request has been successfully created. 
              You will be able to view your requests in the My Requests tab.`
            : pathname.includes("employee")
              ? "Employee details have been added to the record."
              : pathname.includes("category")
                ? `Your category has been succesfully added in the category mangement section.
            You may Edit or delete it as per your convinience`: null
          }
        </p>
        <div className="sent--request--btn">
          {(viewItem === "request added") ? <button className="btn" onClick={() => handleClose(500, true)}>
            Go back to Dashboard
          </button>
            : <button className="btn" onClick={() => openModal()}>
              {pathname.includes("employee")
                ? "Add Another Employee"
                : pathname.includes("category")
                  ? "Add Another Category"
                  : null
              }
            </button>
          }
        </div>
      </section>
    </main>
  );
};

export default SentRequestModal;
