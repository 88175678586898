import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion'


const Welcome = ({ val }) => {
  return <svg style={{ fontWeight: "bolder" }} width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M55.9993 109.999C85.8223 109.999 109.999 85.8223 109.999 55.9993C109.999 26.1763 85.8223 2 55.9993 2C26.1763 2 2 26.1763 2 55.9993C2 85.8223 26.1763 109.999 55.9993 109.999Z" fill="#3263F3" stroke="#464555" strokeWidth="2.14057" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M54.802 94.186C76.0343 94.186 93.2465 76.9738 93.2465 55.7414C93.2465 34.5091 76.0343 17.2969 54.802 17.2969C33.5696 17.2969 16.3574 34.5091 16.3574 55.7414C16.3574 76.9738 33.5696 94.186 54.802 94.186Z" fill="white" stroke="#464555" strokeWidth="2.14057" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M41.5312 57.5687L51.6633 68.985L75.5805 46.1523" fill="white" />
    <motion.path
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ ease: "easeInOut", duration: 0.65, delay: val }}
      strokeWidth="5.06955"
      strokeLinecap="round"
      d="M41.5312 57.5687L51.6633 68.985L75.5805 46.1523" stroke="#3263F3" strokeLinejoin="round" />
  </svg>
}

export default Welcome;