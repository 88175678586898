import React from "react";

export const CategoryUtils = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <circle
          cx="9.5"
          cy="9.5"
          r="8.82205"
          stroke="black"
          strokeWidth="1.35591"
        />
        <path
          d="M4.03125 10.8129L4.76771 9.40234C4.76771 12.2827 7.1029 14.6179 9.98328 14.6179C10.7085 14.6179 11.4276 14.4174 11.5339 14.3837C13.552 13.7342 14.5145 11.982 14.7692 11.4777"
          stroke="black"
          strokeWidth="1.35591"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.76953 9.40234L5.9182 10.55"
          stroke="black"
          strokeWidth="1.35591"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M15.6335 8.41051L14.8971 9.82104C14.8961 6.94066 12.5609 4.60547 9.6805 4.60547C8.95529 4.60547 8.23622 4.80595 8.12984 4.8397C6.11174 5.49024 5.14922 7.24138 4.89453 7.74565"
          stroke="black"
          strokeWidth="1.35591"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.8976 9.81952L13.75 8.67188"
          stroke="black"
          strokeWidth="1.35591"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export const BlueAutoCorrect = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9.5"
        cy="9.5"
        r="8.82205"
        stroke="#0043FF"
        strokeWidth="1.35591"
      />
      <path
        d="M4.03125 10.8129L4.76771 9.40234C4.76771 12.2827 7.1029 14.6179 9.98328 14.6179C10.7085 14.6179 11.4276 14.4174 11.5339 14.3837C13.552 13.7342 14.5145 11.982 14.7692 11.4777"
        stroke="#0043FF"
        strokeWidth="1.35591"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.76953 9.40234L5.9182 10.55"
        stroke="#0043FF"
        strokeWidth="1.35591"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.6335 8.41051L14.8971 9.82104C14.8961 6.94066 12.5609 4.60547 9.6805 4.60547C8.95529 4.60547 8.23622 4.80595 8.12984 4.8397C6.11174 5.49024 5.14922 7.24138 4.89453 7.74565"
        stroke="#0043FF"
        strokeWidth="1.35591"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8976 9.81952L13.75 8.67188"
        stroke="#0043FF"
        strokeWidth="1.35591"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const DefaultItemUnSelect = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.56"
        cx="9"
        cy="9"
        r="8"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
};

export const DefaultItemSelect = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="8" stroke="black" strokeWidth="2" />
      <circle cx="9" cy="9" r="4.5" fill="#3263F3" />
    </svg>
  );
};

export const SuccessIcon = () =>{
  return(
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.5" stroke="black"/>
      <path d="M18.5175 11.2472C18.5175 10.4387 17.9112 9.83242 17.0825 9.83242C16.1124 9.83242 15.2636 10.5802 15.2636 11.5705C15.2636 12.3789 15.8699 12.9448 16.6783 12.9448C17.6484 12.9448 18.5175 12.2375 18.5175 11.2472ZM17.7495 14.4L15.2636 14.4606L14.2935 22.3023C14.152 23.4139 14.6168 24.1415 16.0114 24.1415C16.7794 24.1415 17.2038 24.0202 17.3453 23.9798L17.4867 22.0598C17.4059 22.08 17.3048 22.1002 17.1634 22.1002C16.9411 22.1002 16.7996 21.9992 16.84 21.7364L17.7495 14.4Z" fill="black"/>
    </svg>
  )
}

export const UtilsEmptyCategory = () => {
  return <svg width="182" height="172" viewBox="0 0 182 172" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M97.2681 51.5138C110.226 48.4537 118.25 35.4683 115.19 22.5102C112.13 9.55208 99.1446 1.52813 86.1865 4.58822C73.2283 7.64832 65.2044 20.6337 68.2645 33.5918C71.3246 46.5499 84.3099 54.5739 97.2681 51.5138Z" fill="#C4D2FC" stroke="#8AA6F8" strokeWidth="6.12379"/>
  <path d="M18.427 168.37C24.7585 166.875 28.6791 160.53 27.1839 154.199C25.6887 147.867 19.3439 143.947 13.0124 145.442C6.68082 146.937 2.7602 153.282 4.25541 159.614C5.75062 165.945 12.0954 169.866 18.427 168.37Z" fill="#C4D2FC" stroke="#8AA6F8" strokeWidth="6.12379"/>
  <path d="M94.1906 167.245C100.522 165.75 104.443 159.405 102.948 153.074C101.452 146.742 95.1076 142.822 88.776 144.317C82.4445 145.812 78.5239 152.157 80.0191 158.489C81.5143 164.82 87.8591 168.741 94.1906 167.245Z" fill="#C4D2FC" stroke="#8AA6F8" strokeWidth="6.12379"/>
  <path d="M91.4829 52.0537V98.0808" stroke="#8AA6F8" strokeWidth="6.46186" strokeLinecap="round"/>
  <path d="M15.7183 98.0801V144.107" stroke="#8AA6F8" strokeWidth="6.46186" strokeLinecap="round"/>
  <path d="M91.4829 98.0801V144.107" stroke="#8AA6F8" strokeWidth="6.46186" strokeLinecap="round"/>
  <path d="M167.735 98.0801V144.107" stroke="#8AA6F8" strokeWidth="6.46186" strokeLinecap="round"/>
  <path d="M15.7183 98.0801L123.526 98.0801M167.736 98.0801L145.631 98.0801" stroke="#8AA6F8" strokeWidth="6.46186" strokeLinecap="round"/>
  <rect x="157.411" y="144" width="20.6503" height="20.6503" rx="5.93383" fill="#C4D2FC" stroke="#8AA6F8" strokeWidth="6.12379"/>
  </svg>
}

export const HelpUtils = () =>{
  return <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_i_441_32869)">
  <circle cx="9.5" cy="9.5" r="8.85417" stroke="#0043FF" strokeWidth="1.29167"/>
  </g>
  <path d="M13.8167 7.17363C13.7638 7.11861 13.7009 7.07494 13.6315 7.04514C13.5621 7.01534 13.4877 7 13.4126 7C13.3374 7 13.263 7.01534 13.1937 7.04514C13.1243 7.07494 13.0613 7.11861 13.0084 7.17363L8.7676 11.5526L6.98588 9.70944C6.93093 9.65471 6.86607 9.61167 6.795 9.58279C6.72393 9.55391 6.64804 9.53974 6.57166 9.54111C6.49527 9.54247 6.4199 9.55933 6.34984 9.59073C6.27978 9.62213 6.2164 9.66746 6.16333 9.72411C6.11025 9.78077 6.06852 9.84765 6.04051 9.92094C6.0125 9.99423 5.99877 10.0725 6.00009 10.1513C6.00141 10.23 6.01776 10.3077 6.04821 10.38C6.07866 10.4522 6.12261 10.5176 6.17756 10.5723L8.36344 12.8264C8.41635 12.8814 8.47931 12.9251 8.54868 12.9549C8.61805 12.9847 8.69245 13 8.7676 13C8.84274 13 8.91714 12.9847 8.98651 12.9549C9.05588 12.9251 9.11884 12.8814 9.17175 12.8264L13.8167 8.03651C13.8745 7.98154 13.9206 7.91483 13.9522 7.84058C13.9837 7.76632 14 7.68614 14 7.60507C14 7.52401 13.9837 7.44382 13.9522 7.36957C13.9206 7.29531 13.8745 7.2286 13.8167 7.17363V7.17363Z" fill="#3263F3"/>
  <defs>
  <filter id="filter0_i_441_32869" x="0" y="0" width="19" height="24.1667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="5.16667"/>
  <feGaussianBlur stdDeviation="6.45833"/>
  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.238281 0 0 0 0 0.421998 0 0 0 0 0.9375 0 0 0 0.2 0"/>
  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_441_32869"/>
  </filter>
  </defs>
  </svg>
}

export const CategoryWarning = () =>{
  return(
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.5 64C49.897 64 64 49.897 64 32.5C64 15.103 49.897 1 32.5 1C15.103 1 1 15.103 1 32.5C1 49.897 15.103 64 32.5 64Z" fill="#EC5D6D" stroke="#464555" strokeWidth="1.24868" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.8017 54.7744C44.1874 54.7744 54.228 44.7338 54.228 32.3481C54.228 19.9625 44.1874 9.92188 31.8017 9.92188C19.4161 9.92188 9.37549 19.9625 9.37549 32.3481C9.37549 44.7338 19.4161 54.7744 31.8017 54.7744Z" fill="white" stroke="#464555" strokeWidth="1.24868" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M35.1348 17.2148H28.8374L29.48 37.0496L34.3209 36.8783L35.1348 17.2148ZM29.6942 41.9334C29.0944 43.1329 29.5228 44.5894 30.7223 45.1892C31.9219 45.8318 33.4213 45.3605 34.021 44.161C34.6636 42.9615 34.1924 41.4621 32.9929 40.8624C31.7505 40.2198 30.294 40.7338 29.6942 41.9334Z" fill="black"/>
    </svg>
  )
}