import React from 'react';
import { Link } from 'react-router-dom';
import BetaUtils from "./BetaUtils";


const Beta = () => {
  return <section className='beta--release'>
    <div className='round'>
      <div className='circle'></div>
      <BetaUtils />
      <h1 data-aos="fade-up" data-aos-duration="600" data-aos-easing="ease-in-out">
        We're happy you're here</h1>
      <p data-aos="fade-up" data-aos-duration="850" data-aos-easing="ease-in-out">
        Reqo is currently in its beta phase. We're glad
        that you are one of the first ones here.
      </p>
      <Link to="/register">
        <button className='btn' data-aos="zoom-in" data-aos-duration="1000" data-aos-easing="ease-in-out">
          Get started for free
        </button>
      </Link>
    </div>
  </section>
}

export default Beta;