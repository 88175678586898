import React, { Fragment, useContext, useState } from 'react'
import { HelpUtils } from '../Category/CategoryUtils'
import { GlobalContext } from "../../../Context";
import { sendRequest } from '../../../API';
import Loader from '../../../Component/Spinners/Loader';

const HelpComponent = (props) =>{
    const { idx, h2Value,boxobj,boxName, pValue, placeholder, setShowPop, showPop, setAnimate, setBtnTx} = props
    const { user } = useContext(GlobalContext)
    const [ inputValue, setInputValue] = useState('')
    const {btnTxt} = boxobj
    const handleChange = (event) =>{
        setInputValue(event.target.value)
    }

    const handleSubmit = () =>{
        const resultBox = [...boxName]
        resultBox[idx].btnTxt = 'Processing...'
        setBtnTx(resultBox)

        const { emailID } = user.data
        const result = {
            companyName: user.company.companyName,
            contentType: h2Value === 'Have a Suggestion?' ?'suggestion':'Bug',
            content: inputValue,
            emailID
        }
        const btnComponent = [...boxName]
        btnComponent[idx].btnTxt = 'Submit Report'
        sendRequest('POST','/support',result)
        .then(() =>{
            setAnimate(false)
            setShowPop(!showPop)
            setInputValue('')
            setBtnTx(btnComponent)
            setTimeout(() =>{
                setShowPop(false)
            },8000)
            setTimeout(() =>{
                setAnimate(true)
            },7000)
        })
        .catch(() =>{
            setInputValue('')
            setBtnTx(btnComponent)
        })

    }

    return(
        <div className='each-help'>
            <h2>{h2Value}</h2>
            <p>{pValue}</p>
            <div>
                <textarea type='text' value={inputValue} onChange= {handleChange} placeholder={placeholder}></textarea>
                <button disabled={inputValue === '' || btnTxt.startsWith('P')?true:false} className={inputValue === ''?'disabled-button':null} onClick={handleSubmit}>{btnTxt} {btnTxt.startsWith('P') && <Loader className='help-loader'/>}</button>
            </div>
        </div>
    )
}

const HelpSupport = ()=> {
    const [ showPop, setShowPop ] = useState(false)
    const [ animate, setAnimate ] = useState(true)
    const [ box, setBox ] = useState([
        {btnTxt: 'Submit Report', placeholder: 'Tell us what went wrong.', heading: 'Report a Problem', subTxt: 'Something not working right?  We’ll try to fix it at the earliest'},
        {btnTxt: 'Submit Report', placeholder: 'Tell us your idea here.', heading: 'Have a Suggestion?', subTxt: 'Have an idea that you would like to see come to life in Reqo?'}
    ])

    return (
        <div className='help-support'>
            <div>
                <h1>Help and Support</h1>
                <p>Need something?  Our team is always here to help.</p>
            </div>
            {
                showPop && <p className={`help-popUp animate__animated animate__zoomIn ${animate?'animate__animated animate__zoomOut':null}`}><HelpUtils/> Your report has been sent to our team</p>
            }
            <div className='help-margin'>
            {
                box.map((boxObj, idx) =>{
                    return(
                        <Fragment key={idx}>
                            <HelpComponent boxName = {box} boxobj = {boxObj} idx = {idx} setBtnTx = {setBox} setAnimate = {setAnimate} showPop = {showPop} setShowPop ={setShowPop} h2Value = {boxObj.heading} pValue = {boxObj.subTxt} placeholder = {boxObj.placeholder}/>
                        </Fragment>
                    )
                })
            }
            </div>
        </div>
    )
}

export default HelpSupport