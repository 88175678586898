import React from 'react'
import { FiStar, FiCheck } from 'react-icons/fi';
import { RiTimerLine } from 'react-icons/ri';


const Banner = () => {

  const banner_d = (Icon, text) => {
    return <p> <span><Icon className='icon' /></span> {text}</p>
  }

  return <section className='banner_section'>
    <div className='container'>
      {banner_d(FiStar, "Easy Setup")}
      {banner_d(RiTimerLine, "Time Saving")}
      {banner_d(FiCheck, "Efficient")}
    </div>
  </section>
}

export default Banner;