import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { getRequest, sendRequest } from '../../../API';
import { useContext } from 'react';
import { GlobalContext } from '../../../Context';
import AddMemberComp from './AddMemberComp';
import Loader from "../../../Component/Spinners/Loader";
import { checkForAnother, domainValidator, fieldValidation } from './OnBoardUtils';


const AddMembers = ({ history }) => {
  const { user, setCustomToast } = useContext(GlobalContext);
  const { handleSubmit, formState: { errors }, register, setValue, watch, control } = useForm({
    defaultValues: { employees: [{ empName: "", empID: "", emailID: "" }] }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "employees",
  });
  const { companyID, companyName } = (user && user.company) || {};
  const [addAnother, setAddAnother] = useState(false);
  const [check, setCheck] = useState(false);
  const [customErrorDisabled, setCustomErrorDisabled] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);




  // disabling button, preventing user from adding another employee if error......
  const getValues = watch();
  useEffect(() => {
    fieldValidation({ getValues, setCustomErrorDisabled })
  }, [getValues]);


  // Submitting employee data......
  const submit_data = async (data) => {
    const modifiedData = data.employees.filter((items) => items.empName);
    if (customErrorDisabled) return null;
    if (addAnother) return checkForAnother({ setCheck, fields, append, setAddAnother });

    const validateEmail = await domainValidator({ modifiedData, companyName });
    if (validateEmail.length > 0)
      return setCustomToast({ content: `Email must be a Valid Domain!`, open: true, error: true })
    // mapping empRole & responderID...
    modifiedData && modifiedData.filter((items) => {
      items.emailID = items.emailID.toLowerCase();
      items.empRole = items.empRole && items.empRole.value;
      items.responderID = items.responderID && items.responderID.value;
      return items;
    });
    try {
      setBtnLoader(true);
      // fetch company Details...
      const url = `company/getCompany?companyName=${companyName}&companyID=${companyID}`;
      const { data: { result } } = await getRequest(url);
      if (!result) throw Error("Company Not Found!");
      const { actualEmployeeCount, subscriptionStatus, subscriptionID, companyOrigin } = result;
      const companyData = {
        actualEmployeeCount,
        subscriptionStatus,
        subscriptionID,
        companyName,
        companyID,
        companyOrigin
      };
      // Adding employees Details...
      await sendRequest("POST", "employee/user/createUser", { employees: modifiedData, companyData });
      setBtnLoader(false);
      history.push({ state: { path: "members added" } });
    } catch (error) {
      setBtnLoader(false);
      const { status } = error.response || {};
      if (status === 302) return setCustomToast({ content: `Employee with Email already exists!`, open: true, error: true })
      setCustomToast({ content: 'Try again, Something Went Wrong!', open: true, error: true })
    }
  };



  return <> <section className='add--members'>
    <article className='progress--view'>
      <p>1</p>
      <hr />
      <p>2</p>
    </article>
    <h2>Add Member Details</h2>
    <p className='info'>Add in member details. They will be notified via mail.
      <br /> You can  add in more members from the Team tab.
    </p>

    <form className='add--member--form' onSubmit={handleSubmit(submit_data)}>
      {fields.map((item, i) => {
        var checkError = errors.employees && errors.employees[i];
        return <div key={item.id}>
          <AddMemberComp
            i={i}
            register={register}
            watch={watch}
            checkError={checkError}
            errors={errors}
            control={control}
            setValue={setValue}
            companyID={companyID}
            check={check}
            Controller={Controller}
            remove={remove} />
        </div>
      })}

      <button className='create--btn' disabled={customErrorDisabled} onClick={() => setAddAnother(true)}>
        Add another Member
      </button>

      <article id="member" className='navigation--buttons'>
        <button onClick={() => history.push({ state: { path: "start flow" } })}>Back</button>
        <button onClick={() => setAddAnother(false)}
          disabled={customErrorDisabled || btnLoader}>{btnLoader ? <Loader /> : "Save Details"}</button>
      </article>
    </form>

  </section>
  </>
}

export default AddMembers;




