import React, { Fragment } from 'react'
import { sendRequest } from '../../../API';

const CheckBox = () => {
  return <Fragment>
    <input type="checkbox" disabled={true} checked={true} />
  </Fragment>
};

const delete_emp_main = async ({ companyID, emailID, empRole, currentUser, empName, history }) => {
  try {
    const data_send = {
      companyID,
      emailID,
      role: empRole,
      updaterName: currentUser.empName,
      updaterRole: currentUser.empRole,
      updaterEmailID: currentUser.emailID
    }
    const { data } = await sendRequest("POST", "employee/user/delete", { ...data_send });
    const { code, result } = data.result || {};
    if (code === 202) {
      return history.push("/dashboard/pending", { category: result.Items, code: 202, name: empName, role: empRole, mail: emailID });
    }
    if (code === 203) {
      return history.push("/dashboard/pending", { state: result.Items, name: empName, role: empRole, mail: emailID });
    }
    if (code === 204) {
      return history.push("/dashboard/reassign_reportees", { reportees: result.Items, name: empName, role: empRole, mail: emailID });
    }
    window.location.href = "/dashboard/employee";
  } catch (error) {
    console.log(error);
  }
};

export { CheckBox, delete_emp_main };