import React, { useState } from "react";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { getRequest } from "../../API";
import { BsFiletypePdf } from "react-icons/bs";

const FileUpload = ({ empName, setImageData }) => {
  const [data, setData] = useState("");
  var url = data && URL.createObjectURL(data);

  const handleOutPut = async (data) => {
    let random = Math.floor(Math.random() * 10000000 + 1).toString(16);
    const extension = data.name.match(/\.[0-9a-z]+$/i)[0];
    let fileName = `${empName}_request_${random}${extension}`;

    try {
      const res = await getRequest(`query/uploadimage?fileName=${fileName}`);
      const { result } = res.data || {};
      if (result) setImageData({ data, signedUrl: result, imageKey: fileName });
    } catch (error) {}
  };

  const validateImage = (data) => {
    if (data.size > 5000000) {
      return toast.info("Image Size must not be greater than 1 MB", {
        style: { fontSize: 14 },
      });
    }
    let allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "application/pdf",
    ];
    const { type } = data || {};
    const check =
      type && allowedExtension.find((items) => items.includes(type));
    if (check) {
      handleOutPut(data);
      setData(data);
    } else {
      setData("");
      setImageData({ data: "", signedUrl: "" });
      toast.info("file type not supported!");
    }
  };

  return (
    <>
      {!data && (
        <label className="request-file">
          + Upload File
          <input
            type="file"
            id="files"
            className="file-req"
            onChange={(e) => {
              validateImage(e.target.files[0]);
            }}
          />
        </label>
      )}

      {data ? (
        <div className="uploaded-file">
          <p>Request File</p>
          <div>
            {data.type === "application/pdf" ? (
              <>
                <BsFiletypePdf className="pdf_file" size={18} />
                {data.name}
              </>
            ) : (
              <img src={url} alt="some" />
            )}

            <IoMdClose
              className="close-file"
              onClick={() => {
                setData("");
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FileUpload;
