import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import Welcome from "../Authenticate/Welcome";
import { GlobalContext } from '../../Context';
import { RejectRequestsSign } from '../DashboardUtils';
import { useState } from 'react';
import { FeatureSubContext } from '../../Pages/Manage/FeatureContext';
import { sendRequest } from '../../API';
import Loader from '../Spinners/Loader';
import { toast } from 'react-toastify';


const ApproveRequestModal = () => {
  const { closeModal, setCloseModal, setModalUtils, user, setCustomToast } = useContext(GlobalContext);
  const { getManageRequests, isApprove, setRequestView, requestView } = useContext(FeatureSubContext);
  const [btnTxt, setBtnTxt] = useState(false);
  const { emailID } = (user && user.data) || {};


  const handleClose = () => {
    setTimeout(() => {
      setCloseModal({ sentRequest: false });
      setModalUtils({ approveRejectModal: false });
    }, (450));
    setCloseModal({ sentRequest: true });
  };


  const rejectfn = async (data, status_reject) => {
    setBtnTxt(true);
    const { companyID, ticketNo } = data;
    try {
      const res = await sendRequest("POST", "items/update", {
        emailID, companyID, ticketNo,
        status: status_reject, comments: "", assetId: "Null"
      })
      const { result: { history, status, track } } = res.data || {};
      setBtnTxt(false);
      setModalUtils({ approveRejectModal: false });
      getManageRequests();
      setCustomToast({ content: 'Request rejected Successfully!', open: true, error: false });
      if (history) setRequestView({ ...data, history, status, track });
    } catch (error) {
      setBtnTxt(false);
      const { status, data } = error.response || {};
      if (status === 403) return toast.error(data.message);
      toast.error(("Something went wrong!"));
    }
  }

  return <main className={`sent--request animate__animated animate__bounceIn ${closeModal.sentRequest ? "animate__bounceOut" : null}`}>
    {!isApprove ? <section onClick={() => handleClose()} className='goback'>Go Back</section> : null}
    <section style={{ marginTop: !isApprove ? "8%" : null }}>
      <article style={{ marginTop: "0%" }}> {!isApprove ? <RejectRequestsSign /> : <Welcome val={0.09} />}</article>
      {isApprove ? <h1>Request Approved</h1> : null}
      <p>
        {isApprove
          ? `You have approved the request. Details can be viewed in the requests tab.`
          : <>Are you sure you want to Reject request <b>#{requestView.ticketNo}? </b>
            You will not be able to undo this action.</>
        }
      </p>
      {
        isApprove ? <div className='sent--request--btn'>
          <Link to="/dashboard" onClick={() => handleClose()}><button className='btn'>
            Go back to Requests
          </button>
          </Link>
        </div> : <div className='reject--request--btn'>
          <button className='btn' onClick={() => {
            rejectfn(requestView, "reject");
          }}>
            {btnTxt ? <Loader size={20} /> : "Reject"}
          </button>
        </div>
      }
    </section>
  </main>
};

export default ApproveRequestModal;