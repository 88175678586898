import React, { useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { Inputs, CheckBox } from '../../Component/Authenticate/Inputs';
import { useState } from 'react';
import { GlobalContext } from '../../Context';
import { sendRequest } from '../../API';
import { country_items, description_items, industry_items } from "./authUtils"
import { GrDown } from 'react-icons/gr';
import { IoAlertCircleOutline } from "react-icons/io5"
import moment from "moment";
import Loader from "../../Component/Spinners/Loader";
import PersonalDetails from './PersonalDetails';
import Select from 'react-select';


const CreateWorkspace = () => {
  const history = useHistory();
  const { setCookie } = useContext(GlobalContext);
  const [tabs, setTabs] = useState(false);
  const [tabSwitch, setTabSwitch] = useState(true);
  const [btnTxt, setBtnTxt] = useState("Enter Personal Details");

  let { given_name, getDomain, picture } = history.location.state || {};
  const { handleSubmit, formState: { errors }, register, watch, control } = useForm();
  const watchValues = watch();


  useEffect(() => {
    if (!getDomain) return history.push('/register');
    if (!tabSwitch) setBtnTxt("Create Workspace")
    else setBtnTxt("Enter Personal Details");
  }, [history, tabSwitch]);


  const submit_workspace_data = (data) => {
    setBtnTxt("Processing...");
    setTimeout(() => {
      setTabs(true);
      setTabSwitch(false);
      setBtnTxt("Enter Personal Details");
    }, 1000);
  };

  const submit_personal_data = (data) => {
    const { companyOrigin, companySector, description } = data || {};
    setBtnTxt("Processing...");
    data.isDeleted = "false";
    data.subscriptionEnded = moment().add(30, 'd').format();
    data.companyOrigin = companyOrigin.value;
    data.companySector = companySector.value;
    data.description = description.value;
    delete data.check;

    sendRequest("POST", "company/createCompany", data).then((res) => {
      setBtnTxt("Redirecting...");
      const { token } = res.data && res.data.result;
      setCookie("u_detials", token);
      setCookie("g_token", picture);
      setTimeout(() => {
        return window.location.href = "/dashboard/onboarding";
      }, 200);
    }).catch(() => {
      setBtnTxt("Create Workspace");
    })
  };

  const styles_fn = (key) => {
    return {
      control: styles => ({
        ...styles,
        borderColor: (errors[key]) ? "#EC5D6D!important" : null
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: (errors[key]) ? "#EC5D6D!important" : null,
          opacity: 0.4
        }
      }
    };
  }



  return <main className='create--workspace'>
    <section className='tabs--details'>
      <article onClick={() => {
        setTabSwitch(true)
        setTabs(false);
      }}>
        <p><span className='tab--number'>1</span></p>
        <p>Workspace Details</p>
      </article>
      <article className={!tabs ? "no--operation" : null} onClick={() => tabs ? setTabSwitch(false) : null}>
        <p><span className='tab--number'>2</span></p>
        <p>Personal Details</p>
      </article>
    </section>

    {tabSwitch
      ? <> <h1>Hi <span className='animate__animated animate__fadeIn'>{given_name}</span>, lets create your workspace.</h1>
        <p>Tell us more about your organization.  All (*) fields are mandatory</p>

        {errors.companySize ? <p className='alert--validation'> <IoAlertCircleOutline /> &nbsp;Enter numbers between 2 - 500 only*</p> : null}
        <form onSubmit={handleSubmit(submit_workspace_data)}>
          <section className='input--section animate__animated animate__fadeInUp'>
            <div>
              <label htmlFor="">Name of your company</label>
              <Inputs value={(getDomain && getDomain[0])}
                register={register} errors={errors} name={"companyName"} placeholder="Name of your company*"
                require={true} readOnly={true} />
            </div>
          </section>

          <section className='input--section animate__animated animate__fadeInUp'>
            <div>
              {watchValues.companySize ? <label style={{ color: errors.companySize ? "#FF4343" : null }}
                htmlFor="">Company Size*</label> : null}
              <Inputs register={register} errors={errors} name={"companySize"} placeholder="Company Size*"
                require={true} max={500} min={1} regex={/^[0-9]*$/} />
            </div>
            <div>
              <GrDown className='icon--1' />
              {watchValues.companySector ? <label htmlFor="">Industry*</label> : null}
              <Controller
                control={control}
                name="companySector"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => <Select
                  value={value}
                  styles={styles_fn("companySector")}
                  className="custom"
                  classNamePrefix="select"
                  placeholder="Industry"
                  options={industry_items}
                  onChange={(e) => onChange(e)}
                />}
              />
            </div>
          </section>

          <section className='input--section input--modified animate__animated animate__fadeInUp'>
            <div>
              <GrDown className='icon--1' />
              {watchValues.companyOrigin ? <label htmlFor="">Country*</label> : null}
              <Controller
                control={control}
                name="companyOrigin"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => <Select
                  value={value}
                  styles={styles_fn('companyOrigin')}
                  classNamePrefix="select"
                  placeholder="Country*"
                  options={country_items}
                  onChange={(e) => onChange(e)}
                />}
              />
            </div>
          </section>

          <section className='input--section input--modified--two animate__animated animate__fadeInUp'>
            <div>
              <GrDown className='icon--1' />
              {watchValues.description ? <label htmlFor="">Where did you hear about us?</label> : null}
              <Controller
                control={control}
                name="description"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => <Select
                  value={value}
                  styles={styles_fn("description")}
                  classNamePrefix="select"
                  placeholder="Where did you hear about us?*"
                  options={description_items}
                  onChange={(e) => onChange(e)}
                />}
              />
            </div>
          </section>

          <section className='checkbox--section input--modified--three  animate__animated animate__fadeInUp'>
            <article className="checkbox">
              <CheckBox name="check" register={register} errors={errors} />
              {errors.check ? <span className='shadow'>Required</span> : null}
            </article>
            <p>I agree to Reqos <a target="_blank" href="/terms">Terms and Conditions</a></p>
          </section>

          <section className='workspace--btn animate__animated animate__fadeInUp'>
            <button disabled={btnTxt.includes("...") ? true : false} className="btn">
              {btnTxt} {btnTxt.includes("...") ? <Loader /> : null}
            </button>
          </section>
        </form> </>
      : <PersonalDetails submit_data={submit_personal_data}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        watchValues={watchValues}
        btnTxt={btnTxt} />
    }
  </main>
}

export default CreateWorkspace;