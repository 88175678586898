import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify';
import { sendRequest } from '../../../API';
import { FeatureSubContext } from '../FeatureContext';
import Loader from "../../../Component/Spinners/Loader";
import { GlobalContext } from '../../../Context';
import { SkeletonLoader } from '../../../Component/Spinners/Skeleton';
import { HiOutlinePencil } from "react-icons/hi";
import { SuccessIcon } from '../Category/CategoryUtils';



const Operation = ({ setHandleView, items }) => {
  const { setModalUtils, setViewItem, handleProfile } = useContext(GlobalContext);
  // const { companyName } = (user && user.company) || {};
  // const { getEmployees } = useContext(FeatureSubContext);
  const [tooltip, setToolTip] = useState({
    delete: false,
    edit: false
  });


  // const delete_fn = async (emailID, companyID) => {
  //   setLoader(true);
  //   try {
  //     // fetch company Details...
  //     const url = `company/getCompany?companyName=${companyName}&companyID=${companyID}`;
  //     const { data: { result } } = await getRequest(url);
  //     if (!result) throw Error("Company Not Found!");
  //     const { actualEmployeeCount, subscriptionStatus, subscriptionID, companyOrigin } = result;
  //     const companyData = {
  //       actualEmployeeCount,
  //       subscriptionStatus,
  //       subscriptionID,
  //       companyName,
  //       companyID,
  //       companyOrigin
  //     };
  //     await checkForSubscription();

  //     await sendRequest("PUT", `employee/user/deleteUser?companyID=${companyID}`, { emailID, companyData })
  //     setLoader(false);
  //     toast.success("user deleted!");
  //     setHandleView(true);
  //     setViewItem({});
  //     getEmployees();
  //   } catch (error) {
  //     setLoader(false);
  //     const { status, data } = error.response || {};
  //     if (error === 403) return toast.info("Subscription Ended!");
  //     if (status === 403) return toast.error(data.message);
  //     toast.error("Try again!");
  //   }
  // };


  return items.empName ? <>
    <div className={`tooltips ${tooltip.edit ? "opacity--enlarge" : null}`} onMouseEnter={() => {
      setHandleView(false); setToolTip({ ...tooltip, edit: true });
    }} onMouseLeave={() => {
      setHandleView(true); setToolTip({ ...tooltip, edit: false });
    }}><HiOutlinePencil onClick={() => {
      setViewItem(items);
      setModalUtils({ addEmployee: true });
    }} />
      {tooltip.edit ? <span style={{ right: handleProfile.open ? "-3.1em" : "" }}
        className='animate__animated animate__bounceIn shadow'>Edit</span> : null}
    </div>

    {/* {!loader ?
      <div className={`tooltips ${tooltip.delete ? "opacity--enlarge" : null}`}
        onMouseEnter={() => {
          setHandleView(false);
          setToolTip({ ...tooltip, delete: true })
        }}
        onMouseLeave={() => {
          setHandleView(true);
          setToolTip({ ...tooltip, delete: false })
        }}>
        <FiTrash2 className={empRole.includes("super") ? "modify--trash" : null}
          onClick={() => delete_fn(emailID, companyID, empRole)} />
        {tooltip.delete && !empRole.includes("super") ? <span className='shadow'>Delete</span> : null}
      </div> : <Loader />} */}
  </> : null
};


// Toogle Btn for activate Employees......
const ToogleBtn = ({ items, setHandleView }) => {
  const { checkForSubscription, setViewItem, setCustomToast } = useContext(GlobalContext);
  const { getEmployees } = useContext(FeatureSubContext);
  const { emailID, companyID, isActive, empRole } = items;
  const [loader, setLoader] = useState(false);
  const [tooltip, setToolTip] = useState(false);


  const handleStatus = async (isActive) => {
    if (empRole.includes("super")) return toast.info("Root account can't modified!");
    setLoader(true);
    try {
      await checkForSubscription();

      isActive = (isActive === "true") ? "false" : "true";
      await sendRequest("PUT", "employee/user/updateStatus", { emailID, companyID, isActive })
      setLoader(false);
      setViewItem({});
      setCustomToast({
        content: `Employee Updated as ${(isActive === "true") ? "Active" : "InActive"}`,
        Icon: SuccessIcon, open: true
      })
      getEmployees();
    } catch (err) {
      setLoader(false);
      const { status, data } = err.response || {};
      if (err === 403) return toast.info("Subscription Ended!");
      if (status === 403) return toast.error(data.message);
      toast.error("something went wrong!");
    }
  }

  return isActive ? <div className="form-check form-switch" onMouseEnter={() => setHandleView(false)}
    onMouseLeave={() => setHandleView(true)} style={{ padding: loader ? "0%" : null }}>
    {
      loader ? <Loader borderColor="#3263F3" borderTopColor="whitesmoke" />
        : <div className={`status--tooltip tooltips ${tooltip.delete ? "opacity--enlarge" : null}`}
          onMouseEnter={() => setToolTip(true)}
          onMouseLeave={() => setToolTip(false)}>
          <input className="form-check-input"
            type="checkbox"
            disabled={items && items.empRole === "super_admin"}
            id="flexSwitchCheckChecked"
            onChange={() => { }}
            checked={isActive === "true" ? true : false}
            onClick={() => handleStatus(isActive)}
          />
          {tooltip ? <span className='animate__animated animate__bounceIn shadow'>{isActive === "true" ? "Set Inactive" : "Set Active"}</span> : null}
        </div>
    }
  </div> : <SkeletonLoader />
}

export { ToogleBtn, Operation };