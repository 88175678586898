import React from 'react'
import { useHistory } from 'react-router-dom';
import { FaceBookSvg, LinkedlnSvg, Logo, MadeWithLuv, TwitterSvg } from './Landing/Logo';


const Footer = () => {
  const history = useHistory();

  // const footer_links = (head, t1, t2, t3, t4) => {
  //   return <article>
  //     <p>Programs</p>
  //     <li>About Us</li>
  //     <li>Testimonials</li>
  //     <li>Contact US</li>
  //     <li>Team</li>
  //   </article>
  // }

  return <main className='footer'>
    <div>
      <section className='logo'>
        <Logo check={true} />
        <div className='footer--icons'>
          <FaceBookSvg />
          <TwitterSvg />
          <LinkedlnSvg />
        </div>
        <div className='made--with--luv'>
          <MadeWithLuv />
        </div>
      </section>

      <section className='footer--text'>
        <article>
          <p>Programs</p>
          <li>About Us</li>
          <li>Testimonials</li>
          <li>Contact US</li>
          <li>Team</li>
        </article>
        <article>
          <p>Resources</p>
          <li>Features</li>
          <li>Pricing</li>
          <li onClick={() => history.push("/login")}>Login</li>
          <li onClick={() => history.push("/register")}>SignUp</li>
        </article>
        <article>
          <p>Legal</p>
          <li onClick={() => history.push("/terms")}>Terms of use</li>
          <li onClick={() => history.push("/privacy")}>Privacy policy</li>
          <li onClick={() => history.push("/cancellation")}>Legal notice</li>
        </article>
      </section>
    </div>
    <p className='copyright'>Copyright 2021 &copy; All rights reserved.</p>
  </main>
}

export default Footer;