import React from 'react'
import { Link } from 'react-router-dom';
import Welcome from "../../Component/Authenticate/Welcome";



const WelcomeAboard = () => {
  return <main className='welcome--aboard'>
    <section><Welcome /></section>
    <section className='welcome--info'>
      <h1 className='animate__animated animate__fadeInUp'>Welcome aboard</h1>
      <p className='animate__animated animate__fadeInUp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet netus ullamcorp.</p>
      <Link className='btn--link' to="/dashboard">
        <button className='btn animate__animated animate__zoomIn'>
          Continue to Dashboard
        </button>
      </Link>
    </section>
  </main>
};

export default WelcomeAboard;