import React, { useEffect, useState } from 'react';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import ReactPaginate from "react-paginate";


const Paginate = ({ data, setCurrentItems, displayItem }) => {
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [lastPoint, setLastPoint] = useState(false);


  useEffect(() => {
    const endOffset = itemOffset + (displayItem || 6);
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / (displayItem || 6)));
    setLastPoint(pageCount * (displayItem || 6) === endOffset);
  }, [itemOffset, 6, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * (displayItem || 6)) % data.length;
    setItemOffset(newOffset);
  };


  return <ReactPaginate
    className={"paginate"}
    breakLabel="..."
    nextLabel={<BsArrowRightShort />}
    nextClassName={(lastPoint ? "next disabled" : "next")}
    onPageChange={handlePageClick}
    pageRangeDisplayed={3}
    pageCount={pageCount}
    previousLabel={<BsArrowLeftShort />}
    previousClassName={(itemOffset === 0) ? "previous disabled" : 'previous'}
    renderOnZeroPageCount={null}
  />
}

export default Paginate;