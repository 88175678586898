import React, { useContext, useState, useEffect } from 'react'
import { getRequest, sendRequest } from '../../../API';
import { GlobalContext } from '../../../Context';
import { SkeletonLoad, SkeletonLoader } from '../../../Component/Spinners/Skeleton';
import { useHistory } from 'react-router-dom';
import Loader from "../../../Component/Spinners/Loader";
import HanldeUpdateCard from './HanldeUpdateCard';
import moment from 'moment';
import HandleSub from './HandleSub';
import { toast } from 'react-toastify';
import { CostBreak } from './CostBreakModal';



const Bill = () => {
  const { user, setViewItem, companyData, getCompanyData, setGlobalError, globalError } = useContext(GlobalContext);
  const { empName, emailID } = (user && user.data) || {};
  const { companyID } = (user && user.company) || {};
  const [activeUser, setActiveUser] = useState([]);
  const history = useHistory();
  const [loader, setLoader] = useState({ payment: false, begin: false, company: true });
  const { status } = globalError || {};
  const { subscriptionEnded, subscriptionStatus, subscriptionStartDate,
    customerID, subscriptionID, companyOrigin, cancelAtperiodEnd } = companyData || {};



  const getAllUsers = () => {
    getRequest(`employee/user/allUsers?companyID=${companyID}`).then((res) => {
      let { Items } = res.data;
      if (Items.length > 0) {
        setActiveUser(Items);
        setViewItem(Items.length);
      }
    }).catch((err) => {
      setActiveUser([]);
      setGlobalError(err.response);
    });
  };
  useEffect(() => {
    getAllUsers();
    getCompanyData().then(() => {
      setLoader({ company: false })
    }).catch(() => setLoader({ company: true }));
  }, []);


  const handlePayment = () => {
    const { companyName, companyOrigin } = user.company;
    const data = {
      fullName: empName,
      name: empName && empName.split(" ")[0],
      companyName,
      companyID,
      email: emailID,
      address: {
        country: companyOrigin
      },
      phone: "9090909090"
    };
    sendRequest("POST", "stripe/subscriptionnew", data).then((res) => {
      const { result } = res.data || {};
      setLoader({ payment: false });
      if (result.clientSecret) history.push("/dashboard/paymentscreen", {
        clientSecret: result.clientSecret
      });
    }).catch((err) => {
      setLoader({ payment: false });
      const { status, data } = err.response || {};
      if (status === 403) return toast.error(data.message);
    })
  };

  

  return <section className='billing'>
    <div className='billing--content'>
      <h1>{activeUser && activeUser.length > 0 ?
        `Hi  ${empName && empName.split(" ")[0]},  you have ${activeUser.length} members`
        : <SkeletonLoader />}
      </h1>
      <article className='billing--detials'>
        <div id="box">
          {!loader.company
            ? <p>{subscriptionStatus === "CANCELED" || (cancelAtperiodEnd === true)
              ? "Service used by" : "Next invoice issue date"}</p>
            : <p><SkeletonLoader /></p>}

          {!loader.company ? (subscriptionStartDate && subscriptionStartDate !== "null")
            ? <h6>{moment(subscriptionEnded).format("DD MMMM YYYY")}</h6> : <h6>--</h6>
            : <SkeletonLoad />}

          {subscriptionStatus === 'ACTIVE'
            ? <button className='btn active'>Active</button>
            : <button disabled={(subscriptionStatus === "null" && status !== 403) ? false : true}
              onClick={() => {
                setLoader({ payment: true })
                handlePayment()
              }}> {loader.payment ? <Loader
                size={20}
                borderColor="#3263F3"
                borderTopColor="#FFF" /> : "Make Payment"}  </button>}
        </div>
        <div id="box">
          <CostBreak
            customerID={customerID}
            activeUser={activeUser}
            companyOrigin={companyOrigin}
          />
        </div>
        <div id="box">
          <p>Total Seats billed</p>
          <h6>{(activeUser && activeUser.length) || <SkeletonLoader />}</h6>
        </div>
      </article>
      <article className='user--detials'>
        <p>Invoices sent to</p>
        {!loader.company ? <>
          <p>{emailID}</p>
          <p className={`${subscriptionStatus !== "ACTIVE" || (cancelAtperiodEnd === true)
            ? "no--operation" : null}`}>Change Email</p>
        </> : <p><SkeletonLoad /></p>}
      </article>

      <HanldeUpdateCard
        subscriptionStatus={subscriptionStatus}
        cancelAtperiodEnd={cancelAtperiodEnd}
        cusId={customerID}
        subId={subscriptionID} />

      {status !== 403 ? <HandleSub
        getCompanyData={getCompanyData}
        setLoader={setLoader}
        loader={loader}
        handlePayment={handlePayment} /> : null}

    </div>
  </section>
}

export default Bill;