import React, { useContext, useState } from 'react'
import { IoCloseOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { sendRequest } from '../../API';
import { GlobalContext } from '../../Context';
import Loader from '../Spinners/Loader';
import "../Style/serial_number.css";


const SerailIdModal = ({ setSerialNoModal, items, setRequestView }) => {
  const { setCustomToast, user } = useContext(GlobalContext);
  const { emailID, ticketNo } = items || {};
  const [serial, setSerial] = useState("");
  const [loader, setLoader] = useState(false);
  const { data } = user || {};



  const submit_serialnumber = async () => {
    if (!serial) return toast.info("Serial number is required!");
    setLoader(true);
    try {
      const res = await sendRequest("POST", "items/serialnumber", {
        emailID, ticketNo,
        serialNumber: serial,
        serialNumberUpdate: false,
        updaterName: data && data.empName.split(" ")[0],
        updaterRole: data.empRole
      });
      const { result: { Attributes } } = res.data || {};
      if (Attributes) setRequestView({ ...items, serialNumber: Attributes.serialNumber, history: Attributes.history });
      setLoader(false);
      setSerialNoModal(false);
      setCustomToast({ content: `Serial no. updated for ticket #${ticketNo}`, open: true, error: false });
    } catch (error) {
      setLoader(false);
      setCustomToast({ content: `Something went wrong!`, open: true, error: true });
    }
  }



  return <section className='serialNumber--modal animate__animated animate__bounceIn'>
    <article className='serial--card'>
      <IoCloseOutline className='close--icon' onClick={() => setSerialNoModal(false)} />
      <h2>
        <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M31.5 62C48.3447 62 62 48.3447 62 31.5C62 14.6553 48.3447 1 31.5 1C14.6553 1 1 14.6553 1 31.5C1 48.3447 14.6553 62 31.5 62Z"
            fill="#3263F3" stroke="#464555" stroke-width="1.20904" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M30.8237 53.0673C42.8162 53.0673 52.538 43.3455 52.538 31.353C52.538 19.3605 42.8162 9.63867 30.8237 9.63867C18.8312 9.63867 9.10938 19.3605 9.10938 31.353C9.10938 43.3455 18.8312 53.0673 30.8237 53.0673Z"
            fill="white" stroke="#464555" stroke-width="1.20904" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M23.3281 32.3857L29.0509 38.8339L42.5599 25.9375" fill="white" />
          <path d="M23.3281 32.3857L29.0509 38.8339L42.5599 25.9375" stroke="#3263F3" stroke-width="2.86339"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg> You have accepted {items.requester && items.requester.split(" ")[0]} Request
      </h2>
      <div>
        <p>System Generated ID for Request: {items.assetId}</p>
        <input value={serial} onChange={(e) => setSerial(e.target.value)}
          className='form-control' placeholder='Enter serial number' type="text" />
      </div>
      <div className='submit--btn'>
        <button disabled={loader} className='btn' onClick={() => submit_serialnumber()}>
          {loader ? <Loader /> : <b>Done</b>}
        </button>
      </div>
    </article>
  </section>
}

export default SerailIdModal;