import React, { useContext, useEffect, useState } from 'react'
import Paginate from '../../../Component/Paginate';
import { SkeletonLoader } from '../../../Component/Spinners/Skeleton';
import ViewEmployeeData from "../../../Component/Modals/ViewEmployeeData";
import { FeatureSubContext } from '../FeatureContext';
import { GlobalContext } from '../../../Context';
import { ToogleBtn } from './Operation';
import { ActiveSmall, UtilsNoEmployee } from '../../../Component/DashboardUtils';
import { BiPlus } from 'react-icons/bi';
import DeleteProfileModal from '../../../Component/Modals/DeleteModals/DeleteProfileModal';



const Employee = () => {
  const { openViewItemsModal, setModalUtils, modalUtils, handleProfile, setHandleProfile, setViewItem,
    deleteProfileModal } = useContext(GlobalContext);
  const { getEmployees, employeeData } = useContext(FeatureSubContext);
  const [currentItems, setCurrentItems] = useState([{}]);
  const [handleView, setHandleView] = useState(true);

  document.title = "REQO | Manage Employees";



  useEffect(() => {
    getEmployees();
  }, []);

  // will sort...
  useEffect(() => {
    if (employeeData.length <= 1) setCurrentItems(employeeData);
  }, [employeeData]);

  return <main>
    {deleteProfileModal.open && <DeleteProfileModal />}
    {openViewItemsModal ? <ViewEmployeeData /> : null}
    {employeeData && employeeData.length > 0
      ? <section className={`view--items employee--section ${(openViewItemsModal || modalUtils.createRequestModal || deleteProfileModal.open) ? "opacity--reduce" : null}`}>


        <article className='employee--total_count'>
          <p>Active Members ({employeeData && employeeData.length.toLocaleString('en-US', { minimumIntegerDigits: 2 })})</p>
          <p onClick={() => {
            setViewItem({});
            setModalUtils({ ...modalUtils, addEmployee: true })
          }}><BiPlus /> Add Member</p>
        </article>

        <article className='request--viewtable'>
          <table className='table'>
            <tbody className='viewtable--body employeeList--body'>
              {currentItems.map((items, i) => {
                const { isActive } = items;
                return <React.Fragment key={i}>
                  <tr className='request--data' onClick={() => handleView ? setHandleProfile({ items, open: true }) : null}>
                    <td className='user--name'>
                      {items.empName ? <>
                        <p className='user--icon'><ActiveSmall fill={isActive === "true" ? "#27AE60" : "#ec5d6d"} />{items.empName.substring(0, 1)}</p>
                        <p><b>{items.empName.split(" ")[0].substring(0, 10)}</b></p>
                      </> : <SkeletonLoader />}
                    </td>
                    {!handleProfile.open ? <td style={{ textTransform: "uppercase" }}>
                      {items.empName ? items.empID || "--" : <SkeletonLoader />}
                    </td> : null}
                    <td className='emprole'>{items.empRole || <SkeletonLoader />}</td>
                    <td className='emailid'>
                      {(items.emailID && items.emailID.substring(0, 29)) || <SkeletonLoader />}
                      {items.emailID && items.emailID.length > 30 ? "..." : null}
                    </td>
                    <td className='employee--toogle'>
                      {items.empName ? <ToogleBtn items={items} setHandleView={setHandleView} /> : <SkeletonLoader />}
                    </td>
                    {/* <td className='icons'>
                      {items.empName ? <Operation setHandleView={setHandleView} items={items} /> : <SkeletonLoader />}
                    </td> */}
                  </tr>
                  <tr className='request--line'><td></td></tr>
                </React.Fragment>
              })}
            </tbody>
          </table>
        </article>

        <article className='view--pagination'>
          {
            employeeData.length > 1 ? <Paginate data={employeeData} setCurrentItems={setCurrentItems} displayItem={9} /> : null
          }
        </article>
      </section> : <section className='no--request--section'>
        <article className='no--request'>
          <UtilsNoEmployee />
          <p>No Employee to browse!</p>
        </article>
      </section>}
  </main>
}

export default Employee;




/*
<article className='view--table'>
        <table className='table'>
          <thead className='table--head'>
            <tr>
              <th scope="col">Employee Name</th>
              <th>Employee ID</th>
              <th scope="col">Role</th>
              <th scope="col">Email ID</th>
              <th
                scope="col" className="toogle--btn employee--tooltip">
                Active/Inactive
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody className='table--body'>
            {
              currentItems.map((items, i) => {
                return <tr key={i} onClick={() => {
                  setOpenViewItemsModal(handleView ? true : false);
                  setViewItem(items);
                }}>
                  <td>{items.empName || <SkeletonLoader />}</td>
                  <td style={{ textTransform: "uppercase" }}>
                    {items.empName ? items.empID || "--" : <SkeletonLoader />}
                  </td>
                  <td>{items.empRole || <SkeletonLoader />}</td>
                  <td className='emailid'>{(items.emailID && items.emailID.substring(0, 29)) || <SkeletonLoader />}{items.emailID && items.emailID.length > 30 ? "..." : null}</td>
                  <td>
                    <ToogleBtn items={items} setHandleView={setHandleView} />
                  </td>
                  <td className='icons'>
                    <Operation setHandleView={setHandleView} items={items} />
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </article>

*/